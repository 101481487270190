<!-- Start Breadcrumbs -->
<app-breadcrumbs title="PROJECTS" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

 <div class="row project-wrapper">
    <div class="col-xxl-8">
        <div class="row">
            <div class="col-lg-4" *ngFor="let stat of statData">
                <app-projects-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon" [persantage]="stat.persantage" [profit]="stat.profit" [month]="stat.month"></app-projects-stat>
            </div><!-- end col -->
        </div><!-- end row -->

        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header border-0 align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1">Projects Overview</h4>
                        <div class="d-flex gap-1">
                            <button type="button" class="btn btn-soft-secondary btn-sm">
                                ALL
                            </button>
                            <button type="button" class="btn btn-soft-secondary btn-sm">
                                1M
                            </button>
                            <button type="button" class="btn btn-soft-secondary btn-sm">
                                6M
                            </button>
                            <button type="button" class="btn btn-soft-primary btn-sm">
                                1Y
                            </button>
                        </div>
                    </div><!-- end card header -->

                    <div class="card-header p-0 border-0 bg-soft-light">
                        <div class="row g-0 text-center">
                            <div class="col-6 col-sm-3">
                                <div class="p-3 border border-dashed border-start-0">
                                    <h5 class="mb-1"><span [CountTo]="9851" class="counter-value" [from]="0" [duration]="1"></span></h5>
                                    <p class="text-muted mb-0">Number of Projects</p>
                                </div>
                            </div><!--end col-->
                            <div class="col-6 col-sm-3">
                                <div class="p-3 border border-dashed border-start-0">
                                    <h5 class="mb-1"><span [CountTo]="1026" class="counter-value" [from]="0" [duration]="1"></span></h5>
                                    <p class="text-muted mb-0">Active Projects</p>
                                </div>
                            </div><!--end col-->
                            <div class="col-6 col-sm-3">
                                <div class="p-3 border border-dashed border-start-0">
                                    <h5 class="mb-1">$<span [CountTo]="228.89" [from]="0" [duration]="1" class="counter-value"></span>k</h5>
                                    <p class="text-muted mb-0">Revenue</p>
                                </div>
                            </div><!--end col-->
                            <div class="col-6 col-sm-3">
                                <div class="p-3 border border-dashed border-start-0 border-end-0">
                                    <h5 class="mb-1 text-success"><span [CountTo]="10589" [from]="0" [duration]="1" class="counter-value"></span>h</h5>
                                    <p class="text-muted mb-0">Working Hours</p>
                                </div>
                            </div><!--end col-->
                        </div>
                    </div><!-- end card header -->
                    <div class="card-body p-0 pb-2">
                        <div class="mb-n3">
                            <apx-chart [series]="OverviewChart.series" [chart]="OverviewChart.chart" [stroke]="OverviewChart.stroke"
                            [fill]="OverviewChart.fill" [markers]="OverviewChart.markers" [xaxis]="OverviewChart.xaxis"
                            [grid]="OverviewChart.grid" [legend]="OverviewChart.legend" [plotOptions]="OverviewChart.plotOptions"
                            [colors]="OverviewChart.colors" [tooltip]="OverviewChart.tooltip"></apx-chart>
                        </div>
                    </div><!-- end card body -->
                </div><!-- end card -->
            </div><!-- end col -->
        </div><!-- end row -->
    </div><!-- end col -->

    <div class="col-xxl-4">
        <div class="card">
            <div class="card-header border-0">
                <h4 class="card-title mb-0">Upcoming Schedules</h4>
            </div><!-- end cardheader -->
            <div class="card-body pt-0">
                <div class="upcoming-scheduled">
                    <input type="text" class="form-control" mwlFlatpickr [altInput]="true" [convertModelValue]="true" [inline]="true">
                </div>

                <h6 class="text-uppercase fw-semibold mt-4 mb-3 text-muted">Events:</h6>
                <div class="mini-stats-wid d-flex align-items-center mt-3">
                    <div class="flex-shrink-0 avatar-sm">
                        <span class="mini-stat-icon avatar-title rounded-circle text-success bg-soft-success fs-4">
                            09
                        </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Development planning</h6>
                        <p class="text-muted mb-0">iTest Factory </p>
                    </div>
                    <div class="flex-shrink-0">
                        <p class="text-muted mb-0">9:20 <span class="text-uppercase">am</span></p>
                    </div>
                </div><!-- end -->
                <div class="mini-stats-wid d-flex align-items-center mt-3">
                    <div class="flex-shrink-0 avatar-sm">
                        <span class="mini-stat-icon avatar-title rounded-circle text-success bg-soft-success fs-4">
                            12
                        </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Design new UI and check sales</h6>
                        <p class="text-muted mb-0">Meta4Systems</p>
                    </div>
                    <div class="flex-shrink-0">
                        <p class="text-muted mb-0">11:30 <span class="text-uppercase">am</span></p>
                    </div>
                </div><!-- end -->
                <div class="mini-stats-wid d-flex align-items-center mt-3">
                    <div class="flex-shrink-0 avatar-sm">
                        <span class="mini-stat-icon avatar-title rounded-circle text-success bg-soft-success fs-4">
                            25
                        </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">Weekly catch-up </h6>
                        <p class="text-muted mb-0">Nesta Technologies</p>
                    </div>
                    <div class="flex-shrink-0">
                        <p class="text-muted mb-0">02:00 <span class="text-uppercase">pm</span></p>
                    </div>
                </div><!-- end -->
                <div class="mini-stats-wid d-flex align-items-center mt-3">
                    <div class="flex-shrink-0 avatar-sm">
                        <span class="mini-stat-icon avatar-title rounded-circle text-success bg-soft-success fs-4">
                            27
                        </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h6 class="mb-1">James Bangs (Client) Meeting</h6>
                        <p class="text-muted mb-0">Nesta Technologies</p>
                    </div>
                    <div class="flex-shrink-0">
                        <p class="text-muted mb-0">03:45 <span class="text-uppercase">pm</span></p>
                    </div>
                </div><!-- end -->

                <div class="mt-3 text-center">
                    <a href="javascript:void(0);" class="text-muted text-decoration-underline">View all Events</a>
                </div>

            </div><!-- end cardbody -->
        </div><!-- end card -->
    </div><!-- end col -->

</div><!-- end row -->

<div class="row">
    <div class="col-xl-7">
        <div class="card">
            <div class="card-header d-flex align-items-center">
                <h4 class="card-title flex-grow-1 mb-0">Active Projects</h4>
                <div class="flex-shrink-0">
                    <a href="javascript:void(0);" class="btn btn-soft-info btn-sm">Export Report</a>
                </div>
            </div><!-- end cardheader -->
            <div class="card-body">
                <div class="table-responsive table-card">
                    <app-active-project [ActiveProjects]="ActiveProjects"></app-active-project>
                </div>

                <div class="align-items-center mt-xl-3 mt-4 justify-content-between d-flex">                
                    <div class="flex-shrink-0">
                        <div class="text-muted">Showing <span class="fw-semibold">5</span> of <span class="fw-semibold">25</span> Results
                        </div>
                    </div>
                    <ul class="pagination pagination-separated pagination-sm mb-0">
                        <li class="page-item disabled">
                            <a href="javascript:void(0);" class="page-link">←</a>
                        </li>
                        <li class="page-item">
                            <a href="javascript:void(0);" class="page-link">1</a>
                        </li>
                        <li class="page-item active">
                            <a href="javascript:void(0);" class="page-link">2</a>
                        </li>
                        <li class="page-item">
                            <a href="javascript:void(0);" class="page-link">3</a>
                        </li>
                        <li class="page-item">
                            <a href="javascript:void(0);" class="page-link">→</a>
                        </li>
                    </ul>
                </div>

            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-5">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1 py-1">My Tasks</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="text-muted">All Tasks <i class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">All Tasks</a>
                            <a class="dropdown-item" href="javascript:void(0);">Completed </a>
                            <a class="dropdown-item" href="javascript:void(0);">Inprogress</a>
                            <a class="dropdown-item" href="javascript:void(0);">Pending</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->
            <div class="card-body">
                <div class="table-responsive table-card">
                    <app-my-task [MyTask]="MyTask"></app-my-task>
                </div>
                <div class="mt-3 text-center">
                    <a href="javascript:void(0);" class="text-muted text-decoration-underline">Load More</a>
                </div>
            </div><!-- end cardbody -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xxl-4">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Team Members</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="fw-semibold text-uppercase fs-12">Sort by: </span><span class="text-muted">Last 30 Days<i class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Today</a>
                            <a class="dropdown-item" href="javascript:void(0);">Yesterday</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last 7 Days</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last 30 Days</a>
                            <a class="dropdown-item" href="javascript:void(0);">This Month</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->

            <div class="card-body">
                
                <div class="table-responsive table-card">
                    <app-team-members [TeamMembers]="TeamMembers"></app-team-members>
                </div>
            </div><!-- end cardbody -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xxl-4 col-lg-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Chat</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="text-muted"><i class="ri-settings-4-line align-middle me-1"></i>Setting <i class="mdi mdi-chevron-down ms-1"></i></span> 
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);"><i class="ri-user-2-fill align-bottom text-muted me-2"></i> View Profile</a>
                            <a class="dropdown-item" href="javascript:void(0);"><i class="ri-inbox-archive-line align-bottom text-muted me-2"></i> Archive</a>
                            <a class="dropdown-item" href="javascript:void(0);"><i class="ri-mic-off-line align-bottom text-muted me-2"></i> Muted</a>
                            <a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line align-bottom text-muted me-2"></i> Delete</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->

            <div class="card-body p-0">
                <div id="users-chat">
                    <ngx-simplebar style="max-height: 400px;" class="chat-conversation p-3" #scrollRef>
                        <ul class="list-unstyled chat-conversation-list chat-sm" id="users-conversation">
                            <li class="chat-list left">
                                <div class="conversation-list">
                                    <div class="chat-avatar">
                                        <img src="assets/images/users/avatar-2.jpg" alt="">
                                    </div>
                                    <div class="user-chat-content">
                                        <div class="ctext-wrap">
                                            <div class="ctext-wrap-content">
                                                <p class="mb-0 ctext-content">Good morning 😊</p>
                                            </div>
                                            <div class="dropdown align-self-start message-box-drop" ngbDropdown>
                                                <a class="dropdown-toggle arrow-none" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                    <i class="ri-more-2-fill"></i>
                                                </a>
                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-file-copy-line me-2 text-muted align-bottom"></i>Copy</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                    <a class="dropdown-item delete-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conversation-name"><small class="text-muted time">09:07 am</small> <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span></div>
                                    </div>                
                                </div>            
                            </li>
                            <!-- chat-list -->

                            <li class="chat-list right">
                                <div class="conversation-list">
                                    <div class="user-chat-content">
                                        <div class="ctext-wrap">
                                            <div class="ctext-wrap-content">
                                                <p class="mb-0 ctext-content">Good morning, How are you? What about our next meeting?</p>
                                            </div>
                                            <div class="dropdown align-self-start message-box-drop" ngbDropdown>
                                                <a class="dropdown-toggle arrow-none" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                    <i class="ri-more-2-fill"></i>
                                                </a>
                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-file-copy-line me-2 text-muted align-bottom"></i>Copy</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                    <a class="dropdown-item delete-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conversation-name"><small class="text-muted time">09:08 am</small> <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span></div>
                                    </div>                
                                </div>            
                            </li>
                            <!-- chat-list -->

                            <li class="chat-list left">
                                <div class="conversation-list">
                                    <div class="chat-avatar">
                                        <img src="assets/images/users/avatar-2.jpg" alt="">
                                    </div>
                                    <div class="user-chat-content">
                                        <div class="ctext-wrap">
                                            <div class="ctext-wrap-content">
                                                <p class="mb-0 ctext-content">Yeah everything is fine. Our next meeting tomorrow at 10.00 AM</p>
                                            </div>
                                            <div class="dropdown align-self-start message-box-drop" ngbDropdown>
                                                <a class="dropdown-toggle arrow-none" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                    <i class="ri-more-2-fill"></i>
                                                </a>
                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-file-copy-line me-2 text-muted align-bottom"></i>Copy</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                    <a class="dropdown-item delete-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ctext-wrap">
                                            <div class="ctext-wrap-content">
                                                <p class="mb-0 ctext-content">Hey, I'm going to meet a friend of mine at the department store. I have to buy some presents for my parents 🎁.</p>
                                            </div>
                                            <div class="dropdown align-self-start message-box-drop" ngbDropdown>
                                                <a class="dropdown-toggle arrow-none" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                    <i class="ri-more-2-fill"></i>
                                                </a>
                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-file-copy-line me-2 text-muted align-bottom"></i>Copy</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                    <a class="dropdown-item delete-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conversation-name"><small class="text-muted time">09:10 am</small> <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span></div>
                                    </div>                
                                </div>            
                            </li>
                            <!-- chat-list -->

                            <li class="chat-list right">
                                <div class="conversation-list">
                                    <div class="user-chat-content">
                                        <div class="ctext-wrap">
                                            <div class="ctext-wrap-content">
                                                <p class="mb-0 ctext-content">Wow that's great</p>
                                            </div>
                                            <div class="dropdown align-self-start message-box-drop" ngbDropdown>
                                                <a class="dropdown-toggle arrow-none" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                    <i class="ri-more-2-fill"></i>
                                                </a>
                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-file-copy-line me-2 text-muted align-bottom"></i>Copy</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                    <a class="dropdown-item delete-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conversation-name"><small class="text-muted time">09:12 am</small> <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span></div>
                                    </div>                
                                </div>            
                            </li>
                            <!-- chat-list -->

                            <li class="chat-list left">
                                <div class="conversation-list">
                                    <div class="chat-avatar">
                                        <img src="assets/images/users/avatar-2.jpg" alt="">
                                    </div>
                                    <div class="user-chat-content">
                                        <div class="ctext-wrap">
                                            <div class="message-img mb-0">
                                                <div class="message-img-list">
                                                    <div>
                                                        <a class="popup-img d-inline-block" href="assets/images/small/img-1.jpg">
                                                            <img src="assets/images/small/img-1.jpg" alt="" class="rounded border">
                                                        </a>
                                                    </div>
                                                    <div class="message-img-link">
                                                        <ul class="list-inline mb-0">
                                                            <li class="list-inline-item dropdown" ngbDropdown>
                                                                <a class="dropdown-toggle" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                                    <i class="ri-more-fill"></i>
                                                                </a>
                                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                                    <a class="dropdown-item" href="assets/images/small/img-1.jpg" download=""><i class="ri-download-2-line me-2 text-muted align-bottom"></i>Download</a>
                                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                                    <a class="dropdown-item delete-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                
                                                <div class="message-img-list">
                                                    <div>
                                                        <a class="popup-img d-inline-block" href="assets/images/small/img-2.jpg">
                                                            <img src="assets/images/small/img-2.jpg" alt="" class="rounded border">
                                                        </a>
                                                    </div>
                                                    <div class="message-img-link">
                                                        <ul class="list-inline mb-0">
                                                            <li class="list-inline-item dropdown" ngbDropdown>
                                                                <a class="dropdown-toggle" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                                    <i class="ri-more-fill"></i>
                                                                </a>
                                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                                    <a class="dropdown-item" href="assets/images/small/img-2.jpg" download=""><i class="ri-download-2-line me-2 text-muted align-bottom"></i>Download</a>
                                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                                    <a class="dropdown-item delete-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                                
                                        <div class="conversation-name"><small class="text-muted time">09:30 am</small> <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span></div>
                                    </div>                
                                </div>            
                            </li>
                            <!-- chat-list -->
                        </ul>
                    </ngx-simplebar>
                    <div class="border-top border-top-dashed">
                        <div class="row g-2 mx-3 mt-2 mb-3">
                            <div class="col">
                                <div class="position-relative">
                                    <input type="text" class="form-control border-light bg-light" placeholder="Enter Message...">
                                </div>
                            </div><!-- end col -->
                            <div class="col-auto">
                                <button type="submit" class="btn btn-info"><span class="d-none d-sm-inline-block me-2">Send</span> <i class="mdi mdi-send float-end"></i></button>
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div>
                </div>
            </div><!-- end cardbody -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xxl-4 col-lg-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Projects Status</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="dropdown-btn text-muted arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            All Time <i class="mdi mdi-chevron-down ms-1"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">All Time</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last 7 Days</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last 30 Days</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last 90 Days</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->

            <div class="card-body">
                <apx-chart class="apex-charts" dir="ltr" [series]="status7.series" [labels]="status7.labels" [chart]="status7.chart" [plotOptions]="status7.plotOptions"
                 [dataLabels]="status7.dataLabels" [legend]="status7.legend" [stroke]="status7.stroke" [colors]="status7.colors"></apx-chart>
                <div class="mt-3">
                    <div class="d-flex justify-content-center align-items-center mb-4">
                        <h2 class="me-3 ff-secondary mb-0">258</h2>
                        <div>
                            <p class="text-muted mb-0">Total Projects</p>
                            <p class="text-success fw-medium mb-0">
                                <span class="badge badge-soft-success p-1 rounded-circle"><i class="ri-arrow-right-up-line"></i></span> +3 New
                            </p>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-success align-middle me-2"></i> Completed</p>
                        <div>
                            <span class="text-muted pe-5">125 Projects</span>
                            <span class="text-success fw-medium fs-12">15870hrs</span>
                        </div>
                    </div><!-- end -->
                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-primary align-middle me-2"></i> In Progress</p>
                        <div>
                            <span class="text-muted pe-5">42 Projects</span>
                            <span class="text-success fw-medium fs-12">243hrs</span>
                        </div>
                    </div><!-- end -->
                    <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-warning align-middle me-2"></i> Yet to Start</p>
                        <div>
                            <span class="text-muted pe-5">58 Projects</span>
                            <span class="text-success fw-medium fs-12">~2050hrs</span>
                        </div>
                    </div><!-- end -->
                    <div class="d-flex justify-content-between py-2">
                        <p class="fw-medium mb-0"><i class="ri-checkbox-blank-circle-fill text-danger align-middle me-2"></i> Cancelled</p>
                        <div>
                            <span class="text-muted pe-5">89 Projects</span>
                            <span class="text-success fw-medium fs-12">~900hrs</span>
                        </div>
                    </div><!-- end -->
                </div>
            </div><!-- end cardbody -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->