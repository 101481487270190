<!-- Start Breadcrumbs -->
<app-breadcrumbs title="NFT Dashboard" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row dash-nft">
    <div class="col-xxl-9">
        <div class="row">
            <div class="col-xl-6">
                <div class="card overflow-hidden">
                    <div class="card-body bg-marketplace d-flex">
                        <div class="flex-grow-1">
                            <h4 class="fs-18 lh-base mb-0">Discover, Collect, Sell and Create <br> your own <span class="text-success">NFTs.</span> </h4>
                            <p class="mb-0 mt-2 pt-1 text-muted">The world's first and largest digital marketplace.</p>
                            <div class="d-flex gap-3 mt-4">
                                <a href="javascript:void(0);" class="btn btn-primary">Discover Now </a>
                                <a href="javascript:void(0);" class="btn btn-success">Create Your Own</a>
                            </div>
                        </div>
                        <img src="assets/images/bg-d.png" alt="" class="img-fluid" />
                    </div>
                </div>
            </div><!--end col-->
            <div class="col-xl-3 col-md-6" *ngFor="let stat of statData">
                <app-nft-stat [icon]="stat.icon" [title]="stat.title" [value]="stat.value" [persantage]="stat.persantage" [profit]="stat.profit" [bg_color]="stat.bg_color"></app-nft-stat>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-xxl-12">
                <div class="card">
                    <div class="card-body p-0">
                        <div class="row g-0">
                            <div class="col-xxl-8">
                                <div class="">
                                    <div class="card-header border-0 align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Marketplace</h4>
                                        <div class="hstack gap-1">
                                            <button type="button" class="btn btn-soft-secondary btn-sm">
                                                ALL
                                            </button>
                                            <button type="button" class="btn btn-soft-secondary btn-sm">
                                                1M
                                            </button>
                                            <button type="button" class="btn btn-soft-secondary btn-sm">
                                                6M
                                            </button>
                                            <button type="button" class="btn btn-soft-primary btn-sm">
                                                1Y
                                            </button>
                                        </div>
                                    </div><!-- end card header -->
                                    <div class="row g-0 text-center">
                                        <div class="col-6 col-sm-4">
                                            <div class="p-3 border border-dashed border-start-0">
                                                <h5 class="mb-1"><span [CountTo]=36.48 class="counter-value" [from]="0" [duration]="1"></span>k</h5>
                                                <p class="text-muted mb-0">Aetworks</p>
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-6 col-sm-4">
                                            <div class="p-3 border border-dashed border-start-0">
                                                <h5 class="mb-1"><span [CountTo]=92.54 class="counter-value" [from]="0" [duration]="1"></span>k</h5>
                                                <p class="text-muted mb-0">Auction</p>
                                            </div>
                                        </div>
                                        <!--end col-->
                                        <div class="col-6 col-sm-4">
                                            <div class="p-3 border border-dashed border-end-0">
                                                <h5 class="mb-1"><span [CountTo]=8.62 class="counter-value" [from]="0" [duration]="1"></span>k</h5>
                                                <p class="text-muted mb-0">Creators</p>
                                            </div>
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <apx-chart [series]="MarketplaceChart.series" [chart]="MarketplaceChart.chart"
                                    [dataLabels]="MarketplaceChart.dataLabels" [stroke]="MarketplaceChart.stroke"
                                    [xaxis]="MarketplaceChart.xaxis" [colors]="MarketplaceChart.colors"></apx-chart>
                                </div>
                            </div>

                            <div class="col-xxl-4">
                                <div class="border-start p-4 h-100 d-flex flex-column">

                                    <div class="w-100">
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/gif/img-2.gif" class="img-fluid avatar-xs rounded-circle object-cover" alt="">
                                            <div class="ms-3 flex-grow-1">
                                                <h5 class="fs-16 mb-1">Trendy Fashion Portraits</h5>
                                                <p class="text-muted mb-0">Artwork</p>
                                            </div>
                                            <div class="dropdown" ngbDropdown>
                                                <a href="javascript:void(0);" class="align-middle text-muted arrow-none" role="button" id="dropdownMenuButton5" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                    <i class="ri-share-line fs-18"></i>
                                                </a>
                                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton5" ngbDropdownMenu >
                                                    <li>
                                                        <a href="javascript:void(0);" class="dropdown-item">
                                                            <i class="ri-twitter-fill text-primary align-bottom me-1"></i> Twitter
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:void(0);" class="dropdown-item">
                                                            <i class="ri-facebook-circle-fill text-info align-bottom me-1"></i> Facebook
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:void(0);" class="dropdown-item">
                                                            <i class="ri-google-fill text-danger align-bottom me-1"></i> Google
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <h3 class="ff-secondary fw-bold mt-4"><i class="mdi mdi-ethereum text-primary"></i> 346.12 ETH</h3>
                                        <p class="text-success mb-3">+586.85 (40.6%)</p>

                                        <p class="text-muted">NFT art is a digital asset that is collectable, unique, and non-transferrable, Cortes explained Every NFT is unique duplicated.</p>

                                        <div class="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                <p class="fs-14 text-muted mb-1">Current Bid</p>
                                                <h4 class="fs-20 ff-secondary fw-semibold mb-0">342.74 ETH</h4>
                                            </div>

                                            <div>
                                                <p class="fs-14 text-muted mb-1">Highest Bid</p>
                                                <h4 class="fs-20 ff-secondary fw-semibold mb-0">346.67 ETH</h4>
                                            </div>
                                        </div>

                                        <div class="dash-countdown mt-4 pt-1">
                                            <div id="countdown" class="countdownlist">
                                                <div class="countdownlist-item">
                                                    <div class="count-title">Days</div>
                                                    <div class="count-num">{{_days}}</div>
                                                </div>
                                                <div class="countdownlist-item">
                                                    <div class="count-title">Hours</div>
                                                    <div class="count-num">{{_hours}}</div>
                                                </div>
                                                <div class="countdownlist-item">
                                                    <div class="count-title">Minutes</div>
                                                    <div class="count-num">{{_minutes}}</div>
                                                </div>
                                                <div class="countdownlist-item">
                                                    <div class="count-title">Seconds</div>
                                                    <div class="count-num">{{_seconds}}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4 pt-2">
                                            <div class="col">
                                                <a routerLink="/marletplace/item-details" class="btn btn-primary w-100">View Details</a>
                                            </div>
                                            <div class="col">
                                                <button class="btn btn-info w-100">Bid Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div><!--end row-->
    </div><!--end col-->

    <div class="col-xxl-3">
        <div class="card">
            <div class="card-header border-0 pb-0">
                <h6 class="card-title mb-0">Popularity</h6>
            </div>
            <div>
                <apx-chart [series]="popularityChart.series" [chart]="popularityChart.chart" [plotOptions]="popularityChart.plotOptions"
                [colors]="popularityChart.colors" [fill]="popularityChart.fill" [dataLabels]="popularityChart.dataLabels" 
                [yaxis]="popularityChart.yaxis" [legend]="popularityChart.legend" [xaxis]="popularityChart.xaxis"></apx-chart>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-flex align-items-center">
                <h6 class="card-title mb-0 flex-grow-1">History of Bids</h6>
                <a class="text-muted" routerLink="/marletplace/item-details">
                    See All <i class="ri-arrow-right-line align-bottom"></i>
                </a>
            </div>
            <div class="card-body">
                <div class="table-responsive table-card">
                    <ngx-simplebar style="max-height: 325px;">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item list-group-item-action">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/users/avatar-10.jpg" alt="" class="avatar-xs object-cover rounded-circle">
                                    <div class="ms-3 flex-grow-1">
                                        <a href="javascript:void(0);" class="stretched-link">
                                            <h6 class="fs-14 mb-1">Herbert Stokes</h6>
                                        </a>
                                        <p class="mb-0 text-muted">@herbert10</p>
                                    </div>
                                    <div>
                                        <h6>174.36 ETH</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/nft/img-01.jpg" alt="" class="avatar-xs object-cover rounded-circle">
                                    <div class="ms-3 flex-grow-1">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-14 mb-1">Nancy Martino</h6>
                                        </a>
                                        <p class="mb-0 text-muted">@nancyMt</p>
                                    </div>
                                    <div>
                                        <h6>346.47 ETH</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/nft/img-04.jpg" alt="" class="avatar-xs object-cover rounded-circle">
                                    <div class="ms-3 flex-grow-1">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-14 mb-1">Timothy Smith</h6>
                                        </a>
                                        <p class="mb-0 text-muted">@timothy</p>
                                    </div>
                                    <div>
                                        <h6>349.08 ETH</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/nft/img-06.jpg" alt="" class="avatar-xs object-cover rounded-circle">
                                    <div class="ms-3 flex-grow-1">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-14 mb-1">Glen Matney</h6>
                                        </a>
                                        <p class="mb-0 text-muted">@matney10</p>
                                    </div>
                                    <div>
                                        <h6>852.34 ETH</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/users/avatar-8.jpg" alt="" class="avatar-xs object-cover rounded-circle">
                                    <div class="ms-3 flex-grow-1">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-14 mb-1">Michael Morris</h6>
                                        </a>
                                        <p class="mb-0 text-muted">@michael</p>
                                    </div>
                                    <div>
                                        <h6>4.071 ETH</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/nft/img-03.jpg" alt="" class="avatar-xs object-cover rounded-circle">
                                    <div class="ms-3 flex-grow-1">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-14 mb-1">Alexis Clarke</h6>
                                        </a>
                                        <p class="mb-0 text-muted">@alexis_30</p>
                                    </div>
                                    <div>
                                        <h6>30.749 ETH</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/nft/img-05.jpg" alt="" class="avatar-xs object-cover rounded-circle">
                                    <div class="ms-3 flex-grow-1">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-14 mb-1">Timothy Smith</h6>
                                        </a>
                                        <p class="mb-0 text-muted">@timothy</p>
                                    </div>
                                    <div>
                                        <h6>349.08 ETH</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </ngx-simplebar>
                </div>
            </div>
        </div>
    </div><!--end col-->
</div>
<!--end row-->

<div class="row">
    <div class="col-xxl-8">
        <div class="swiper marketplace-swiper rounded gallery-light">
            <div class="d-flex pt-2 mb-n5">
                <h5 class="card-title fs-18 mb-0">Featured NFTs Artworks</h5>
            </div>
            <swiper [config]="Responsive">
                <div class="swiper-slide" *ngFor="let data of featuredData">
                    <div class="card explore-box card-animate rounded">
                        <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                            <button type="button" class="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                        </div>
                        <div class="explore-place-bid-img">
                            <img src="{{data.img}}" alt="" class="img-fluid card-img-top explore-img" />
                            <div class="bg-overlay"></div>
                            <div class="place-bid-btn">
                                <a href="javascript:void(0);" class="btn btn-success"><i class="ri-auction-fill align-bottom me-1"></i> Place Bid</a>
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="fw-medium mb-0 float-end"><i class="mdi mdi-heart text-danger align-middle"></i> {{data.likes}} </p>
                            <h5 class="mb-1"><a routerLink="/marletplace/item-details">{{data.title}}</a></h5>
                            <p class="text-muted mb-0">{{data.category}}</p>
                        </div>
                        <div class="card-footer border-top border-top-dashed">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 fs-14">
                                    <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest: <span class="fw-medium">{{data.highest}}</span>
                                </div>
                                <h5 class="flex-shrink-0 fs-14 text-primary mb-0">{{data.price}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper>
        </div>
    </div><!--end col-->
    <div class="col-xxl-4">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Top Artworks</h4>
                <div class="flex-shrink-0">
                    <div>
                        <button type="button" class="btn btn-soft-primary btn-sm">
                            See All
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive table-card">
                    <ngx-simplebar style="max-height: 405px;">
                        <table class="table table-borderless align-middle">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/img-01.jpg" alt="" class="avatar-sm rounded-circle">
                                            <div class="ms-3">
                                                <a href="javascript:void(0);">
                                                    <h6 class="fs-15 mb-1">One shop destination on</h6>
                                                </a>
                                                <p class="mb-0 text-muted">13,450 Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <apx-chart [series]="minichart1.series" [chart]="minichart1.chart" [colors]="minichart1.colors" 
                                        [stroke]="minichart1.stroke" [tooltip]="minichart1.tooltip"></apx-chart>
                                    </td>
                                    <td class="text-end">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-15 mb-1">$235,000+</h6>
                                        </a>
                                        <p class="mb-0 text-muted">Total USD</p>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/img-02.jpg" alt="" class="avatar-sm rounded-circle">
                                            <div class="ms-3">
                                                <a href="javascript:void(0);">
                                                    <h6 class="fs-15 mb-1">Coin Journal is dedicated</h6>
                                                </a>
                                                <p class="mb-0 text-muted">11,752 Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <apx-chart [series]="minichart2.series" [chart]="minichart2.chart" [colors]="minichart2.colors" 
                                        [stroke]="minichart2.stroke" [tooltip]="minichart2.tooltip"></apx-chart>
                                    </td>
                                    <td class="text-end">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-15 mb-1">$632,000+</h6>
                                        </a>
                                        <p class="mb-0 text-muted">Total USD</p>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/img-03.jpg" alt="" class="avatar-sm rounded-circle">
                                            <div class="ms-3">
                                                <a href="javascript:void(0);">
                                                    <h6 class="fs-15 mb-1">The Bitcoin-holding U.S.</h6>
                                                </a>
                                                <p class="mb-0 text-muted">7,526 Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <apx-chart [series]="minichart3.series" [chart]="minichart3.chart" [colors]="minichart3.colors" 
                                        [stroke]="minichart3.stroke" [tooltip]="minichart3.tooltip"></apx-chart>
                                    </td>
                                    <td class="text-end">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-15 mb-1">$468,000+</h6>
                                        </a>
                                        <p class="mb-0 text-muted">Total USD</p>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/img-04.jpg" alt="" class="avatar-sm rounded-circle">
                                            <div class="ms-3">
                                                <a href="javascript:void(0);">
                                                    <h6 class="fs-15 mb-1">Cryptocurrency Price Bitcoin</h6>
                                                </a>
                                                <p class="mb-0 text-muted">15,521 Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <apx-chart [series]="minichart4.series" [chart]="minichart4.chart" [colors]="minichart4.colors" 
                                        [stroke]="minichart4.stroke" [tooltip]="minichart4.tooltip"></apx-chart>
                                    </td>
                                    <td class="text-end">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-15 mb-1">$265,000+</h6>
                                        </a>
                                        <p class="mb-0 text-muted">Total USD</p>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/img-05.jpg" alt="" class="avatar-sm rounded-circle">
                                            <div class="ms-3">
                                                <a href="javascript:void(0);">
                                                    <h6 class="fs-15 mb-1">Dash, Ripple and Litecoin</h6>
                                                </a>
                                                <p class="mb-0 text-muted">12,652 Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <apx-chart [series]="minichart5.series" [chart]="minichart5.chart" [colors]="minichart5.colors" 
                                        [stroke]="minichart5.stroke" [tooltip]="minichart5.tooltip"></apx-chart>
                                    </td>
                                    <td class="text-end">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-15 mb-1">$456,000+</h6>
                                        </a>
                                        <p class="mb-0 text-muted">Total USD</p>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/img-06.jpg" alt="" class="avatar-sm rounded-circle">
                                            <div class="ms-3">
                                                <a href="javascript:void(0);">
                                                    <h6 class="fs-15 mb-1">The Cat X Takashi</h6>
                                                </a>
                                                <p class="mb-0 text-muted">11,745 Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <apx-chart [series]="minichart6.series" [chart]="minichart6.chart" [colors]="minichart6.colors" 
                                        [stroke]="minichart6.stroke" [tooltip]="minichart6.tooltip"></apx-chart>
                                    </td>
                                    <td class="text-end">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-15 mb-1">$256,000+</h6>
                                        </a>
                                        <p class="mb-0 text-muted">Total USD</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/img-01.jpg" alt="" class="avatar-sm rounded-circle">
                                            <div class="ms-3">
                                                <a href="javascript:void(0);">
                                                    <h6 class="fs-15 mb-1">Long-tailed Macaque</h6>
                                                </a>
                                                <p class="mb-0 text-muted">41,032 Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <apx-chart [series]="minichart7.series" [chart]="minichart7.chart" [colors]="minichart7.colors" 
                                        [stroke]="minichart7.stroke" [tooltip]="minichart7.tooltip"></apx-chart>
                                    </td>
                                    <td class="text-end">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-15 mb-1">$745,000+</h6>
                                        </a>
                                        <p class="mb-0 text-muted">Total USD</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/nft/gif/img-5.gif" alt="" class="avatar-sm rounded-circle">
                                            <div class="ms-3">
                                                <a href="javascript:void(0);">
                                                    <h6 class="fs-15 mb-1">Evolved Reality</h6>
                                                </a>
                                                <p class="mb-0 text-muted">513,794 Sales</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <apx-chart [series]="minichart8.series" [chart]="minichart8.chart" [colors]="minichart8.colors" 
                                        [stroke]="minichart8.stroke" [tooltip]="minichart8.tooltip"></apx-chart>
                                    </td>
                                    <td class="text-end">
                                        <a href="javascript:void(0);">
                                            <h6 class="fs-15 mb-1">$870,000+</h6>
                                        </a>
                                        <p class="mb-0 text-muted">Total USD</p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </ngx-simplebar>
                </div>
            </div>
        </div>
    </div><!--end col-->
</div><!--end row-->

<div class="row">
    <div class="col-xxl-6">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Recent NFTs</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="fw-semibold text-uppercase fs-12">Sort by: </span><span class="text-muted">Popular <i class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu >
                            <a class="dropdown-item" href="javascript:void(0);">Popular</a>
                            <a class="dropdown-item" href="javascript:void(0);">Newest</a>
                            <a class="dropdown-item" href="javascript:void(0);">Oldest</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive table-card">
                    <table class="table table-borderless table-centered align-middle table-nowrap mb-0">
                        <thead class="text-muted bg-soft-light">
                            <tr>
                                <th>Collection</th>
                                <th>Volume</th>
                                <th>24h %</th>
                                <th>Creators</th>
                                <th>Items</th>
                            </tr>
                        </thead><!-- end thead -->
                        <tbody>
                            <tr *ngFor="let data of recentData">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="me-2">
                                            <img src="{{data.img}}" alt="" class="avatar-xs rounded-circle">
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="mb-1"><a routerLink="/marletplace/item-details">{{data.title}}</a></h6>
                                            <p class="text-muted mb-0"> {{data.category}}</p>
                                        </div>
                                    </div>
                                </td>
                                <td><img src="{{data.icon}}" class="avatar-xxs me-2" alt="">{{data.volume}}</td>
                                <td>
                                    <span class="text-success mb-0"><i class="mdi mdi-trending-up align-middle me-1"></i>{{data.hours}}
                                    </span>
                                </td>
                                <td>{{data.creators}}</td>
                                <td>{{data.items}}</td>
                            </tr><!-- end -->
                        </tbody><!-- end tbody -->
                    </table><!-- end table -->
                </div><!-- end tbody -->

            </div>
        </div>
    </div><!--end col-->
    <!--end card-->
    <div class="col-xxl-3 col-lg-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Worldwide Top Creators</h4>
                <div class="flex-shrink-0">
                    <button type="button" class="btn btn-soft-primary btn-sm">
                        Export Report
                    </button>
                </div>
            </div><!-- end card header -->

            <!-- card body -->
            <div class="card-body">

                <div id="sales-by-locations" leaflet style="height: 250px;" [leafletOptions]="options" [leafletLayers]="layers"></div>

                <div class="mt-1">
                    <p class="mb-1"><img src="assets/images/flags/us.svg" alt="" height="15" class="rounded me-2"> United States <span class="float-end">34%</span></p>
                    <p class="mb-1"><img src="assets/images/flags/russia.svg" alt="" height="15" class="rounded me-2"> Russia <span class="float-end">27%</span></p>
                    <p class="mb-1"><img src="assets/images/flags/spain.svg" alt="" height="15" class="rounded me-2"> Spain <span class="float-end">21%</span></p>
                    <p class="mb-1"><img src="assets/images/flags/italy.svg" alt="" height="15" class="rounded me-2"> Italy <span class="float-end">13%</span></p>
                    <p class="mb-0"><img src="assets/images/flags/germany.svg" alt="" height="15" class="rounded me-2"> Germany <span class="float-end">5%</span></p>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div><!--end col-->
    <div class="col-xxl-3 col-lg-6">
        <div class="card">
            <div class="card-header d-flex align-items-center">
                <h6 class="card-title flex-grow-1 mb-0">Top Collections</h6>
                <a routerLink="/marletplace/collections" type="button" class="btn btn-soft-primary btn-sm flex-shrink-0">
                    See All <i class="ri-arrow-right-line align-bottom"></i>
                </a>
            </div>
            <div class="card-body">
                <swiper [config]="collection">
                    <div class="swiper-slide" *ngFor="let data of topCollectionData">
                        <div class="dash-collection overflow-hidden rounded-top position-relative">
                            <img src="{{data.img}}" alt="" height="220" class="object-cover w-100" />
                            <div class="content position-absolute bottom-0 m-2 p-2 start-0 end-0 rounded d-flex align-items-center">
                                <div class="flex-grow-1">
                                    <a href="javascript:void(0);">
                                        <h5 class="text-white fs-16 mb-1">{{data.category}}</h5>
                                    </a>
                                    <p class="text-white-75 mb-0">{{data.items}} Items</p>
                                </div>
                                <div class="avatar-xxs">
                                    <div class="avatar-title bg-white rounded-circle">
                                        <a href="javascript:void(0);" class="link-success"><i class="ri-arrow-right-line"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper> 
                <!--end swiper-->
            </div>
        </div>
        <div class="card">
            <div class="card-header d-flex align-items-center">
                <h5 class="card-title flex-grow-1 mb-0">Popular Creators</h5>
                <a routerLink="/marletplace/creators" type="button" class="btn btn-soft-primary btn-sm flex-shrink-0">
                    See All <i class="ri-arrow-right-line align-bottom"></i>
                </a>
            </div>
            <div class="card-body">
                    <swiper [config]="collection">
                        <div class="swiper-slide" *ngFor="let data of popularData">
                            <div class="d-flex">
                                <div class="flex-shink-0">
                                    <img src="{{data.img}}" alt="" class="avatar-sm object-cover rounded">
                                </div>
                                <div class="ms-3 flex-grow-1">
                                    <a routerLink="/pages/profile">
                                        <h5 class="mb-1">{{data.author}}</h5>
                                    </a>
                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> {{data.price}}</p>
                                </div>
                                <div>
                                    <div class="dropdown float-end" ngbDropdown >
                                        <button class="btn btn-ghost-primary btn-icon dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                            <i class="ri-more-fill align-middle fs-16"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu >
                                            <li><a class="dropdown-item" href="javascript:void(0);">View</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">Share</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">Report</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper>
                <!--end swiper-->
            </div>
        </div>
    </div><!--end col-->
</div><!--end row-->