import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutsModule } from "./layouts/layouts.module";
import { PagesModule } from "./pages/pages.module";

// Auth
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
// import { initFirebaseBackend } from './authUtils';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { ReactiveFormsModule } from '@angular/forms';

// Language
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { getFirebaseBackend, initFirebaseBackend } from './authUtils';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

if (environment.defaultauth === 'firebase') {
    // console.info('prueba', environment.defaultauth);
    initFirebaseBackend(environment.firebaseConfig);
}
// else {
//   this.router.navigate(['/']);
//   console.info('prueba2',environment.defaultauth );
//   FakeBackendInterceptor;
// }

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        LayoutsModule,
        PagesModule,
        ReactiveFormsModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: []
})
export class AppModule implements DoBootstrap {

    constructor(private router: Router) { }

    ngDoBootstrap(app: any) {

        let user = getFirebaseBackend()?.getAuthenticatedUser();

        // console.log(user.stsTokenManager.accessToken);
        // if (user) {
        //     app.bootstrap(AppComponent);
        // } else {
        //     console.log(" Mandando a signin");
        //     // this.router.navigateByUrl('/auth/signin/cover');
        //     app.bootstrap(AppComponent);
        // }
        app.bootstrap(AppComponent);

    }
}
