import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';


class FirebaseAuthBackend {

    constructor(firebaseConfig: any) {
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            // firebase.auth().onAuthStateChanged((user: any) => {
            //     if (user) {
            //         sessionStorage.setItem('authUser', JSON.stringify(user));
            //     } else {
            //         sessionStorage.removeItem('authUser');
            //     }
            // });
        }
    }

    /**
     * Registers the user with given details
     */
    registerUser = (email: any, password: any) => {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password).then((user: any) => {
                var user: any = firebase.auth().currentUser;
                resolve(user);
            }, (error: any) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email: any, password: any) => {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then((user: any) => {
                // eslint-disable-next-line no-redeclare
                var user: any = firebase.auth().currentUser;
                firebase.auth().currentUser?.getIdToken().then(jwt=>{
                    user = {
                        uid: user.uid,
                        displayName: user.displayName,
                        phoneNumber: user.phoneNumber,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        jwt:jwt
                    }
                    // user.jwt = jwt;
                    this.setLoggeedInUser(user);
                    resolve(user);
                });

                
                // user.jwtToken = firebase.auth().currentUser?.getIdToken();
                
            }, (error: any) => {
                reject(this._handleError(error));
            });
        });
    }

    refreshToken(){
        return firebase.auth().currentUser?.getIdToken();
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                sessionStorage.removeItem('authUser');
                resolve(true);
            }).catch((error: any) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email: any) => {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + '//' + window.location.host + '/login' }).then(() => {
                resolve(true);
            }).catch((error: any) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * verify Code Password user with given details
     */
     verifyCodePassword= (code: any) => {
        return new Promise((resolve, reject) => {
            firebase.auth().verifyPasswordResetCode(code).then(() => {
                resolve(true);
            }).catch((error: any) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * verify Code Password user with given details
     */
     confirmPassword= (code: any, password:any) => {
        return new Promise((resolve, reject) => {
            firebase.auth().confirmPasswordReset(code, password).then(() => {
                resolve(true);
            }).catch((error: any) => {
                reject(this._handleError(error));
            });
        });
    }

    setLoggeedInUser = (user: any) => {
        sessionStorage.setItem('authUser', JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem('authUser')) {
            return null;
        }
        const user = JSON.parse(sessionStorage.getItem('authUser')!)
        if(user.email === 'ltejeda@t3direct.com'){
            if(sessionStorage.getItem('customerUser')){
                const customerUser = JSON.parse(sessionStorage.getItem('customerUser')!);
                user.IdUser = customerUser.idUser;
                user.news_watched = customerUser.news_watched;
            }
        }
        return user;
    }

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error: any) {
        // tslint:disable-next-line: prefer-const
        var errorMessage = error.message;
        return errorMessage;
    }
}

// tslint:disable-next-line: variable-name
let _fireBaseBackend: FirebaseAuthBackend | null = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config: any) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
