<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Orders" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
  <div class="col-lg-12">
      <div class="card"  id="orderList">
          <div class="card-header  border-0">
              <div class="d-flex align-items-center">
                  <h5 class="card-title mb-0 flex-grow-1">Order History</h5>
                  <div class="flex-shrink-0 hstack gap-2">
                      <button type="button" class="btn btn-success add-btn" data-bs-toggle="modal" id="create-btn"
                          data-bs-target="#showModal" (click)="openModal(content)"><i class="ri-add-line align-bottom me-1"></i> Create
                          Order</button>
                      <button type="button" class="btn btn-info"><i class="ri-file-download-line align-bottom me-1"></i> Import</button>
                      <button class="btn btn-soft-danger" onClick="deleteMultiple()"><i
                        class="ri-delete-bin-2-line"></i></button>
                  </div>
              </div>
          </div>
          <div class="card-body border border-dashed border-end-0 border-start-0">
              <form>
                  <div class="row g-3">
                      <div class="col-xxl-5 col-sm-6">
                          <div class="search-box">
                              <input type="text"  name="searchTerm" class="form-control" placeholder="Search for order ID, customer, order status or something..." [(ngModel)]="service.searchTerm">
                              <i class="ri-search-line search-icon"></i>
                          </div>
                      </div>
                      <!--end col-->
                      <div class="col-xxl-2 col-sm-6">
                          <div>
                              <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" placeholder="Select date">
                          </div>
                      </div>
                      <!--end col-->
                      <div class="col-xxl-2 col-sm-4">
                          <div>
                              <select class="form-control" data-choices data-choices-search-false name="choices-single-default" id="idStatus">
                                  <option value="">Status</option>
                                  <option value="all" selected>All</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Inprogress">Inprogress</option>
                                  <option value="Cancelled">Cancelled</option>
                                  <option value="Pickups">Pickups</option>
                                  <option value="Returns">Returns</option>
                                  <option value="Delivered">Delivered</option>
                              </select>
                          </div>
                      </div>
                      <!--end col-->
                      <div class="col-xxl-2 col-sm-4">
                          <div>
                              <select class="form-control" data-choices data-choices-search-false name="choices-single-default"
                                  id="idPayment">
                                  <option value="">Select Payment</option>
                                  <option value="all" selected>All</option>
                                  <option value="Mastercard">Mastercard</option>
                                  <option value="Paypal">Paypal</option>
                                  <option value="Visa">Visa</option>
                                  <option value="COD">COD</option>
                              </select>
                          </div>
                      </div>
                      <!--end col-->
                      <div class="col-xxl-1 col-sm-4">
                          <div>
                              <button type="button" class="btn btn-primary w-100" onclick="SearchData();"> <i class="ri-equalizer-fill me-1 align-bottom"></i>
                                  Filters
                              </button>
                          </div>
                      </div>
                      <!--end col-->
                  </div>
                  <!--end row-->
              </form>
          </div>
          <div class="card-body pt-0">
              <div>
                  <!-- Nav tabs -->
                  <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs nav-tabs-custom nav-success mb-3">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>
                          <i class="ri-store-2-fill me-1 align-bottom"></i> All Orders
                        </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive table-card mb-0">
                              <table class="table">
                                <thead>
                                <tr class="bg-light text-muted">
                                  <th scope="col" style="width: 25px;">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="checkAll" value="option" [(ngModel)]="masterSelected" (change)="checkUncheckAll()">
                                    </div>
                                </th>
                                <th class="sort" data-sort="id">Order ID</th>
                                <th class="sort" data-sort="customer_name">Customer</th>
                                <th class="sort" data-sort="product_name">Product</th>
                                <th class="sort" data-sort="date">Order Date</th>
                                <th class="sort" data-sort="amount">Amount</th>
                                <th class="sort" data-sort="payment">Payment Method</th>
                                <th class="sort" data-sort="status">Delivery Status</th>
                                <th class="sort" data-sort="city">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of CustomersData">
                                  <th scope="row">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="checkAll" value="option1" [(ngModel)]="data.isSelected">
                                    </div>
                                  </th>
                                  <td><ngb-highlight [result]="data.id" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.name" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.product" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.date" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.amount" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.pmethod" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td class="status"><span class="badge text-uppercase" [ngClass]=" { 'badge-soft-warning': data.status === 'PENDING', 'badge-soft-danger': data.status === 'CANCELLED', 'badge-soft-secondary': data.status === 'INPROGRESS', 'badge-soft-info': data.status === 'PICKUPS', 'badge-soft-primary': data.status === 'RETURNS', 'badge-soft-success': data.status === 'DELIVERED' }">{{data.status}}</span>
                                  </td>
                                  <td>
                                    <ul class="list-inline hstack gap-2 mb-0">
                                        <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" ngbTooltip="View" placement="top">
                                            <a routerLink="/ecommerce/order-details" class="text-primary d-inline-block">
                                                <i class="ri-eye-fill fs-16"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" ngbTooltip="Edit" placement="top">
                                            <a href="javascript:void(0);" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn">
                                                <i class="ri-pencil-fill fs-16"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item" ngbTooltip="Remove" placement="top" data-bs-toggle="tooltip" data-bs-trigger="hover" (click)="confirm()">
                                            <a class="text-danger d-inline-block remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteOrder">
                                                <i class="ri-delete-bin-5-fill fs-16"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="row justify-content-md-between align-items-md-center gy-2">
                              <div class="col col-sm-6">
                                  <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                      Showing
                                      {{service.startIndex}} to
                                      {{service.endIndex}} of {{service.totalRecords}}
                                      entries
                                  </div>
                              </div>
                              <!-- Pagination -->
                              <div class="col col-sm-6">
                                  <div class="text-sm-right float-sm-end listjs-pagination">
                                    <ngb-pagination
                                    [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                  </ngb-pagination>
                                  </div>
                              </div>
                              <!-- End Pagination -->
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink >
                          <i class="ri-checkbox-circle-line me-1 align-bottom"></i> Delivered
                        </a>
                        <ng-template ngbNavContent>
                          <div class="table-responsive table-card mb-0">
                            <table class="table">
                              <thead>
                              <tr class="bg-light text-muted">
                                <th scope="col" style="width: 25px;">
                                  <div class="form-check">
                                      <input class="form-check-input" type="checkbox" id="checkAll" value="option">
                                  </div>
                              </th>
                              <th class="sort" data-sort="id">Order ID</th>
                              <th class="sort" data-sort="customer_name">Customer</th>
                              <th class="sort" data-sort="product_name">Product</th>
                              <th class="sort" data-sort="date">Order Date</th>
                              <th class="sort" data-sort="amount">Amount</th>
                              <th class="sort" data-sort="payment">Payment Method</th>
                              <th class="sort" data-sort="status">Delivery Status</th>
                              <th class="sort" data-sort="city">Action</th>
                              </tr>
                              </thead>
                              <tbody>
                                <tr  *ngFor="let data of ordersList$ | async">
                                  <ng-template [ngIf]="data.status == 'DELIVERED'">
                                    <th scope="row">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="checkAll" value="option1">
                                    </div>
                                    </th>
                                    <td><ngb-highlight [result]="data.id" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td><ngb-highlight [result]="data.name" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td><ngb-highlight [result]="data.product" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td><ngb-highlight [result]="data.date" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td><ngb-highlight [result]="data.amount" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td><ngb-highlight [result]="data.pmethod" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td class="status"><span class="badge text-uppercase" [ngClass]=" { 'badge-soft-success': data.status === 'DELIVERED'}">{{data.status}}</span>
                                    </td>
                                    <td>
                                    <ul class="list-inline hstack gap-2 mb-0">
                                        <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                            <a routerLink="/ecommerce/order-details" class="text-primary d-inline-block">
                                                <i class="ri-eye-fill fs-16"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                            <a href="javascript:void(0);" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn">
                                                <i class="ri-pencil-fill fs-16"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Remove" (click)="confirm()">
                                            <a class="text-danger d-inline-block remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteOrder">
                                                <i class="ri-delete-bin-5-fill fs-16"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    </td>
                                  </ng-template>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row justify-content-md-between align-items-md-center mt-2">
                            <div class="col col-sm-6">
                                <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                    Showing
                                    {{service.startIndex}} to
                                    {{service.endIndex}} of {{service.totalRecords}}
                                    entries
                                </div>
                            </div>
                            <!-- Pagination -->
                            <div class="col col-sm-6">
                                <div class="text-sm-right float-sm-end listjs-pagination">
                                  <ngb-pagination
                                  [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                </ngb-pagination>
                                </div>
                            </div>
                            <!-- End Pagination -->
                          </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink >
                          <i class="ri-truck-line me-1 align-bottom"></i> Pickups <span class="badge bg-danger align-middle ms-1">2</span>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="table-responsive">
                              <table class="table ">
                                <thead>
                                <tr>
                                  <th scope="col" style="width: 25px;">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="checkAll" value="option">
                                    </div>
                                </th>
                                <th class="sort" data-sort="id">Order ID</th>
                                <th class="sort" data-sort="customer_name">Customer</th>
                                <th class="sort" data-sort="product_name">Product</th>
                                <th class="sort" data-sort="date">Order Date</th>
                                <th class="sort" data-sort="amount">Amount</th>
                                <th class="sort" data-sort="payment">Payment Method</th>
                                <th class="sort" data-sort="status">Delivery Status</th>
                                <th class="sort" data-sort="city">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                  <tr  *ngFor="let data of ordersList$ | async">
                                    <ng-template [ngIf]="data.status == 'PICKUPS'">
                                      <th scope="row">
                                      <div class="form-check">
                                          <input class="form-check-input" type="checkbox" name="checkAll" value="option1">
                                      </div>
                                      </th>
                                      <td><ngb-highlight [result]="data.id" [term]="service.searchTerm"></ngb-highlight></td>
                                      <td><ngb-highlight [result]="data.name" [term]="service.searchTerm"></ngb-highlight></td>
                                      <td><ngb-highlight [result]="data.product" [term]="service.searchTerm"></ngb-highlight></td>
                                      <td><ngb-highlight [result]="data.date" [term]="service.searchTerm"></ngb-highlight></td>
                                      <td><ngb-highlight [result]="data.amount" [term]="service.searchTerm"></ngb-highlight></td>
                                      <td><ngb-highlight [result]="data.pmethod" [term]="service.searchTerm"></ngb-highlight></td>
                                      <td class="status"><span class="badge text-uppercase" [ngClass]=" { 'badge-soft-info': data.status === 'PICKUPS'}">{{data.status}}</span>
                                      </td>
                                      <td>
                                      <ul class="list-inline hstack gap-2 mb-0">
                                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                              <a routerLink="/ecommerce/order-details" class="text-primary d-inline-block">
                                                  <i class="ri-eye-fill fs-16"></i>
                                              </a>
                                          </li>
                                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                              <a href="javascript:void(0);" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn">
                                                  <i class="ri-pencil-fill fs-16"></i>
                                              </a>
                                          </li>
                                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Remove" (click)="confirm()">
                                              <a class="text-danger d-inline-block remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteOrder">
                                                  <i class="ri-delete-bin-5-fill fs-16"></i>
                                              </a>
                                          </li>
                                      </ul>
                                      </td>
                                    </ng-template>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="row justify-content-md-between align-items-md-center mt-2">
                              <div class="col col-sm-6">
                                  <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                      Showing
                                      {{service.startIndex}} to
                                      {{service.endIndex}} of {{service.totalRecords}}
                                      entries
                                  </div>
                              </div>
                              <!-- Pagination -->
                              <div class="col col-sm-6">
                                  <div class="text-sm-right float-sm-end listjs-pagination">
                                    <ngb-pagination
                                    [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                  </ngb-pagination>
                                  </div>
                              </div>
                              <!-- End Pagination -->
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                      <a ngbNavLink >
                        <i class="ri-arrow-left-right-fill me-1 align-bottom"></i> Returns
                      </a>
                      <ng-template ngbNavContent>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                            <tr>
                              <th scope="col" style="width: 25px;">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkAll" value="option">
                                </div>
                            </th>
                            <th class="sort" data-sort="id">Order ID</th>
                            <th class="sort" data-sort="customer_name">Customer</th>
                            <th class="sort" data-sort="product_name">Product</th>
                            <th class="sort" data-sort="date">Order Date</th>
                            <th class="sort" data-sort="amount">Amount</th>
                            <th class="sort" data-sort="payment">Payment Method</th>
                            <th class="sort" data-sort="status">Delivery Status</th>
                            <th class="sort" data-sort="city">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr  *ngFor="let data of ordersList$ | async">
                                <ng-template [ngIf]="data.status == 'RETURNS'">
                                  <th scope="row">
                                  <div class="form-check">
                                      <input class="form-check-input" type="checkbox" name="checkAll" value="option1">
                                  </div>
                                  </th>
                                  <td><ngb-highlight [result]="data.id" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.name" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.product" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.date" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.amount" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.pmethod" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td class="status"><span class="badge text-uppercase" [ngClass]=" { 'badge-soft-primary': data.status === 'RETURNS'}">{{data.status}}</span>
                                  </td>
                                  <td>
                                  <ul class="list-inline hstack gap-2 mb-0">
                                      <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                          <a routerLink="/ecommerce/order-details" class="text-primary d-inline-block">
                                              <i class="ri-eye-fill fs-16"></i>
                                          </a>
                                      </li>
                                      <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                          <a href="javascript:void(0);" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn">
                                              <i class="ri-pencil-fill fs-16"></i>
                                          </a>
                                      </li>
                                      <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Remove" (click)="confirm()">
                                          <a class="text-danger d-inline-block remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteOrder">
                                              <i class="ri-delete-bin-5-fill fs-16"></i>
                                          </a>
                                      </li>
                                  </ul>
                                  </td>
                                </ng-template>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row justify-content-md-between align-items-md-center mt-2">
                          <div class="col col-sm-6">
                              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                  Showing
                                  {{service.startIndex}} to
                                  {{service.endIndex}} of {{service.totalRecords}}
                                  entries
                              </div>
                          </div>
                          <!-- Pagination -->
                          <div class="col col-sm-6">
                              <div class="text-sm-right float-sm-end listjs-pagination">
                                <ngb-pagination
                                [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                              </ngb-pagination>
                              </div>
                          </div>
                          <!-- End Pagination -->
                        </div>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="5">
                      <a ngbNavLink >
                        <i class="ri-close-circle-line me-1 align-bottom"></i> Cancelled
                      </a>
                      <ng-template ngbNavContent>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                            <tr>
                              <th scope="col" style="width: 25px;">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="checkAll" value="option">
                                </div>
                            </th>
                            <th class="sort" data-sort="id">Order ID</th>
                            <th class="sort" data-sort="customer_name">Customer</th>
                            <th class="sort" data-sort="product_name">Product</th>
                            <th class="sort" data-sort="date">Order Date</th>
                            <th class="sort" data-sort="amount">Amount</th>
                            <th class="sort" data-sort="payment">Payment Method</th>
                            <th class="sort" data-sort="status">Delivery Status</th>
                            <th class="sort" data-sort="city">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr  *ngFor="let data of ordersList$ | async">
                                <ng-template [ngIf]="data.status == 'CANCELLED'">
                                  <th scope="row">
                                  <div class="form-check">
                                      <input class="form-check-input" type="checkbox" name="checkAll" value="option1">
                                  </div>
                                  </th>
                                  <td><ngb-highlight [result]="data.id" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.name" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.product" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.date" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.amount" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td><ngb-highlight [result]="data.pmethod" [term]="service.searchTerm"></ngb-highlight></td>
                                  <td class="status"><span class="badge text-uppercase" [ngClass]=" { 'badge-soft-danger': data.status === 'CANCELLED'}">{{data.status}}</span>
                                  </td>
                                  <td>
                                  <ul class="list-inline hstack gap-2 mb-0">
                                      <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                          <a routerLink="/ecommerce/order-details" class="text-primary d-inline-block">
                                              <i class="ri-eye-fill fs-16"></i>
                                          </a>
                                      </li>
                                      <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                          <a href="javascript:void(0);" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn">
                                              <i class="ri-pencil-fill fs-16"></i>
                                          </a>
                                      </li>
                                      <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Remove" (click)="confirm()">
                                          <a class="text-danger d-inline-block remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteOrder">
                                              <i class="ri-delete-bin-5-fill fs-16"></i>
                                          </a>
                                      </li>
                                  </ul>
                                  </td>
                                </ng-template>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row justify-content-md-between align-items-md-center mt-2">
                          <div class="col col-sm-6">
                              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                  Showing
                                  {{service.startIndex}} to
                                  {{service.endIndex}} of {{service.totalRecords}}
                                  entries
                              </div>
                          </div>
                          <!-- Pagination -->
                          <div class="col col-sm-6">
                              <div class="text-sm-right float-sm-end listjs-pagination">
                                <ngb-pagination
                                [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                              </ngb-pagination>
                              </div>
                          </div>
                          <!-- End Pagination -->
                        </div>
                      </ng-template>
                    </li>
                  </ul>

                  <!-- Tab panes -->
                  <div class="tab-content text-muted">
                    <div [ngbNavOutlet]="nav"></div>
                  </div>

              <!-- Order Create Model -->
              <ng-template #content role="document" let-modal>
                <div class="modal-header bg-light p-3">
                  <h5 class="modal-title" id="exampleModalLabel">Add Order</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.dismiss('Cross click')"></button>
                </div>
                <form (ngSubmit)="saveUser()" [formGroup]="ordersForm">
                  <div class="modal-body">
                      <div class="mb-3">
                          <label for="customername-field" class="form-label">Customer Name</label>
                          <input type="text" id="customername-field" class="form-control" placeholder="Enter Name" required  formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"/>
                          <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="form['name'].errors['required']">Customer Name is required</div>
                          </div>
                      </div>

                      <div class="mb-3">
                          <label for="productname-field" class="form-label">Product</label>
                          <select class="form-control" data-trigger name="productname-field" id="productname-field" formControlName="productname" [ngClass]="{ 'is-invalid': submitted && form['productname'].errors }">
                              <option value="">Product</option>
                              <option value="Puma Tshirt">Puma Tshirt</option>
                              <option value="Adidas Sneakers">Adidas Sneakers</option>
                              <option value="350 ml Glass Grocery Container">350 ml Glass Grocery Container</option>
                              <option value="American egale outfitters Shirt">American egale outfitters Shirt</option>
                              <option value="Galaxy Watch4">Galaxy Watch4</option>
                              <option value="Apple iPhone 12">Apple iPhone 12</option>
                              <option value="Funky Prints T-shirt">Funky Prints T-shirt</option>
                              <option value="USB Flash Drive Personalized with 3D Print">USB Flash Drive Personalized with 3D Print</option>
                              <option value="Oxford Button-Down Shirt">Oxford Button-Down Shirt</option>
                              <option value="Classic Short Sleeve Shirt">Classic Short Sleeve Shirt</option>
                              <option value="Half Sleeve T-Shirts (Blue)">Half Sleeve T-Shirts (Blue)</option>
                              <option value="Noise Evolve Smartwatch">Noise Evolve Smartwatch</option>
                          </select>
                          <div *ngIf="submitted && form['productname'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="form['productname'].errors['required']">product Name is required</div>
                          </div>
                      </div>

                      <div class="mb-3">
                          <label for="date-field" class="form-label">Order Date</label>
                          <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true">
                      </div>

                      <div class="row gy-4 mb-3">
                          <div class="col-md-6">
                              <div>
                                  <label for="amount-field" class="form-label">Amount</label>
                                  <input type="text" id="amount-field" class="form-control"  placeholder="Total amount" required formControlName="amount" [ngClass]="{ 'is-invalid': submitted && form['amount'].errors }"/>
                                  <div *ngIf="submitted && form['amount'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['amount'].errors['required']">Amount is required</div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div>
                                  <label for="payment-field" class="form-label">Payment
                                      Method</label>
                                  <select class="form-control" data-trigger name="payment-method" id="payment-field" formControlName="payment" [ngClass]="{ 'is-invalid': submitted && form['payment'].errors }">
                                      <option value="">Payment Method</option>
                                      <option value="Mastercard">Mastercard</option>
                                      <option value="Visa">Visa</option>
                                      <option value="COD">COD</option>
                                      <option value="Paypal">Paypal</option>
                                  </select>
                                  <div *ngIf="submitted && form['payment'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['payment'].errors['required']">Payment Method is required</div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div>
                          <label for="delivered-status" class="form-label">Delivery Status</label>
                          <select class="form-control" data-trigger name="delivered-status" id="delivered-status" formControlName="delivered" [ngClass]="{ 'is-invalid': submitted && form['delivered'].errors }">
                              <option value="">Delivery Status</option>
                              <option value="PENDING">Pending</option>
                              <option value="INPROGRESS">Inprogress</option>
                              <option value="CANCELLED">Cancelled</option>
                              <option value="PICKUPS">Pickups</option>
                              <option value="DELIVERED">Delivered</option>
                              <option value="RETURNS">Returns</option>
                          </select>
                          <div *ngIf="submitted && form['delivered'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="form['delivered'].errors['required']">Delivered Status is required</div>
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                          <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Close</button>
                          <button type="submit" class="btn btn-success" id="add-btn">Add Order</button>
                      </div>
                  </div>
              </form>
              </ng-template>
              <!--End Modal -->
        </div>
      </div>

  </div><!--end col-->
</div><!--end row-->
