<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Shopping Cart" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->


<div class="row mb-3">
  <div class="col-xl-8">
      <div class="row align-items-center gy-3 mb-3">
          <div class="col-sm">
              <div>
                  <h5 class="fs-14 mb-0">Your Cart (03 items)</h5>
              </div>
          </div>
          <div class="col-sm-auto">
              <a routerLink="/ecommerce/products" class="link-primary text-decoration-underline">Continue Shopping</a>
          </div>
      </div>

      <div class="card product" *ngFor="let data of cartData">
          <div class="card-body">
              <div class="row gy-3">
                  <div class="col-sm-auto">
                      <div class="avatar-lg bg-light rounded p-1">
                          <img src="{{data.image}}" alt="" class="img-fluid d-block">
                      </div>
                  </div>
                  <div class="col-sm">
                      <h5 class="fs-14 text-truncate"><a routerLink="/ecommerce/product-detail/1" class="text-dark">{{data.name}}</a></h5>
                      <ul class="list-inline text-muted">
                          <li class="list-inline-item">Color : <span class="fw-medium">{{data.color}}</span></li>
                          <li class="list-inline-item">Size : <span class="fw-medium">{{data.size}}</span></li>
                      </ul>

                      <div class="input-step">
                          <button type="button" class="minus" (click)="decrement(data.id)">–</button>
                          <input type="number" class="product-quantity" id="cart-{{data.id}}" value="{{data.quantity}}" min="0" max="100">
                          <button type="button" class="plus" (click)="increment(data.id)">+</button>
                      </div>
                  </div>
                  <div class="col-sm-auto">
                      <div class="text-lg-end">
                          <p class="text-muted mb-1">Item Price:</p>
                          <h5 class="fs-14">$<span id="ticket_price" class="product-price">{{data.price}}</span></h5>
                      </div>
                  </div>
              </div>
          </div>
          <!-- card body -->
          <div class="card-footer">
              <div class="row align-items-center gy-3">
                  <div class="col-sm">
                      <div class="d-flex flex-wrap my-n1">
                          <div>
                              <a href="javascript:void(0);" class="d-block text-body p-1 px-2" data-bs-toggle="modal" data-bs-target="#removeItemModal" (click)="confirm()"><i class="ri-delete-bin-fill text-muted align-bottom me-1"></i> Remove</a>
                          </div>
                          <div>
                              <a href="javascript:void(0);" class="d-block text-body p-1 px-2"><i class="ri-star-fill text-muted align-bottom me-1"></i> Add Wishlist</a>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-auto">
                      <div class="d-flex align-items-center gap-2 text-muted">
                          <div>Total :</div>
                          <h5 class="fs-14 mb-0">$<span class="product-line-price">{{data.total}}</span></h5>
                      </div>
                  </div>
              </div>
          </div>
          <!-- end card footer -->
      </div>
      <!-- end card -->


      <div class="text-end mb-4">
          <a routerLink="/ecommerce/checkout" class="btn btn-success btn-label right ms-auto"><i class="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i> Checkout</a>
      </div>
  </div>
  <!-- end col -->

  <div class="col-xl-4">
      <div class="sticky-side-div">
          <div class="card">
              <div class="card-header border-bottom-dashed">
                  <h5 class="card-title mb-0">Order Summary</h5>
              </div>
              <div class="card-header bg-soft-light border-bottom-dashed">
                  <div class="text-center">
                      <h6 class="mb-2">Have a <span class="fw-semibold">promo</span> code ?</h6>
                  </div>
                  <div class="hstack gap-3 px-3 mx-n3">
                      <input class="form-control me-auto" type="text" placeholder="Enter coupon code" aria-label="Add Promo Code here...">
                      <button type="button" class="btn btn-success w-xs">Apply</button>
                  </div>
              </div>
              <div class="card-body pt-2">
                  <div class="table-responsive">
                      <table class="table table-borderless mb-0">
                          <tbody>
                              <tr>
                                  <td>Sub Total :</td>
                                  <td class="text-end" id="cart-subtotal">$ 359.96</td>
                              </tr>
                              <tr>
                                  <td>Discount <span class="text-muted">(VELZON15)</span> : </td>
                                  <td class="text-end" id="cart-discount">- $ 53.99</td>
                              </tr>
                              <tr>
                                  <td>Shipping Charge :</td>
                                  <td class="text-end" id="cart-shipping">$ 65.00</td>
                              </tr>
                              <tr>
                                  <td>Estimated Tax (12.5%) : </td>
                                  <td class="text-end" id="cart-tax">$ 44.99</td>
                              </tr>
                              <tr class="table-active">
                                  <th>Total (USD) :</th>
                                  <td class="text-end">
                                      <span class="fw-semibold" id="cart-total">
                                          $415.96
                                      </span>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                  <!-- end table-responsive -->
              </div>
          </div>

          <div class="alert border-dashed alert-danger" role="alert">
              <div class="d-flex align-items-center">
                <lord-icon src="https://cdn.lordicon.com/nkmsrxys.json" trigger="loop"
                    colors="primary:#121331,secondary:#f06548" style="width:80px;height:80px">
                </lord-icon>
                  <div class="ms-2">
                      <h5 class="fs-14 text-danger fw-semibold"> Buying for a loved one?</h5>
                      <p class="text-black mb-1">Gift wrap and personalised message on card, <br/>Only for <span class="fw-semibold">$9.99</span> USD</p>
                      <button type="button" class="btn ps-0 btn-sm btn-link text-danger text-uppercase">Add Gift Wrap</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- end stickey -->

  </div>
</div>
