import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-extension',
  templateUrl: './chat-extension.component.html',
  styleUrls: ['./chat-extension.component.scss']
})
export class ChatExtensionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  public loadScript() {
    let node = document.createElement('script');
    node.src = "//js.hs-scripts.com/5494960.js";
    node.type = 'text/javascript';
    node.async = true;
    node.defer=true;
    node.id="hs-script-loader";
    document.getElementsByTagName('head')[0].appendChild(node);      
  }

}
