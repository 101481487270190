<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Create Product" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
  <div class="col-lg-8">
      <form>
          <div class="card">
              <div class="card-body">
                  <div class="mb-3">
                      <label class="form-label" for="product-title-input">Product Title</label>
                      <input type="text" class="form-control" id="product-title-input"
                          placeholder="Enter product title">
                  </div>
                  <div>
                      <label>Product Description</label>
                      <ckeditor [editor]="Editor" data="
                        <p>Tommy Hilfiger men striped pink sweatshirt. Crafted with cotton. Material composition is 100% organic cotton. This is one of the world’s leading designer lifestyle brands and is internationally recognized for celebrating the essence of classic American cool style, featuring preppy with a twist designs.</p>
                        <ul>
                          <li>Full Sleeve</li>
                          <li>Cotton</li>
                          <li>All Sizes available</li>
                          <li>4 Different Color</li>
                        </ul>
                        ">
                      </ckeditor>
                  </div>
              </div>
          </div>
          <!-- end card -->

          <div class="card">
              <div class="card-header">
                  <h5 class="card-title mb-0">Product Gallery</h5>
              </div>
              <div class="card-body">
                  <div class="mb-4">
                      <h5 class="fs-14 mb-1">Product Image</h5>
                      <p class="text-muted">Add Product main Image.</p>
                      <input class="form-control" id="product-image-input" type="file" accept="image/png, image/gif, image/jpeg">
                  </div>
                  <div>
                      <h5 class="fs-14 mb-1">Product Gallery</h5>
                      <p class="text-muted">Add Product Gallery Images.</p>
                      <dropzone class="dropzone_sec"></dropzone>
                      <!-- end dropzon-preview -->
                  </div>
              </div>
          </div>
          <!-- end card -->

          <div class="card">
              <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="1"  class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                  <li [ngbNavItem]="1" class="nav-item">
                      <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                        General Info
                      </a>
                      <ng-template ngbNavContent>
                        <div class="tab-pane active" id="addproduct-general-info" role="tabpanel">
                          <div class="mb-3">
                              <label class="form-label" for="manufacturer-name-input">Manufacturer Name</label>
                              <input type="text" class="form-control" id="manufacturer-name-input"
                                  placeholder="Enter manufacturer name">
                          </div>
                          <div class="row">
                              <div class="col-lg-6">
                                  <div class="mb-3">
                                      <label class="form-label" for="manufacturer-brand-input">Manufacturer Brand</label>
                                      <input type="text" class="form-control" id="manufacturer-brand-input"
                                          placeholder="Enter manufacturer brand">
                                  </div>
                              </div>
                              <!-- end col -->
                              <div class="col-lg-6">
                                  <div class="row">
                                      <div class="col-sm-6">
                                          <div class="mb-3">
                                              <label class="form-label" for="product-price-input">Price</label>
                                              <div class="input-group mb-3">
                                                  <span class="input-group-text" id="product-price-addon">$</span>
                                                  <input type="text" class="form-control" id="product-price-input"
                                                      placeholder="Enter price" aria-label="Price"
                                                      aria-describedby="product-price-addon">

                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-sm-6">
                                          <div class="mb-3">
                                              <label class="form-label"
                                                  for="product-discount-input">Discount</label>
                                              <div class="input-group mb-3">
                                                  <span class="input-group-text"
                                                      id="product-discount-addon">%</span>
                                                  <input type="text" class="form-control"
                                                      id="product-discount-input" placeholder="Enter discount"
                                                      aria-label="discount"
                                                      aria-describedby="product-discount-addon">
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!-- end col -->
                          </div>
                          <!-- end row -->
                        </div>
                      </ng-template>
                  </li>
                  <li [ngbNavItem]="2" class="nav-item">
                      <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                        Meta Data
                      </a>
                      <ng-template ngbNavContent>
                        <div class="tab-pane" id="addproduct-metadata" role="tabpanel">
                          <div class="row">
                              <div class="col-lg-6">
                                  <div class="mb-3">
                                      <label class="form-label" for="meta-title-input">Meta title</label>
                                      <input type="text" class="form-control" placeholder="Enter meta title" id="meta-title-input">
                                  </div>
                              </div>
                              <!-- end col -->

                              <div class="col-lg-6">
                                  <div class="mb-3">
                                      <label class="form-label" for="meta-keywords-input">Meta Keywords</label>
                                      <input type="text" class="form-control" placeholder="Enter meta keywords" id="meta-keywords-input">
                                  </div>
                              </div>
                              <!-- end col -->
                          </div>
                          <!-- end row -->

                          <div>
                              <label class="form-label" for="meta-description-input">Meta Description</label>
                              <textarea class="form-control" id="meta-description-input" placeholder="Enter meta description" rows="3"></textarea>
                          </div>
                        </div>
                      </ng-template>
                  </li>
                </ul>
                <!-- Tab panes -->
                <div class="tab-content pt-4">
                  <div [ngbNavOutlet]="customNav"></div>
                </div>
              </div>
              <!-- end card header -->
          </div>
          <!-- end card -->
          <div class="text-end mb-3">
              <button type="submit" class="btn btn-success w-sm">Submit</button>
          </div>
      </form>


  </div>
  <!-- end col -->

  <div class="col-lg-4">
      <div class="card">
          <div class="card-header">
              <h5 class="card-title mb-0">Publish</h5>
          </div>
          <div class="card-body">
              <div class="mb-3">
                  <label for="choices-publish-status-input" class="form-label">Status</label>

                  <select class="form-select" id="choices-publish-status-input" data-choices data-choices-search-false>
                      <option value="Published" selected>Published</option>
                      <option value="Scheduled">Scheduled</option>
                      <option value="Draft">Draft</option>
                  </select>
              </div>

              <div>
                  <label for="choices-publish-visibility-input" class="form-label">Visibility</label>
                  <select class="form-select" id="choices-publish-visibility-input" data-choices data-choices-search-false>
                      <option value="Public" selected>Public</option>
                      <option value="Hidden">Hidden</option>
                  </select>
              </div>
          </div>
          <!-- end card body -->
      </div>
      <!-- end card -->

      <div class="card">
          <div class="card-header">
              <h5 class="card-title mb-0">Publish Schedule</h5>
          </div>
          <!-- end card body -->
          <div class="card-body">
              <div>
                  <label for="datepicker-publish-input" class="form-label">Publish Date & Time</label>
                  <input class="form-control flatpickr-input" type="text" placeholder="Enter publish date" mwlFlatpickr [altInput]="true" [enableTime]="true" [convertModelValue]="true" [dateFormat]="'Y-m-d H:i'">
              </div>
          </div>
      </div>
      <!-- end card -->

      <div class="card">
          <div class="card-header">
              <h5 class="card-title mb-0">Product Categories</h5>
          </div>
          <div class="card-body">
              <p class="text-muted mb-2"> <a href="javascript:void(0);" class="float-end text-decoration-underline">Add New</a>Select product category</p>
              <select class="form-select" name="choices-category-input" data-choices data-choices-search-false >
                  <option value="All">All</option>
                  <option value="Appliances">Appliances</option>
                  <option value="Fashion" selected>Fashion</option>
                  <option value="Electronics">Electronics</option>
                  <option value="Grocery">Grocery</option>
                  <option value="Home & Furniture">Home & Furniture</option>
                  <option value="Kids">Kids</option>
                  <option value="Mobiles">Mobiles</option>
              </select>
          </div>
          <!-- end card body -->
      </div>
      <!-- end card -->
      <div class="card">
          <div class="card-header">
              <h5 class="card-title mb-0">Product Tags</h5>
          </div>
          <div class="card-body">
              <div class="hstack gap-3 align-items-start">
                  <div class="flex-grow-1">
                    <ng-select [items]="selectValue" [multiple]="true"></ng-select>
                  </div>
              </div>
          </div>
          <!-- end card body -->
      </div>
      <!-- end card -->

      <div class="card">
          <div class="card-header">
              <h5 class="card-title mb-0">Product Short Description</h5>
          </div>
          <div class="card-body">
              <p class="text-muted mb-2">Add short description for product</p>
              <textarea class="form-control" placeholder="Must enter minimum of a 100 characters" rows="3"></textarea>
          </div>
          <!-- end card body -->
      </div>
      <!-- end card -->

  </div>
</div>
<!-- end row -->
