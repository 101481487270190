 <!-- start client section -->
 <div class="pt-5 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">

                <div class="text-center mt-5">
                    <h5 class="fs-20">Trusted <span class="text-primary text-decoration-underline">by</span> the world's best</h5>
                     <!-- Swiper -->
                    <div class="swiper trusted-client-slider mt-sm-5 mt-4 mb-sm-5 mb-4">
                        <swiper [config]="Responsive">
                            <div class="swiper-slide" *ngFor="let data of ClientLogo">
                                <div class="client-images">
                                    <img src="{{data.image}}" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </swiper>
                    </div>
                </div>

            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end client section -->