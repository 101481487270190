// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api:'https://api.admin.lmsleads.com/',
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: "AIzaSyC97mpFWAzru_N5T-t9RPeLxs9mnDcEyEA",
    authDomain: "lms-leads.firebaseapp.com",
    projectId: "lms-leads",
    storageBucket: "lms-leads.appspot.com",
    messagingSenderId: "583949757063",
    appId: "1:583949757063:web:4ddf556141003f9d13e622"
  }
};

// LOCAL DOCKER
// export const environment = {
//     production: false,
//     api:'http://localhost/',
//     defaultauth: 'firebase',
//     firebaseConfig: {
//       apiKey: "AIzaSyC97mpFWAzru_N5T-t9RPeLxs9mnDcEyEA",
//       authDomain: "lms-leads.firebaseapp.com",
//       projectId: "lms-leads",
//       storageBucket: "lms-leads.appspot.com",
//       messagingSenderId: "583949757063",
//       appId: "1:583949757063:web:4ddf556141003f9d13e622"
//     }
//   };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
