import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

constructor(private http: HttpClient) { }

getUserNotifications(userId: string){
    const url = `${environment.api}notifications/get_user_notifications/${userId}`;
    return this.http.get(url);
}

}
