<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Seller Details" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
  <div class="col-xxl-3">
      <div class="card">
          <div class="card-body p-4">
              <div>
                  <div class="flex-shrink-0 avatar-md mx-auto">
                      <div class="avatar-title bg-light rounded">
                          <img src="assets/images/companies/img-2.png" alt="" height="50" />
                      </div>
                  </div>
                  <div class="mt-4 text-center">
                      <h5 class="mb-1">Force Medicines</h5>
                      <p class="text-muted">Since 1987</p>
                  </div>
                  <div class="table-responsive">
                      <table class="table mb-0 table-borderless">
                          <tbody>
                              <tr>
                                  <th><span  class="fw-medium">Owner Name</span></th>
                                  <td>David Marshall</td>
                              </tr>
                              <tr>
                                  <th><span  class="fw-medium">Company Type</span></th>
                                  <td>Partnership</td>
                              </tr>
                              <tr>
                                  <th><span  class="fw-medium">Email</span></th>
                                  <td>forcemedicines@gamil.com</td>
                              </tr>
                              <tr>
                                  <th><span  class="fw-medium">Website</span></th>
                                  <td><a href="javascript:void(0);" class="link-primary">www.forcemedicines.com</a></td>
                              </tr>
                              <tr>
                                  <th><span  class="fw-medium">Contact No.</span></th>
                                  <td>+(123) 9876 654 321</td>
                              </tr>
                              <tr>
                                  <th><span  class="fw-medium">Fax</span></th>
                                  <td>+1 999 876 5432</td>
                              </tr>
                              <tr>
                                  <th><span  class="fw-medium">Location</span></th>
                                  <td>United Kingdom</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div><!--end card-body-->
          <div class="card-body border-top border-top-dashed p-4">
              <div>
                  <h6 class="text-muted text-uppercase fw-semibold mb-4">Customer Reviews</h6>
                  <div>
                      <div>
                          <div class="bg-light px-3 py-2 rounded-2 mb-2">
                              <div class="d-flex align-items-center">
                                  <div class="flex-grow-1">
                                      <div class="fs-16 align-middle text-warning">
                                          <i class="ri-star-fill"></i>
                                          <i class="ri-star-fill"></i>
                                          <i class="ri-star-fill"></i>
                                          <i class="ri-star-fill"></i>
                                          <i class="ri-star-half-fill"></i>
                                      </div>
                                  </div>
                                  <div class="flex-shrink-0">
                                      <h6 class="mb-0">4.5 out of 5</h6>
                                  </div>
                              </div>
                          </div>
                          <div class="text-center">
                              <div class="text-muted">Total <span class="fw-medium">5.50k</span> reviews</div>
                          </div>
                      </div>

                      <div class="mt-3">
                          <div class="row align-items-center g-2">
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0">5 star</h6>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="p-1">
                                      <div class="progress animated-progess progress-sm">
                                          <div class="progress-bar bg-success" role="progressbar" style="width: 50.16%" aria-valuenow="50.16" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0 text-muted">2758</h6>
                                  </div>
                              </div>
                          </div>
                          <!-- end row -->

                          <div class="row align-items-center g-2">
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0">4 star</h6>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="p-1">
                                      <div class="progress animated-progess progress-sm">
                                          <div class="progress-bar bg-success" role="progressbar" style="width: 29.32%" aria-valuenow="29.32" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0 text-muted">1063</h6>
                                  </div>
                              </div>
                          </div>
                          <!-- end row -->

                          <div class="row align-items-center g-2">
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0">3 star</h6>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="p-1">
                                      <div class="progress animated-progess progress-sm">
                                          <div class="progress-bar bg-warning" role="progressbar" style="width: 18.12%" aria-valuenow="18.12" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0 text-muted">997</h6>
                                  </div>
                              </div>
                          </div>
                          <!-- end row -->

                          <div class="row align-items-center g-2">
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0">2 star</h6>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="p-1">
                                      <div class="progress animated-progess progress-sm">
                                          <div class="progress-bar bg-success" role="progressbar" style="width: 4.98%" aria-valuenow="4.98" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0 text-muted">227</h6>
                                  </div>
                              </div>
                          </div>
                          <!-- end row -->

                          <div class="row align-items-center g-2">
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0">1 star</h6>
                                  </div>
                              </div>
                              <div class="col">
                                  <div class="p-1">
                                      <div class="progress animated-progess progress-sm">
                                          <div class="progress-bar bg-danger" role="progressbar" style="width: 7.42%" aria-valuenow="7.42" aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-auto">
                                  <div class="p-1">
                                      <h6 class="mb-0 text-muted">408</h6>
                                  </div>
                              </div>
                          </div><!-- end row -->
                      </div>
                  </div>
              </div>
          </div><!--end card-body-->
          <div class="card-body p-4 border-top border-top-dashed">
              <h6 class="text-muted text-uppercase fw-semibold mb-4">Products Reviews</h6>
              <!-- Swiper -->
              <ngx-simplebar class="swiper vertical-swiper" style="max-height: 242px;">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="card border border-dashed shadow-none">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 avatar-sm">
                                        <div class="avatar-title bg-light rounded">
                                            <img src="assets/images/companies/img-1.png" alt="" height="30">
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <div>
                                            <p class="text-muted mb-1 fst-italic">" Great product and looks great, lots of features. "</p>
                                            <div class="fs-11 align-middle text-warning">
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                            </div>
                                        </div>
                                        <div class="text-end mb-0 text-muted">
                                            - by <cite title="Source Title">Force Medicines</cite>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="card border border-dashed shadow-none">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-shrink-0">
                                        <img src="assets/images/users/avatar-3.jpg" alt="" class="avatar-sm rounded">
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <div>
                                            <p class="text-muted mb-1 fst-italic">" Amazing template, very easy to understand and manipulate. "</p>
                                            <div class="fs-11 align-middle text-warning">
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-half-fill"></i>
                                            </div>
                                        </div>
                                        <div class="text-end mb-0 text-muted">
                                            - by <cite title="Source Title">Henry Baird</cite>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="card border border-dashed shadow-none">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 avatar-sm">
                                        <div class="avatar-title bg-light rounded">
                                            <img src="assets/images/companies/img-8.png" alt="" height="30">
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <div>
                                            <p class="text-muted mb-1 fst-italic">"Very beautiful product and Very helpful customer service."</p>
                                            <div class="fs-11 align-middle text-warning">
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-line"></i>
                                                <i class="ri-star-line"></i>
                                            </div>
                                        </div>
                                        <div class="text-end mb-0 text-muted">
                                            - by <cite title="Source Title">Zoetic Fashion</cite>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="card border border-dashed shadow-none">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-shrink-0">
                                        <img src="assets/images/users/avatar-2.jpg" alt="" class="avatar-sm rounded">
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <div>
                                            <p class="text-muted mb-1 fst-italic">" The product is very beautiful. I like it. "</p>
                                            <div class="fs-11 align-middle text-warning">
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-half-fill"></i>
                                                <i class="ri-star-line"></i>
                                            </div>
                                        </div>
                                        <div class="text-end mb-0 text-muted">
                                            - by <cite title="Source Title">Nancy Martino</cite>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </ngx-simplebar>
              <div class="text-center mt-3">
                  <a href="javascript:void(0)" class="link-primary">View All Reviews <i class="ri-arrow-right-line align-bottom ms-1"></i></a>
              </div>
          </div>
          <div class="card-body p-4 border-top border-top-dashed">
              <h6 class="text-muted text-uppercase fw-semibold mb-4">Contact Support</h6>
              <form action="#">
                  <div class="mb-3">
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Enter your messages..."></textarea>
                  </div>
                  <div class="text-end">
                      <button type="submit" class="btn btn-primary"><i class="ri-mail-send-line align-bottom me-1"></i> Send Messages</button>
                  </div>
              </form>
          </div><!--end card-body-->
      </div><!--end card-->
  </div><!--end col-->

  <div class="col-xxl-9">
      <div class="card">
          <div class="card-header border-0 align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Revenue</h4>
              <div class="d-flex gap-1">
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                      ALL
                  </button>
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                      1M
                  </button>
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                      6M
                  </button>
                  <button type="button" class="btn btn-soft-primary btn-sm">
                      1Y
                  </button>
              </div>
          </div><!-- end card header -->

          <div class="card-header p-0 border-0 bg-soft-light">
              <div class="row g-0 text-center">
                  <div class="col-6 col-sm-3">
                      <div class="p-3 border border-dashed border-start-0">
                          <h5 class="mb-1"><span [CountTo]="7585" class="counter-value" [from]="0" [duration]="1"></span></h5>
                          <p class="text-muted mb-0">Orders</p>
                      </div>
                  </div><!--end col-->
                  <div class="col-6 col-sm-3">
                      <div class="p-3 border border-dashed border-start-0">
                          <h5 class="mb-1">$<span [CountTo]="22.89" class="counter-value" [from]="0" [duration]="1"></span>k</h5>
                          <p class="text-muted mb-0">Earnings</p>
                      </div>
                  </div><!--end col-->
                  <div class="col-6 col-sm-3">
                      <div class="p-3 border border-dashed border-start-0">
                          <h5 class="mb-1"><span [CountTo]="367" class="counter-value" [from]="0" [duration]="1"></span></h5>
                          <p class="text-muted mb-0">Refunds</p>
                      </div>
                  </div><!--end col-->
                  <div class="col-6 col-sm-3">
                      <div class="p-3 border border-dashed border-start-0 border-end-0">
                          <h5 class="mb-1 text-success"><span [CountTo]="18.92" class="counter-value" [from]="0" [duration]="1"></span>%</h5>
                          <p class="text-muted mb-0">Conversation Ratio</p>
                      </div>
                  </div><!--end col-->
              </div>
          </div><!-- end card header -->

          <div class="card-body p-0 pb-2">
              <div>
                <apx-chart dir="ltr" class="apex-charts" [chart]="analyticsChart.chart"
                [series]="analyticsChart.series" [legend]="analyticsChart.legend"
                [stroke]="analyticsChart.stroke" [colors]="analyticsChart.colors" [fill]="analyticsChart.fill"
                [dataLabels]="analyticsChart.dataLabels" [xaxis]="analyticsChart.xaxis"
                [markers]="analyticsChart.markers" [plotOptions]="analyticsChart.plotOptions"
                [yaxis]="analyticsChart.yaxis" [tooltip]="analyticsChart.tooltip" [grid]="analyticsChart.grid"
                [labels]="analyticsChart.labels">
            </apx-chart>
              </div>
          </div><!-- end card body -->
      </div><!-- end card -->

      <div class="row g-4 mb-3">
          <div class="col-sm-auto">
              <div>
                  <a routerLink="/ecommerce/add-product" class="btn btn-success"><i class="ri-add-line align-bottom me-1"></i> Add New</a>
              </div>
          </div>
          <div class="col-sm">
              <div class="d-flex justify-content-sm-end">
                  <div class="search-box ms-2">
                      <input type="text"  name="searchTerm" class="form-control" placeholder="Search Products..." [(ngModel)]="service.searchTerm">
                      <i class="ri-search-line search-icon"></i>
                  </div>
              </div>
          </div>
      </div>
      <div class="card">
          <div class="card-body">
			<div class="table-card">
                <div class="table-responsive ">
                    <table class="table align-middle">
                    <thead>
                    <tr class="bg-light text-muted">
                        <th scope="col">#</th>
                        <th scope="col">Product</th>
                        <th scope="col">Stock</th>
                        <th scope="col">Price</th>
                        <th scope="col">Orders</th>
                        <th scope="col">Rating</th>
                        <th scope="col">Published</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of sellerDetailsList$ | async">
                        <td><input type="checkbox" class="gridjs-checkbox"></td>
                        <td>
                        <span>
                            <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm bg-light rounded p-1">
                                <img src="{{data.image}}" alt="" class="img-fluid d-block">
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-14 mb-1">
                                <a routerLink="/ecommerce/product-detail/1" class="text-dark">{{data.name}}</a>
                                </h5>
                                <p class="text-muted mb-0">Category :
                                <span class="fw-medium">{{data.cat}}</span>
                                </p>
                            </div>
                            </div>
                        </span>
                        </td>
                        <td><ngb-highlight [result]="data.stock" [term]="service.searchTerm"></ngb-highlight></td>
                        <td>$ <ngb-highlight [result]="data.price" [term]="service.searchTerm"></ngb-highlight></td>
                        <td><ngb-highlight [result]="data.order" [term]="service.searchTerm"></ngb-highlight></td>
                        <td><span class="badge bg-light text-body fs-12 fw-medium"><i class="mdi mdi-star text-warning me-1"></i>{{data.rating}}</span></td>
                        <td><span>{{data.date}}<small class="text-muted ms-1">{{data.time}}</small></span></td>
                        <td>
                        <span>
                            <div class="dropdown" ngbDropdown>
                            <button class="btn btn-soft-secondary btn-sm dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                <i class="ri-more-fill"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                <li>
                                <a class="dropdown-item" routerLink="/ecommerce/product-detail/1"><i class="ri-eye-fill align-bottom me-2 text-muted"></i> View </a>
                                </li>
                                <li>
                                <a class="dropdown-item" routerLink="/ecommerce/add-product"><i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit</a>
                                </li>
                                <li class="dropdown-divider"></li>
                                <li>
                                <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#removeItemModal"><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete</a>
                                </li>
                            </ul>
                            </div>
                        </span>
                        </td>
                    </tr>
                    </tbody>
                    </table>
                </div>
			<div class="px-3">
            <div class="row justify-content-md-between align-items-md-center">
              <div class="col col-sm-6">
                  <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                      Showing
                      {{service.startIndex}} to
                      {{service.endIndex}} of {{service.totalRecords}}
                      entries
                  </div>
              </div>
              <!-- Pagination -->
              <div class="col col-sm-6">
                  <div class="text-sm-right float-sm-end pagination-rounded gridjs-pagination pb-2">
                    <ngb-pagination
                    [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                  </ngb-pagination>
                  </div>
              </div>
              <!-- End Pagination -->
				</div>
                </div>
            </div>
            
          </div>
      </div>

  </div><!--end col-->
</div><!--end row-->
