<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Products" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
  <div class="col-xl-3 col-lg-4">
      <div class="card">
          <div class="card-header">
              <div class="d-flex mb-3">
                  <div class="flex-grow-1">
                      <h5 class="fs-16">Filters</h5>
                  </div>
                  <div class="flex-shrink-0">
                      <a href="javascript:void(0);" class="text-decoration-underline">Clear All</a>
                  </div>
              </div>

              <div class="filter-choices-input">
                <ng-select [items]="Default" [multiple]="true" bindLabel="name"
                  [(ngModel)]="multiDefaultOption">
                  <ng-template ng-optgroup-tmp let-item="item">
                      {{item.country || 'Unnamed group'}}
                  </ng-template>
                </ng-select>
              </div>
          </div>

          <div class="accordion accordion-flush">

              <div class="card-body border-bottom">
                  <div>
                      <p class="text-muted text-uppercase fs-12 fw-medium mb-2">Products</p>
                      <ngb-accordion #acc="ngbAccordion" activeIds="static-2" class="products-filter vstack gap-2" [closeOthers]="true">
                        <ngb-panel id="static-1">
                            <ng-template ngbPanelTitle class="accordion-header">
                              <span class="d-flex">
                                <span class="flex-grow-1 text-start">
                                  <h5 class="fs-13 mb-0">Grocery</h5>
                                </span>
                              </span>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-2">
                            <ng-template ngbPanelTitle class="accordion-header">
                              <span class="d-flex">
                                <span class="flex-grow-1 text-start">
                                  <h5 class="fs-13 mb-0">Fashion</h5>
                                </span>
                                <span class="flex-shrink-0 ms-2">
                                    <span class="badge bg-light text-muted">5</span>
                                </span>
                              </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="collapse show" id="filterlist-fashion">
                                <ul class="ps-4">
                                    <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Men's Wear</a>
                                    </li>
                                    <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Women Western
                                            Wear</a></li>
                                    <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Footwear</a>
                                    </li>
                                    <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Watches</a></li>
                                    <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Kids</a></li>
                                </ul>
                              </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3">
                            <ng-template ngbPanelTitle class="accordion-header">
                              <span class="d-flex">
                                <span class="flex-grow-1 text-start">
                                  <h5 class="fs-13 mb-0">Mobiles</h5>
                                </span>
                              </span>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-4">
                          <ng-template ngbPanelTitle class="accordion-header">
                            <span class="d-flex">
                              <span class="flex-grow-1 text-start">
                                <h5 class="fs-13 mb-0">Electronics</h5>
                              </span>
                              <span class="flex-shrink-0 ms-2">
                                  <span class="badge bg-light text-muted">5</span>
                              </span>
                            </span>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="" id="filterlist-electronics">
                              <ul class="ps-4">
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Audio</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Computer</a>
                                  </li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Laptop</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Gaming</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Tablets</a></li>
                              </ul>
                            </div>
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-5">
                          <ng-template ngbPanelTitle class="accordion-header">
                            <span class="d-flex">
                              <span class="flex-grow-1 text-start">
                                <h5 class="fs-13 mb-0">Home & Furniture</h5>
                              </span>
                              <span class="flex-shrink-0 ms-2">
                                  <span class="badge bg-light text-muted">6</span>
                              </span>
                            </span>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="" id="filterlist-furniture">
                              <ul class="ps-4">
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Home
                                          Furnishing</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Living rooms</a>
                                  </li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Kitchen &
                                          Dining</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Bedroom Room</a>
                                  </li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Home Decor</a>
                                  </li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Tools &
                                          Utility</a></li>
                              </ul>
                            </div>
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-6">
                          <ng-template ngbPanelTitle class="accordion-header">
                            <span class="d-flex">
                              <span class="flex-grow-1 text-start">
                                <h5 class="fs-13 mb-0">Appliances</h5>
                              </span>
                              <span class="flex-shrink-0 ms-2">
                                  <span class="badge bg-light text-muted">7</span>
                              </span>
                            </span>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="" id="filterlist-appliances">
                              <ul class="ps-4">
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Televisions</a>
                                  </li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Washing
                                          Machines</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Air
                                          Conditioners</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Refrigerator</a>
                                  </li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Kitchen
                                          Appliances</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Home
                                          Appliances</a></li>
                                  <li><a href="javascript:void(0);" class="d-block py-1 text-muted">Premium
                                          Appliances</a></li>
                              </ul>
                            </div>
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-7">
                          <ng-template ngbPanelTitle class="accordion-header">
                            <span class="d-flex">
                              <span class="flex-grow-1 text-start">
                                <h5 class="fs-13 mb-0">Kids</h5>
                              </span>
                            </span>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>
                  </div>
              </div>

              <div class="card-body border-bottom">
                  <p class="text-muted text-uppercase fs-12 fw-medium mb-4">Price</p>
                  <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options"></ngx-slider>
              </div>

              <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
                <ngb-panel id="static-1">
                    <ng-template ngbPanelTitle>
                        <h2 class="accordion-header" id="gen-ques-headingOne">
                            <button class="accordion-button bg-transparent shadow-none collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#gen-ques-collapseOne" aria-expanded="false"
                                aria-controls="gen-ques-collapseOne">
                                <span class="text-muted text-uppercase fs-12 fw-medium">Brands</span> <span class="badge bg-success rounded-pill align-middle ms-1">2</span>
                            </button>
                        </h2>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="accordion-body text-body pt-0">
                        <div class="search-box search-box-sm">
                            <input type="text" class="form-control bg-light border-0" placeholder="Search Brands...">
                            <i class="ri-search-line search-icon"></i>
                        </div>
                        <div class="d-flex flex-column gap-2 mt-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value=""
                                    id="productBrandRadio5" checked>
                                <label class="form-check-label"
                                    for="productBrandRadio5">Boat</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value=""
                                    id="productBrandRadio4">
                                <label class="form-check-label"
                                    for="productBrandRadio4">OnePlus</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value=""
                                    id="productBrandRadio3">
                                <label class="form-check-label"
                                    for="productBrandRadio3">Realme</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value=""
                                    id="productBrandRadio2">
                                <label class="form-check-label"
                                    for="productBrandRadio2">Sony</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value=""
                                    id="productBrandRadio1" checked>
                                <label class="form-check-label" for="productBrandRadio1">JBL</label>
                            </div>

                            <div>
                                <button type="button"
                                    class="btn btn-link text-decoration-none text-uppercase fw-medium p-0">1,235
                                    More</button>
                            </div>
                        </div>
                    </div>
                    </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <!-- end accordion-item -->

              <ngb-accordion #acc="ngbAccordion" activeIds="static-2">
                <ngb-panel id="static-2">
                    <ng-template ngbPanelTitle>
                      <h2 class="accordion-header" id="flush-headingDiscount">
                        <button class="accordion-button bg-transparent shadow-none collapsed" type="button"
                            data-bs-toggle="collapse" data-bs-target="#flush-collapseDiscount"
                            aria-expanded="true" aria-controls="flush-collapseDiscount">
                            <span class="text-muted text-uppercase fs-12 fw-medium">Discount</span> <span
                                class="badge bg-success rounded-pill align-middle ms-1">1</span>
                        </button>
                      </h2>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="d-flex flex-column gap-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productdiscountRadio6">
                            <label class="form-check-label" for="productdiscountRadio6">
                                50% or more
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productdiscountRadio5">
                            <label class="form-check-label" for="productdiscountRadio5">
                                40% or more
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productdiscountRadio4">
                            <label class="form-check-label" for="productdiscountRadio4">
                                30% or more
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productdiscountRadio3" checked>
                            <label class="form-check-label" for="productdiscountRadio3">
                                20% or more
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productdiscountRadio2">
                            <label class="form-check-label" for="productdiscountRadio2">
                                10% or more
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productdiscountRadio1">
                            <label class="form-check-label" for="productdiscountRadio1">
                                Less than 10%
                            </label>
                        </div>
                      </div>
                    </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <!-- end accordion-item -->

              <ngb-accordion #acc="ngbAccordion" activeIds="static-3">
                <ngb-panel id="static-3">
                    <ng-template ngbPanelTitle>
                      <h2 class="accordion-header" id="flush-headingRating">
                        <button class="accordion-button bg-transparent shadow-none collapsed"
                            type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseRating" aria-expanded="false"
                            aria-controls="flush-collapseRating">
                            <span class="text-muted text-uppercase fs-12 fw-medium">Rating</span> <span
                                class="badge bg-success rounded-pill align-middle ms-1">1</span>
                        </button>
                      </h2>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="d-flex flex-column gap-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productratingRadio4" checked>
                            <label class="form-check-label" for="productratingRadio4">
                                <span class="text-muted">
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star"></i>
                                </span> 4 & Above
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productratingRadio3">
                            <label class="form-check-label" for="productratingRadio3">
                                <span class="text-muted">
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star"></i>
                                    <i class="mdi mdi-star"></i>
                                </span> 3 & Above
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productratingRadio2">
                            <label class="form-check-label" for="productratingRadio2">
                                <span class="text-muted">
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star"></i>
                                    <i class="mdi mdi-star"></i>
                                    <i class="mdi mdi-star"></i>
                                </span> 2 & Above
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value=""
                                id="productratingRadio1">
                            <label class="form-check-label" for="productratingRadio1">
                                <span class="text-muted">
                                    <i class="mdi mdi-star text-warning"></i>
                                    <i class="mdi mdi-star"></i>
                                    <i class="mdi mdi-star"></i>
                                    <i class="mdi mdi-star"></i>
                                    <i class="mdi mdi-star"></i>
                                </span> 1
                            </label>
                        </div>
                      </div>
                    </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <!-- end accordion-item -->
          </div>
      </div>
      <!-- end card -->
  </div>
  <!-- end col -->

  <div class="col-xl-9 col-lg-8">
      <div>
          <div class="card">
              <div class="card-header border-0">
                  <div class="row g-4">
                      <div class="col-sm-auto">
                          <div>
                              <a routerLink="/ecommerce/add-product" class="btn btn-success"><i
                                      class="ri-add-line align-bottom me-1"></i> Add Product</a>
                          </div>
                      </div>
                      <div class="col-sm">
                          <div class="d-flex justify-content-sm-end">
                              <div class="search-box ms-2">
                                  <input type="text"  name="searchTerm" class="form-control" placeholder="Search Products..." [(ngModel)]="service.searchTerm">
                                  <i class="ri-search-line search-icon"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Nav tabs -->
                      <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs-custom card-header-tabs border-bottom-0">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                              All <span class="badge badge-soft-danger align-middle rounded-pill ms-1">12</span>
                            </a>
                            <ng-template ngbNavContent>
								              <div class="mt-3">
                                <div class="table-card gridjs-border-none">
                                  <div class="table-responsive">
                                    <table class="table align-middle">
                                      <thead>
                                      <tr class="bg-light text-muted">
                                        <th scope="col">#</th>
                                        <th scope="col" sortable="image" (sort)="onSort($event)">Product</th>
                                        <th scope="col" sortable="stock" (sort)="onSort($event)">Stock</th>
                                        <th scope="col" sortable="price" (sort)="onSort($event)">Price</th>
                                        <th scope="col" sortable="order" (sort)="onSort($event)">Orders</th>
                                        <th scope="col" sortable="rating" (sort)="onSort($event)">Rating</th>
                                        <th scope="col" sortable="date" (sort)="onSort($event)">Published</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr *ngFor="let data of invoiceList$ | async">
                                        <td><input type="checkbox" class="gridjs-checkbox"></td>
                                        <td>
                                          <span>
                                            <div class="d-flex align-items-center">
                                              <div class="flex-shrink-0 me-3">
                                                <div class="avatar-sm bg-light rounded p-1">
                                                  <img src="{{data.image}}" alt="" class="img-fluid d-block">
                                                </div>
                                              </div>
                                              <div class="flex-grow-1">
                                                <h5 class="fs-14 mb-1">
                                                  <a routerLink="/ecommerce/product-detail/1" class="text-dark">{{data.name}}</a>
                                                </h5>
                                                <p class="text-muted mb-0">Category :
                                                  <span class="fw-medium">{{data.cat}}</span>
                                                </p>
                                              </div>
                                            </div>
                                          </span>
                                        </td>
                                        <td><ngb-highlight [result]="data.stock" [term]="service.searchTerm"></ngb-highlight></td>
                                        <td>$ <ngb-highlight [result]="data.price" [term]="service.searchTerm"></ngb-highlight></td>
                                        <td><ngb-highlight [result]="data.order" [term]="service.searchTerm"></ngb-highlight></td>
                                        <td><span class="badge bg-light text-body fs-12 fw-medium"><i class="mdi mdi-star text-warning me-1"></i>{{data.rating}}</span></td>
                                        <td><span>{{data.date}}<small class="text-muted ms-1">{{data.time}}</small></span></td>
                                        <td>
                                          <span>
                                            <div class="dropdown" ngbDropdown>
                                              <button class="btn btn-soft-secondary btn-sm dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                <i class="ri-more-fill"></i>
                                              </button>
                                              <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                <li>
                                                  <a class="dropdown-item" routerLink="/ecommerce/product-detail/1"><i class="ri-eye-fill align-bottom me-2 text-muted"></i> View </a>
                                                </li>
                                                <li>
                                                  <a class="dropdown-item" routerLink="/ecommerce/add-product"><i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit</a>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li>
                                                  <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#removeItemModal" (click)="confirm()"><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </span>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div class="px-3">
                                    <div class="row justify-content-md-between align-items-md-center g-0">
                                      <div class="col-sm-12 col-md-5">
                                          <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                              Showing
                                              {{service.startIndex}} to
                                              {{service.endIndex}} of {{service.totalRecords}}
                                              entries
                                          </div>
                                      </div>
                                      <!-- Pagination -->
                                      <div class="col-sm-12 col-md-5">
                                          <div class="text-md-right float-md-end pagination-rounded gridjs-pagination mb-3">
                                            <ngb-pagination
                                            [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                          </ngb-pagination>
                                          </div>
                                      </div>
                                      <!-- End Pagination -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                              Published <span class="badge badge-soft-danger align-middle rounded-pill ms-1">5</span>
                            </a>
                            <ng-template ngbNavContent>
							<div class="mt-3">
                                <div class="table-card gridjs-border-none">
                              <div class="table-responsive">
                                <table class="table align-middle">
                                  <thead>
                                  <tr class="bg-light text-muted"> 
                                    <th scope="col" sortable="image" (sort)="onSort($event)">Product</th>
                                    <th scope="col" sortable="stock" (sort)="onSort($event)">Stock</th>
                                    <th scope="col" sortable="price" (sort)="onSort($event)">Price</th>
                                    <th scope="col" sortable="order" (sort)="onSort($event)">Orders</th>
                                    <th scope="col" sortable="rating" (sort)="onSort($event)">Rating</th>
                                    <th scope="col" sortable="date" (sort)="onSort($event)">Published</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>

                                  <tr *ngFor="let data of invoiceList$ | async">
                                    <ng-template [ngIf]="data.type == 'Published'">
                                    <td>
                                      <span>
                                        <div class="d-flex align-items-center">
                                          <div class="flex-shrink-0 me-3">
                                            <div class="avatar-sm bg-light rounded p-1">
                                              <img src="{{data.image}}" alt="" class="img-fluid d-block">
                                            </div>
                                          </div>
                                          <div class="flex-grow-1">
                                            <h5 class="fs-14 mb-1">
                                              <a routerLink="/ecommerce/product-detail/1" class="text-dark">{{data.name}}</a>
                                            </h5>
                                            <p class="text-muted mb-0">Category :
                                              <span class="fw-medium">{{data.cat}}</span>
                                            </p>
                                          </div>
                                        </div>
                                      </span>
                                    </td>
                                    <td><ngb-highlight [result]="data.stock" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td>$ <ngb-highlight [result]="data.price" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td><ngb-highlight [result]="data.order" [term]="service.searchTerm"></ngb-highlight></td>
                                    <td><span class="badge bg-light text-body fs-12 fw-medium"><i class="mdi mdi-star text-warning me-1"></i>{{data.rating}}</span></td>
                                    <td><span>{{data.date}}<small class="text-muted ms-1">{{data.time}}</small></span></td>
                                    <td>
                                      <span>
                                        <div class="dropdown" ngbDropdown>
                                          <button class="btn btn-soft-secondary btn-sm dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                            <i class="ri-more-fill"></i>
                                          </button>
                                          <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                            <li>
                                              <a class="dropdown-item" routerLink="/ecommerce/product-detail/1"><i class="ri-eye-fill align-bottom me-2 text-muted"></i> View </a>
                                            </li>
                                            <li>
                                              <a class="dropdown-item" routerLink="/ecommerce/add-product"><i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit</a>
                                            </li>
                                            <li class="dropdown-divider"></li>
                                            <li>
                                              <a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#removeItemModal" (click)="confirm()"><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete</a>
                                            </li>
                                          </ul>
                                        </div>
                                      </span>
                                    </td>
                                  </ng-template>
                                  </tr>
                                  </tbody>
                                </table>
                              </div>
							<div class="px-3">
                              <div class="row justify-content-md-between align-items-md-center g-0">
                                <div class="col-sm-12 col-md-6">
                                    <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                        Showing
                                        {{service.startIndex}} to
                                        {{service.endIndex}} of {{service.totalRecords}}
                                        entries
                                    </div>
                                </div>
                                <!-- Pagination -->
                                <div class="col-sm-12 col-md-6">
                                    <div class="text-md-right float-md-end pagination-rounded gridjs-pagination">
                                      <ngb-pagination
                                      [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                                    </ngb-pagination>
                                    </div>
                                </div>
                                <!-- End Pagination -->
								</div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink >
                              Draft
                            </a>
                            <ng-template ngbNavContent>
                              <div class="tab-pane" id="productnav-draft" role="tabpanel">
                                <div class="py-4 text-center">
                                  <div>
                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json"
                                        trigger="loop" colors="primary:#0d68c3,secondary:#0ab39c"
                                        style="width:72px;height:72px">
                                    </lord-icon>
                                  </div>
                                    <div class="mt-4">
                                        <h5>Sorry! No Result Found</h5>
                                    </div>
                                </div>
                            </div>
                            </ng-template>
                        </li>
                      </ul>

                      <!-- Tab panes -->
                      <div class="tab-content text-muted">
                        <div [ngbNavOutlet]="nav"></div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <!-- end card -->
      </div>
  </div>
  <!-- end col -->
</div>
<!-- end row -->
