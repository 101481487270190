import { Component, OnInit, EventEmitter, Output, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EventService } from '../../core/services/event.service';

//Logout
import { environment } from '../../../environments/environment';
import { getFirebaseBackend } from '../../../app/authUtils';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { Router } from '@angular/router';

// Language
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtService } from 'src/app/core/services/jwt.service';
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service';
import Swal from 'sweetalert2';
import { NewsService } from 'src/app/core/services/news/news.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit {

    element: any;
    mode: string | undefined;
    @Output() mobileMenuButtonClicked = new EventEmitter();

    flagvalue: any;
    valueset: any;
    countryName: any;
    cookieValue: any;
    userName: string = "";
    user: any;

    public notifications: any[];

    constructor(@Inject(DOCUMENT) private document: any, public newsSrv: NewsService, private jwtService: JwtService, private eventService: EventService, public languageService: LanguageService,
        public _cookiesService: CookieService, private notificationService: NotificationsService, public translate: TranslateService, private authService: AuthenticationService, private authFackservice: AuthfakeauthenticationService, private router: Router) {
        this.user = getFirebaseBackend()?.getAuthenticatedUser();
        this.userName = this.user.FirstName + " " + this.user.LastName;

        this.notifications = [];
    }

    ngOnInit(): void {
        this.element = document.documentElement;

        // Cookies wise Language set
        this.cookieValue = this._cookiesService.get('lang');
        const val = this.listLang.filter(x => x.lang === this.cookieValue);
        this.countryName = val.map(element => element.text);
        if (val.length === 0) {
            if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.svg'; }
        } else {
            this.flagvalue = val.map(element => element.flag);
        }

        //data user



    }

    ngAfterViewInit() {
        this.getNotifications();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Fullscreen method
     */
    fullscreen() {
        document.body.classList.toggle('fullscreen-enable');
        if (
            !document.fullscreenElement && !this.element.mozFullScreenElement &&
            !this.element.webkitFullscreenElement) {
            if (this.element.requestFullscreen) {
                this.element.requestFullscreen();
            } else if (this.element.mozRequestFullScreen) {
                /* Firefox */
                this.element.mozRequestFullScreen();
            } else if (this.element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.element.webkitRequestFullscreen();
            } else if (this.element.msRequestFullscreen) {
                /* IE/Edge */
                this.element.msRequestFullscreen();
            }
        } else {
            if (this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    /**
    * Topbar Light-Dark Mode Change
    */
    changeMode(mode: string) {
        this.mode = mode;
        this.eventService.broadcast('changeMode', mode);

        switch (mode) {
            case 'light':
                document.body.setAttribute('data-layout-mode', "light");
                document.body.setAttribute('data-sidebar', "light");
                break;
            case 'dark':
                document.body.setAttribute('data-layout-mode', "dark");
                document.body.setAttribute('data-sidebar', "dark");
                break;
            default:
                document.body.setAttribute('data-layout-mode', "light");
                break;
        }
    }

    /***
     * Language Listing
     * { text: 'Deutsche', flag: 'assets/images/flags/germany.svg', lang: 'de' },
        { text: 'Italiana', flag: 'assets/images/flags/italy.svg', lang: 'it' },
        { text: 'русский', flag: 'assets/images/flags/russia.svg', lang: 'ru' },
     */
    listLang = [
        { text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en' },
        { text: 'Española', flag: 'assets/images/flags/spain.svg', lang: 'es' },        
        { text: '中国人', flag: 'assets/images/flags/china.svg', lang: 'ch' },
        { text: 'français', flag: 'assets/images/flags/french.svg', lang: 'fr' },
    ];

    /***
     * Language Value Set
     */
    setLanguage(text: string, lang: string, flag: string) {
        this.countryName = text;
        this.flagvalue = flag;
        this.cookieValue = lang;
        this.languageService.setLanguage(lang);
    }

    /**
     * Logout the user
     */
    logout() {

        var chat = document.getElementById("hubspot-messages-iframe-container");
        if (chat != null) {
            chat.remove();
        }

        if (environment.defaultauth === 'firebase') {
            this.authService.logout();
        } else {
            this.authFackservice.logout();
        }
        this.router.navigate(['/auth/signin/cover']);
    }

    testJWT() {
        this.jwtService.testJWT().subscribe(data => {

        })
    }

    getNotifications() {
        const user = getFirebaseBackend()?.getAuthenticatedUser();
        this.notificationService.getUserNotifications(this.user?.IdUser).subscribe((data:any) => {
            this.notifications = data?.body;
        })
    }

    changeUser(email: string){
        email = email.trim();
        this.authService.getLmsUserData(email).subscribe((data: any)=>{
            if(data){
                sessionStorage.setItem('customerUser', JSON.stringify(data.body));
                window.location.reload();
            } else {
                Swal.fire({
                    title:'Error',
                    icon:'error',
                    text: 'The user you are looking for doest exist on the database, please review the email'
                })
            }
            
        });
        
    }

    showNews(){
        this.newsSrv.showNews(this.user.IdUser);
    }

}
