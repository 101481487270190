import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-danger-msg',
  templateUrl: './danger-msg.component.html',
  styleUrls: ['./danger-msg.component.scss']
})
export class DangerMsgComponent implements OnInit {
  @Input() msg:string='';
  message:string="";
  constructor() { }

  ngOnInit(): void {
    this.message=this.msg;
  }

}
