import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { data } from 'src/app/pages/charts/Apexcharts/area/area.component';
import { environment } from 'src/environments/environment';
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../models/auth.models';
import * as dayjs from 'dayjs';

const httpOptions={
    headers: new HttpHeaders({'Content-Type': 'multipart/form-data'})    
}
@Injectable({ providedIn: 'root' })

/**headers: new HttpHeaders({'Content-Type': 'application/json',}),
 * Auth-service Component
 */
export class AuthenticationService {

    user!: User;
    currentUserValue: any;
    url: string="";
    constructor(private http: HttpClient) { 
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend()!.registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        }).catch(response=>{
            console.error("Error:", response);
        })
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend()!.loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Returns the current user
     */
    public currentUser(): any {
        return getFirebaseBackend()!.getAuthenticatedUser();
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        return getFirebaseBackend()!.logout();
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        // getFirebaseBackend()!.forgetPassword(email);
        return getFirebaseBackend()!.forgetPassword(email)
        .then(
            (response: any) => {
            const message = response.data;
            return message;},
            (rejectionReason) => {alert(rejectionReason) })
        .catch((e) => {console.info('respuesta error: reset password:' + e); });
    }

    verifyPassword(code:string){
        return getFirebaseBackend()!.verifyCodePassword(code);
    }

    confirmPassword(code:string, password:string){
        return getFirebaseBackend()!.confirmPassword(code, password);
    }

    refreshToken(){
        return getFirebaseBackend()?.refreshToken();
    }

    getLmsUserData(email: string){
        this.url = `${environment.api}user/get_lms_data/${email}`;
        return this.http.get(this.url);
    }
    getIpPublicNetwork(){
        var ip="";
        
        this.http.get("https://jsonip.com").subscribe((response:any)=>{
            ip=response.ip;
        },(error)=>{
            ip="";
            console.info("Se tuvo un detalle en la obtencion de ip", error);
        });

        return ip;
    }

    saveLogin(userId:number,ip:string){
        console.info("datos a enviar de historia inicio", userId, ip);
        var formData: any = new FormData();
        formData.append('UserId', userId);
        formData.append('Ip', ip);
        formData.append('Date_user', dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'));

        console.info("datos a enviar de historia", dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'));
        this.url = `${environment.api}user/save_login`;
        var prueba= this.http.post(this.url, formData);
        return prueba;
    }

}

