<!-- start counter -->
<section class="py-5 position-relative bg-light">
    <div class="container">
        <div class="row text-center gy-4">
            <div class="col-lg-3 col-6">
                <div>
                    <h2 class="mb-2"><span [CountTo]="100" class="counter-value" [from]="0" [duration]="1"></span>+</h2>
                    <div class="text-muted">Projects Completed</div>
                </div>
            </div>
            <!-- end col -->

            <div class="col-lg-3 col-6">
                <div>
                    <h2 class="mb-2"><span [CountTo]="24" class="counter-value" [from]="0" [duration]="1"></span></h2>
                    <div class="text-muted">Win Awards</div>
                </div>
            </div>
            <!-- end col -->

            <div class="col-lg-3 col-6">
                <div>
                    <h2 class="mb-2"><span [CountTo]="20" class="counter-value" [from]="0" [duration]="1"></span>k</h2>
                    <div class="text-muted">Satisfied Clients</div>
                </div>
            </div>
            <!-- end col -->
            <div class="col-lg-3 col-6">
                <div>
                    <h2 class="mb-2"><span [CountTo]="50" class="counter-value" [from]="0" [duration]="1"></span></h2>
                    <div class="text-muted">Employees</div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</section>
<!-- end counter -->