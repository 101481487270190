<!-- Start Breadcrumbs -->
<app-breadcrumbs title="CRM" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->


<div class="row">
    <div class="col-xl-12">
        <div class="card crm-widget">
            <div class="card-body p-0">
                <div class="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                    <div *ngFor="let stat of statData">
                        <app-crm-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon" [profit]="stat.profit"></app-crm-stat>
                    </div>
                </div><!-- end row -->
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xxl-3 col-md-6">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Sales Forecast</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="fw-semibold text-uppercase fs-12">Sort by: </span><span class="text-muted">Nov 2021<i class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Oct 2021</a>
                            <a class="dropdown-item" href="javascript:void(0);">Nov 2021</a>
                            <a class="dropdown-item" href="javascript:void(0);">Dec 2021</a>
                            <a class="dropdown-item" href="javascript:void(0);">Jan 2022</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->
            <div class="card-body pb-0">
				<div class="mb-n3">
                <apx-chart [series]="salesForecastChart.series" [chart]="salesForecastChart.chart" [colors]="salesForecastChart.colors" [dataLabels]="salesForecastChart.dataLabels"
              [plotOptions]="salesForecastChart.plotOptions" [yaxis]="salesForecastChart.yaxis" [legend]="salesForecastChart.legend"
              [fill]="salesForecastChart.fill" [stroke]="salesForecastChart.stroke" [tooltip]="salesForecastChart.tooltip"
              [xaxis]="salesForecastChart.xaxis"></apx-chart>
			</div>
            </div>
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xxl-3 col-md-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Deal Type</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="fw-semibold text-uppercase fs-12">Sort by: </span><span class="text-muted">Monthly<i class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Today</a>
                            <a class="dropdown-item" href="javascript:void(0);">Weekly</a>
                            <a class="dropdown-item" href="javascript:void(0);">Monthly</a>
                            <a class="dropdown-item" href="javascript:void(0);">Yearly</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->
            <div class="card-body pb-0">
			<div class="mb-n3">
                <apx-chart [series]="DealTypeChart.series" [chart]="DealTypeChart.chart"
                        [stroke]="DealTypeChart.stroke" [fill]="DealTypeChart.fill"
                        [markers]="DealTypeChart.markers" [colors]="DealTypeChart.colors"
                        [xaxis]="DealTypeChart.xaxis"></apx-chart>
					</div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
    
    <div class="col-xxl-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Balance Overview</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="fw-semibold text-uppercase fs-12">Sort by: </span><span class="text-muted">Current Year<i class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Today</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Week</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                            <a class="dropdown-item" href="javascript:void(0);">Current Year</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->
            <div class="card-body px-0">
                <ul class="list-inline main-chart text-center mb-0">
                    <li class="list-inline-item chart-border-left me-0 border-0">
                        <h4 class="text-primary">$584k <span class="text-muted d-inline-block fs-13 align-middle ms-2">Revenue</span></h4>
                    </li>
                    <li class="list-inline-item chart-border-left me-0">
                        <h4>$497k<span class="text-muted d-inline-block fs-13 align-middle ms-2">Expenses</span>
                        </h4>
                    </li>
                    <li class="list-inline-item chart-border-left me-0">
                        <h4><span data-plugin="counterup">3.6</span>%<span class="text-muted d-inline-block fs-13 align-middle ms-2">Profit Ratio</span></h4>
                    </li>
                </ul>
				<div class="mb-n3">
                <apx-chart [series]="splineAreaChart.series" [chart]="splineAreaChart.chart"
                [dataLabels]="splineAreaChart.dataLabels" [stroke]="splineAreaChart.stroke"
                [xaxis]="splineAreaChart.xaxis" [yaxis]="splineAreaChart.yaxis"
                [colors]="splineAreaChart.colors" [fill]="splineAreaChart.fill"></apx-chart>
				</div>
            </div>
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-7">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Deals Status</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="text-muted">02 Nov 2021 to 31 Dec 2021<i class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Today</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Week</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                            <a class="dropdown-item" href="javascript:void(0);">Current Year</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="table-responsive table-card">
                    <app-deals-status [DealsStatus]="DealsStatus"></app-deals-status>
                </div><!-- end table responsive -->
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-5">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">My Tasks</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="text-muted"><i class="ri-settings-4-line align-middle me-1 fs-15"></i>Settings</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);">Remove</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->

            <div class="card-body p-0">

                <div class="align-items-center p-3 justify-content-between d-flex">
                    <div class="flex-shrink-0">
                        <div class="text-muted"><span class="fw-semibold">4</span> of <span class="fw-semibold">10</span> remaining</div>
                    </div>
                    <button type="button" class="btn btn-sm btn-success"><i class="ri-add-line align-middle me-1"></i> Add Task</button>
                </div><!-- end card header -->
                
                <ngx-simplebar style="max-height: 219px;">
                    <ul class="list-group list-group-flush border-dashed px-3">
                        <li class="list-group-item ps-0">
                            <div class="d-flex align-items-start">
                                <div class="form-check ps-0 flex-sharink-0">
                                    <input type="checkbox" class="form-check-input ms-0" id="task_one">
                                </div>
                                <div class="flex-grow-1">
                                    <label class="form-check-label mb-0 ps-2" for="task_one">Review and make sure nothing slips through cracks</label>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                    <p class="text-muted fs-12 mb-0">15 Sep, 2021</p>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item ps-0">
                            <div class="d-flex align-items-start">
                                <div class="form-check ps-0 flex-sharink-0">
                                    <input type="checkbox" class="form-check-input ms-0" id="task_two">
                                </div>
                                <div class="flex-grow-1">
                                    <label class="form-check-label mb-0 ps-2" for="task_two">Send meeting invites for sales upcampaign</label>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                    <p class="text-muted fs-12 mb-0">20 Sep, 2021</p>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item ps-0">
                            <div class="d-flex align-items-start">
                                <div class="form-check flex-sharink-0 ps-0">
                                    <input type="checkbox" class="form-check-input ms-0" id="task_three">
                                </div>
                                <div class="flex-grow-1">
                                    <label class="form-check-label mb-0 ps-2" for="task_three">Weekly closed sales won checking with sales team</label>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                    <p class="text-muted fs-12 mb-0">24 Sep, 2021</p>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item ps-0">
                            <div class="d-flex align-items-start">
                                <div class="form-check ps-0 flex-sharink-0">
                                    <input type="checkbox" class="form-check-input ms-0" id="task_four">
                                </div>
                                <div class="flex-grow-1">
                                    <label class="form-check-label mb-0 ps-2" for="task_four">Add notes that can be viewed from the individual view</label>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                    <p class="text-muted fs-12 mb-0">27 Sep, 2021</p>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item ps-0">
                            <div class="d-flex align-items-start">
                                <div class="form-check ps-0 flex-sharink-0">
                                    <input type="checkbox" class="form-check-input ms-0" id="task_five">
                                </div>
                                <div class="flex-grow-1">
                                    <label class="form-check-label mb-0 ps-2" for="task_five">Move stuff to another page</label>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                    <p class="text-muted fs-12 mb-0">27 Sep, 2021</p>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item ps-0">
                            <div class="d-flex align-items-start">
                                <div class="form-check ps-0 flex-sharink-0">
                                    <input type="checkbox" class="form-check-input ms-0" id="task_six">
                                </div>
                                <div class="flex-grow-1">
                                    <label class="form-check-label mb-0 ps-2" for="task_six">Styling wireframe design and documentation for velzon admin</label>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                    <p class="text-muted fs-12 mb-0">27 Sep, 2021</p>
                                </div>
                            </div>
                        </li>
                    </ul><!-- end ul -->
                </ngx-simplebar>
                <div class="p-3 pt-2">
                    <a href="javascript:void(0);" class="text-muted text-decoration-underline">Show more...</a>
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xxl-5">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Upcoming Activities</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="text-muted fs-18"><i class="mdi mdi-dots-vertical"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);">Remove</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->
            <div class="card-body pt-0">
                <ul class="list-group list-group-flush border-dashed">
                    <app-upcoming-activities [UpcomingActivities]="UpcomingActivities"></app-upcoming-activities>
                </ul><!-- end -->
                <div class="align-items-center mt-2 row g-3 text-center text-sm-start">
                    <div class="col-sm">
                        <div class="text-muted">Showing<span class="fw-semibold">4</span> of <span class="fw-semibold">125</span> Results
                        </div>
                    </div>
                    <div class="col-sm-auto">
                        <ul class="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                            <li class="page-item disabled">
                                <a href="javascript:void(0);" class="page-link">←</a>
                            </li>
                            <li class="page-item">
                                <a href="javascript:void(0);" class="page-link">1</a>
                            </li>
                            <li class="page-item active">
                                <a href="javascript:void(0);" class="page-link">2</a>
                            </li>
                            <li class="page-item">
                                <a href="javascript:void(0);" class="page-link">3</a>
                            </li>
                            <li class="page-item">
                                <a href="javascript:void(0);" class="page-link">→</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
    
    <div class="col-xxl-7">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Closing Deals</h4>
                <div class="flex-shrink-0">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option selected="">Closed Deals</option>
                        <option value="1">Active Deals</option>
                        <option value="2">Paused Deals</option>
                        <option value="3">Canceled Deals</option>
                    </select>
                </div>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="table-responsive">
                    <app-closing-deals [ClosingDeals]="ClosingDeals"></app-closing-deals>
                </div><!-- end table responsive -->
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->