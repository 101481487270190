<!-- Start Breadcrumbs -->
<app-breadcrumbs title="CALENDAR" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-h-100">
                    <div class="card-body">
                        <div id="calendar"></div>
                        <full-calendar [options]="calendarOptions"></full-calendar>
                    </div>
                </div>
            </div><!-- end col -->
        </div><!--end row-->

        <div style='clear:both'></div>

        <!-- Add New Event MODAL -->
        <ng-template #modalShow let-modal>
        <div class="modal-header p-3 bg-soft-info">
            <h5 class="modal-title" id="modal-title">Add Event</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true" (click)="closeEventModal()"></button>
        </div>
        <div class="modal-body p-4">
            <form class="needs-validation" name="event-form" id="form-event" novalidate (ngSubmit)="saveEvent()"
                [formGroup]="formData">
                <div class="row event-form">
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Type</label>
                            <select class="form-select" name="category" formControlName="category" [ngClass]="{'is-invalid': submitted && form['category'].errors}" id="event-category"  required>
                                <option value="bg-soft-danger">Danger</option>
                                <option value="bg-soft-success">Success</option>
                                <option value="bg-soft-primary">Primary</option>
                                <option value="bg-soft-info">Info</option>
                                <option value="bg-soft-dark">Dark</option>
                                <option value="bg-soft-warning">Warning</option>
                            </select>
                            <div *ngIf="submitted && form['category'].errors" class="invalid-feedback">
                                <div *ngIf="form['category'].errors['required']">
                                    This value is required
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Event Name</label>
                            <input class="form-control" placeholder="Enter event name" type="text" name="title" id="event-title" required value="" formControlName="title"
                            [ngClass]="{ 'is-invalid': submitted && form['title'].errors }" />
                            <div *ngIf="submitted && form['title'].errors" class="invalid-feedback">
                                <span *ngIf="form['title'].errors['required']">This value is required.</span>
                            </div>
                        </div>
                    </div><!--end col-->
                    <div class="col-12">
                        <div class="mb-3">
                            <label>Event Date</label>
                            <div class="input-group">
                                <!-- <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" placeholder="Select date"> -->
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" placeholder="Select date">
                            </div>
                        </div>
                    </div><!--end col-->
                    <div class="col-12" id="event-time">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-3">
                                    <label class="form-label">Start Time</label>
                                    <div class="input-group">
                                        <input class="form-control flatpickr-input" type="text" placeholder="Select start time" mwlFlatpickr [noCalendar]="true" [enableTime]="true" [dateFormat]="'H:i'">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3">
                                    <label class="form-label">End Time</label>
                                    <div class="input-group">
                                        <input class="form-control flatpickr-input" type="text" placeholder="Select end time" mwlFlatpickr [noCalendar]="true" [enableTime]="true" [dateFormat]="'H:i'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <div class="col-12">
                        <div class="mb-3">
                            <label for="event-location">Location</label>
                            <div>
                                <input type="text" class="form-control" name="event-location" id="event-location" placeholder="Event location">
                            </div>
                        </div>
                    </div><!--end col-->
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Description</label>
                            <textarea class="form-control" id="event-description" placeholder="Enter a description" rows="3" spellcheck="false"></textarea>
                        </div>
                    </div><!--end col-->
                </div>
                <div class="hstack gap-2 justify-content-end">
                    <button type="submit" class="btn btn-success" id="btn-save-event">Add Event</button>
                </div>
            </form>
        </div>
        </ng-template>
        <!-- end modal-->

    <!-- Edit event modal -->
    <ng-template #editmodalShow let-modal>
      <div class="modal-header p-3 bg-soft-info">
          <h5 class="modal-title" id="modal-title">All Day Event</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"
              (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body p-4">
          <form [formGroup]="formEditData" (ngSubmit)="editEventSave()">
              <div class="row event-form">
                <div class="col-12">
                    <div class="mb-3">
                        <label class="form-label">Type</label>
                        <select class="form-control" name="ecategory" formControlName="editCategory" required>
                            <option *ngFor="let option of category" value="{{ option.value }}">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div><!--end col-->
                <div class="col-12">
                    <div class="mb-3">
                        <label class="form-label">Event Name</label>
                        <input class="form-control" placeholder="Insert Event Name" type="text" name="editTitle" formControlName="editTitle" />
                    </div>
                </div><!--end col-->
                <div class="col-12">
                    <div class="mb-3">
                        <label>Event Date</label>
                        <div class="input-group">
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" placeholder="Select date">
                        </div>
                    </div>
                </div><!--end col-->
                <div class="col-12" id="event-time">
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <label class="form-label">Start Time</label>
                                <div class="input-group">
                                    <input class="form-control flatpickr-input" type="text" placeholder="Select start time" mwlFlatpickr [noCalendar]="true" [enableTime]="true" [dateFormat]="'H:i'">
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <label class="form-label">End Time</label>
                                <div class="input-group">
                                    <input class="form-control flatpickr-input" type="text" placeholder="Select end time" mwlFlatpickr [noCalendar]="true" [enableTime]="true" [dateFormat]="'H:i'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--end col-->
                <div class="col-12">
                    <div class="mb-3">
                        <label for="event-location">Location</label>
                        <div>
                            <input type="text" class="form-control" name="event-location" id="event-location" placeholder="Event location">
                        </div>
                    </div>
                </div><!--end col-->
                <div class="col-12">
                    <div class="mb-3">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" id="event-description" placeholder="Enter a description" rows="3" spellcheck="false"></textarea>
                    </div>
                </div><!--end col-->
              </div>
              <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-soft-danger" id="btn-delete-event" (click)="confirm()"><i class="ri-close-line align-bottom"></i> Delete</button>
                <button type="submit" class="btn btn-success" id="btn-save-event">Update Event</button>
            </div>
          </form>
      </div>
  </ng-template>
  <!-- end modal-->
    </div>
</div> <!-- end row-->
