<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Widgets" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
  <div class="col-xl-3 col-md-6" *ngFor="let data of tileBoxs1">
      <!-- card -->
      <div class="card card-animate">
          <div class="card-body" [ngClass]=" { 'bg-info': data.bgColor}">
              <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                      <p class="text-uppercase fw-medium text-{{data.labelClass}} mb-0">{{data.label}}</p>
                  </div>
                  <div class="flex-shrink-0">
                      <h5 class="text-{{data.percentageClass}} fs-14 mb-0">
                          <i class="{{data.percentageIcon}} fs-13 align-middle"></i> {{data.percentage}}
                      </h5>
                  </div>
              </div>
              <div class="d-flex align-items-end justify-content-between mt-4">
                  <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4" [ngClass]=" { 'text-white': data.counterClass}">{{data.prefix}}<span [CountTo]="data.counter" class="counter-value" [from]="0" [duration]="data.decimals"></span>{{data.suffix}}</h4>
                      <a href="javascript:void(0);" class="text-decoration-underline" [ngClass]=" { 'text-white-50': data.captionClass}">{{data.caption}}</a>
                  </div>
                  <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-{{data.iconClass}} rounded fs-3">
                          <i class="{{data.icon}} text-{{data.iconClass}}"></i>
                      </span>
                  </div>
              </div>
          </div><!-- end card body -->
      </div><!-- end card -->
  </div><!-- end col -->
</div> <!-- end row-->

<div class="row">
  <div class="col-xl-12">
      <div class="card crm-widget">
          <div class="card-body p-0">
              <div class="row row-cols-md-3 row-cols-1">
                  <div class="col col-lg border-end" *ngFor="let data of tileBoxs2">
                      <div class="py-4 px-3">
                          <h5 class="text-muted text-uppercase fs-13">{{data.label}} <i class="{{data.badge}} fs-18 float-end align-middle"></i></h5>
                          <div class="d-flex align-items-center">
                              <div class="flex-shrink-0">
                                  <i class="{{data.icon}} display-6 text-muted"></i>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h2 class="mb-0"><span [CountTo]="data.counter" class="counter-value" [from]="0" [duration]="data.decimals"></span></h2>
                              </div>
                          </div>
                      </div>
                  </div><!-- end col -->
              </div><!-- end row -->
          </div><!-- end card body -->
      </div><!-- end card -->
  </div><!-- end col -->
</div><!-- end row -->

<div class="row">
  <div class="col-xl-3 col-md-6" *ngFor="let data of tileBoxs3">
      <div class="card card-animate" [ngClass]=" { 'bg-primary': data.bgColor}">
          <div class="card-body">
              <div class="d-flex justify-content-between">
                  <div>
                      <p class="fw-medium text-{{data.labelClass}} mb-0">{{data.label}}</p>
                      <h2 class="mt-4 ff-secondary fw-semibold" [ngClass]=" { 'text-white': data.counterClass}"><span *ngFor="let user of data.subCounter"><span [CountTo]="user.counter" class="counter-value" [from]="0" [duration]="user.decimals"></span>{{user.suffix}}</span></h2>
                      <p class="mb-0 text-{{data.labelClass}}"><span class="badge {{data.badgeClass}} mb-0">
                          <i class="{{data.badge}}"></i> {{data.percentage}}
                      </span> vs. previous month</p>
                  </div>
                  <div>
                      <div class="avatar-sm flex-shrink-0">
                          <span class="avatar-title bg-soft-{{data.iconClass}} rounded-circle fs-2">
                              <i-feather name="{{data.feaIcon}}" class="text-{{data.iconClass}} feather-icon-align"></i-feather>
                          </span>
                      </div>
                  </div>
              </div>
          </div><!-- end card body -->
      </div> <!-- end card-->
  </div> <!-- end col-->
</div> <!-- end row-->

<div class="row">
  <div class="col-xl-3 col-md-6" *ngFor="let data of tileBoxs4">
      <div class="card  card-height-100" [ngClass]=" { 'bg-success': data.bgColor}">
          <div class="card-body">
               <div class="d-flex align-items-center">
                   <div class="avatar-sm flex-shrink-0">
                       <span class="avatar-title bg-soft-{{data.iconClass}} text-{{data.iconClass}} rounded-2 fs-2">
                           <i class="{{data.icon}}"></i>
                       </span>
                   </div>
                   <div class="flex-grow-1 ms-3">
                      <p class="text-uppercase fw-medium text-{{data.labelClass}} mb-3">{{data.label}}</p>
                      <h4 class="fs-4 mb-3 {{data.counterClass}}"><span [CountTo]="data.counter" class="counter-value" [from]="0" [duration]="data.decimals"></span></h4>
                      <p class="text-{{data.captionClass}} mb-0">{{data.caption}}</p>
                   </div>
                   <div class="flex-shrink-0 align-self-center">
                       <span class="badge badge-soft-{{data.percentageClass}} fs-12"><i class="{{data.badge}} fs-13 align-middle me-1"></i>{{data.percentage}}<span>
                   </span></span></div>
               </div>
          </div><!-- end card body -->
      </div>
  </div> <!-- end col-->
</div> <!-- end row-->

<div class="row">
  <div class="col-12">
      <h5 class="text-decoration-underline mb-3 mt-2 pb-3">Other Widgets</h5>
  </div>
</div>
<!-- end row-->

<div class="row">
  <div class="col-xl-4">
      <div class="card">
          <div class="card-body p-0">
              <div class="alert alert-warning border-0 rounded-top rounded-0 m-0 d-flex align-items-center" role="alert">
                  <i-feather name="alert-triangle" class="text-warning me-2 icon-sm"></i-feather>
                  <div class="flex-grow-1 text-truncate">
                      Your free trial expired in <b>17</b> days.
                  </div>
                  <div class="flex-shrink-0">
                      <a routerLink="/pages/pricing" class="text-reset text-decoration-underline"><b>Upgrade</b></a>
                  </div>
              </div>

              <div class="row align-items-end">
                  <div class="col-sm-8">
                      <div class="p-3">
                          <p class="fs-16 lh-base">Upgrade your plan from a <span class="fw-semibold">Free
                              trial</span>, to ‘Premium Plan’ <i class="mdi mdi-arrow-right"></i></p>
                          <div class="mt-3">
                              <a routerLink="/pages/pricing" class="btn btn-success">Upgrade Account!</a>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div class="px-3">
                          <img src="assets/images/user-illustarator-2.png" class="img-fluid" alt="">
                      </div>
                  </div>
              </div>
          </div> <!-- end card-body-->
      </div>
  </div> <!-- end col-->
  <div class="col-xl-4">
      <div class="card bg-primary">
          <div class="card-body p-0">
              <div class="alert alert-danger rounded-top alert-solid alert-label-icon border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                  <i class="ri-error-warning-line label-icon"></i>
                  <div class="flex-grow-1 text-truncate">
                      Your free trial expired in <b>17</b> days.
                  </div>
                  <div class="flex-shrink-0">
                      <a routerLink="/pages/pricing" class="text-reset text-decoration-underline"><b>Upgrade</b></a>
                  </div>
              </div>

              <div class="row align-items-end">
                  <div class="col-sm-8">
                      <div class="p-3">
                          <p class="fs-16 lh-base text-white">Upgrade your plan from a <span class="fw-semibold">Free
                              trial</span>, to ‘Premium Plan’ <i class="mdi mdi-arrow-right"></i></p>
                          <div class="mt-3">
                              <a routerLink="/pages/pricing" class="btn btn-info">Upgrade Account!</a>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div class="px-3">
                          <img src="assets/images/user-illustarator-1.png" class="img-fluid" alt="">
                      </div>
                  </div>
              </div>
          </div> <!-- end card-body-->
      </div>
  </div> <!-- end col-->
  <div class="col-xl-4">
      <div class="card">
          <div class="card-body p-0">
              <div class="alert alert-warning border-0 rounded-top rounded-0 m-0 d-flex align-items-center" role="alert">
                  <div class="flex-grow-1 text-truncate">
                      We will choose a gift for you in <b>5</b> days.
                  </div>
                  <div class="flex-shrink-0">
                      <a routerLink="/pages/pricing" class="text-reset text-decoration-underline"><b>Get Free Gift</b></a>
                  </div>
              </div>
              <div class="d-flex p-3">
                  <div class="flex-shrink-0">
                      <div class="avatar-md me-3">
                          <span class="avatar-title bg-soft-danger rounded-circle fs-1">
                              <i class="ri-gift-2-line text-danger"></i>
                          </span>
                      </div>
                  </div>
                  <div>
                      <p class="fs-16 lh-base">Personalized <span class="fw-semibold">Gift Boxes</span>, with attitude, Let's collect your Xmas box <i class="mdi mdi-arrow-right"></i></p>
                      <div class="mt-3">
                          <a routerLink="/pages/pricing" class="btn btn-secondary">Get a Free Gift</a>
                      </div>
                  </div>
              </div>
          </div> <!-- end card-body-->
      </div>
  </div> <!-- end col-->
</div> <!-- end row-->

<div class="row">
  <div class="col-lg-4" *ngFor="let data of otherWidgets">
      <div class="card">
          <div class="card-body">
              <h5 class="fs-15 fw-semibold">{{data.title}}</h5>
              <p class="text-muted">{{data.subTitle}}</p>
              <div class="d-flex flex-wrap justify-content-evenly">
                   <p class="text-muted mb-0" *ngFor="let item of data.subItem"><i class="{{item.icon}} text-{{item.iconClass}} fs-18 align-middle me-2"></i>{{item.label}}</p>
              </div>
          </div>
          <div class="progress animated-progess rounded-bottom rounded-0" style="height: 6px;">
              <div class="progress-bar {{progress.bgColor}} rounded-0" role="progressbar" style="width: 30%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100" *ngFor="let progress of data.progressBar"></div>
          </div>
      </div>
  </div><!-- end col -->
</div> <!-- end row-->

<div class="row">
  <div class="col-xxl-5">
      <div class="card card-height-100">
          <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Upcoming Activities</h4>
              <div class="flex-shrink-0">
                  <div class="dropdown card-header-dropdown" ngbDropdown>
                      <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                          <span class="text-muted fs-18"><i class="mdi mdi-dots-vertical"></i></span>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                          <a class="dropdown-item" href="javascript:void(0);">Edit</a>
                          <a class="dropdown-item" href="javascript:void(0);">Remove</a>
                      </div>
                  </div>
              </div>
          </div><!-- end card header -->
          <div class="card-body pt-0">
              <ul class="list-group list-group-flush border-dashed">
                  <li class="list-group-item ps-0" *ngFor="let data of widgetsActivities">
                      <div class="row align-items-center g-3">
                          <div class="col-auto">
                              <div class="avatar-sm p-1 py-2 h-auto bg-light rounded-3">
                                  <div class="text-center">
                                       <h5 class="mb-0">{{data.date}}</h5>
                                       <div class="text-muted">{{data.weekDay}}</div>
                                  </div>
                              </div>
                          </div>
                          <div class="col">
                              <h5 class="text-muted mt-0 mb-1 fs-13">{{data.time}}</h5>
                              <a href="javascript:void(0);" class="text-reset fs-14 mb-0">{{data.caption}}</a>
                          </div>
                          <div class="col-sm-auto">
                              <div class="avatar-group">
                                  <div class="avatar-group-item" *ngFor="let item of data.subItem">
                                      <a href="javascript: void(0);" class="d-inline-block" ngbTooltip="{{item.name}}" placement="top" *ngIf="item.img">
                                          <img src="{{item.img}}" alt="" class="rounded-circle avatar-xxs">
                                      </a>
                                      <a href="javascript: void(0);" *ngIf="!item.img">
                                        <div class="avatar-xxs">
                                            <span class="avatar-title rounded-circle {{item.bgcolor}} text-white">
                                              {{item.imgNumber}}
                                            </span>
                                        </div>
                                      </a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- end row -->
                  </li><!-- end -->
              </ul><!-- end -->
              <div class="align-items-center mt-2 row text-center text-sm-start">
                  <div class="col-sm">
                      <div class="text-muted">Showing<span class="fw-semibold">4</span> of <span class="fw-semibold">125</span> Results
                      </div>
                  </div>
                  <div class="col-sm-auto">
                      <ul class="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                          <li class="page-item disabled">
                              <a href="javascript:void(0);" class="page-link">←</a>
                          </li>
                          <li class="page-item">
                              <a href="javascript:void(0);" class="page-link">1</a>
                          </li>
                          <li class="page-item active">
                              <a href="javascript:void(0);" class="page-link">2</a>
                          </li>
                          <li class="page-item">
                              <a href="javascript:void(0);" class="page-link">3</a>
                          </li>
                          <li class="page-item">
                              <a href="javascript:void(0);" class="page-link">→</a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div><!-- end card body -->
      </div><!-- end card -->
  </div> <!-- end col-->
  <div class="col-xxl-7">
      <div class="row">
          <div class="col-xl-6">
              <div class="card card-height-100">
                  <div class="card-header align-items-center d-flex">
                      <h4 class="card-title mb-0 flex-grow-1">My Tasks</h4>
                      <div class="flex-shrink-0">
                          <div class="dropdown card-header-dropdown" ngbDropdown>
                              <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                  <span class="text-muted"><i class="ri-settings-4-line align-middle me-1 fs-15"></i>Settings</span>
                              </a>
                              <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                  <a class="dropdown-item" href="javascript:void(0);">Edit</a>
                                  <a class="dropdown-item" href="javascript:void(0);">Remove</a>
                              </div>
                          </div>
                      </div>
                  </div><!-- end card header -->

                  <div class="card-body p-0">

                      <div class="align-items-center p-3 justify-content-between d-flex">
                          <div class="flex-shrink-0">
                              <div class="text-muted"><span class="fw-semibold">4</span> of <span class="fw-semibold">10</span> remaining</div>
                          </div>
                          <button type="button" class="btn btn-sm btn-success"><i class="ri-add-line align-middle me-1"></i> Add Task</button>
                      </div><!-- end card header -->


                      <ngx-simplebar style="max-height: 257px;">
                        <ul class="list-group list-group-flush border-dashed px-3">
                            <li class="list-group-item ps-0" *ngFor="let data of widgetsTasks">
                                <div class="d-flex align-items-start">
                                    <div class="form-check ps-0 flex-sharink-0">
                                        <input type="checkbox" class="form-check-input ms-0" id="{{data.forId}}">
                                    </div>
                                    <div class="flex-grow-1">
                                        <label class="form-check-label mb-0 ps-2" for="task_one">{{data.text}}</label>
                                    </div>
                                    <div class="flex-shrink-0 ms-2">
                                        <p class="text-muted fs-12 mb-0">{{data.date}}</p>
                                    </div>
                                </div>
                            </li>
                        </ul><!-- end ul -->
                      </ngx-simplebar>

                      <div class="p-3">
                          <a href="javascript:void(0);" class="text-muted text-decoration-underline">Show more...</a>
                      </div>
                  </div><!-- end card body -->
              </div><!-- end card -->
          </div><!-- end col -->
          <div class="col-xl-6">
              <div class="card card-height-100">
                  <div class="card-header border-bottom-dashed align-items-center d-flex">
                      <h4 class="card-title mb-0 flex-grow-1">Recent Activity</h4>
                      <div class="flex-shrink-0">
                          <button type="button" class="btn btn-soft-primary btn-sm">
                              View All Activity
                          </button>
                      </div>
                  </div><!-- end cardheader -->
                  <div class="card-body p-0">
                    <ngx-simplebar class="p-3" style="max-height: 364px;">
                      <div class="acitivity-timeline acitivity-main">
                          <div class="acitivity-item d-flex">
                              <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                  <div class="avatar-title bg-soft-success text-success rounded-circle">
                                      <i class="ri-shopping-cart-2-line"></i>
                                  </div>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h6 class="mb-1">Purchase by James Price</h6>
                                  <p class="text-muted mb-1">Product noise evolve smartwatch </p>
                                  <small class="mb-0 text-muted">02:14 PM Today</small>
                              </div>
                          </div>
                          <div class="acitivity-item py-3 d-flex">
                              <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                  <div class="avatar-title bg-soft-primary text-primary rounded-circle">
                                      <i class="ri-stack-fill"></i>
                                  </div>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h6 class="mb-1">Added new <span class="fw-semibold">style collection</span></h6>
                                  <p class="text-muted mb-1">By Nesta Technologies</p>
                                  <div class="d-inline-flex gap-2 border border-dashed p-2 mb-2 w-75">
                                      <a href="ecommerce/product-detail/1" class="bg-light rounded p-1">
                                          <img src="assets/images/products/img-8.png" alt="" class="img-fluid d-block" />
                                      </a>
                                      <a href="ecommerce/product-detail/1" class="bg-light rounded p-1">
                                          <img src="assets/images/products/img-2.png" alt="" class="img-fluid d-block" />
                                      </a>
                                      <a href="ecommerce/product-detail/1" class="bg-light rounded p-1">
                                          <img src="assets/images/products/img-10.png" alt="" class="img-fluid d-block" />
                                      </a>
                                  </div>
                                  <p class="mb-0 text-muted"><small>9:47 PM Yesterday</small></p>
                              </div>
                          </div>
                          <div class="acitivity-item py-3 d-flex">
                              <div class="flex-shrink-0">
                                  <img src="assets/images/users/avatar-2.jpg" alt="" class="avatar-xs rounded-circle acitivity-avatar">
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h6 class="mb-1">Natasha Carey have liked the products</h6>
                                  <p class="text-muted mb-1">Allow users to like products in your WooCommerce store.</p>
                                  <small class="mb-0 text-muted">25 Dec, 2021</small>
                              </div>
                          </div>
                          <div class="acitivity-item py-3 d-flex">
                              <div class="flex-shrink-0">
                                  <div class="avatar-xs acitivity-avatar">
                                      <div class="avatar-title rounded-circle bg-secondary">
                                          <i class="mdi mdi-sale fs-14"></i>
                                      </div>
                                  </div>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h6 class="mb-1">Today offers by <a href="ecommerce/seller-details" class="link-secondary">Digitech Galaxy</a></h6>
                                  <p class="text-muted mb-2">Offer is valid on orders of Rs.500 Or above for selected products only.</p>
                                  <small class="mb-0 text-muted">12 Dec, 2021</small>
                              </div>
                          </div>
                          <div class="acitivity-item py-3 d-flex">
                              <div class="flex-shrink-0">
                                  <div class="avatar-xs acitivity-avatar">
                                      <div class="avatar-title rounded-circle bg-soft-danger text-danger">
                                          <i class="ri-bookmark-fill"></i>
                                      </div>
                                  </div>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h6 class="mb-1">Favoried Product</h6>
                                  <p class="text-muted mb-2">Esther James have favorited product.</p>
                                  <small class="mb-0 text-muted">25 Nov, 2021</small>
                              </div>
                          </div>
                          <div class="acitivity-item py-3 d-flex">
                              <div class="flex-shrink-0">
                                  <div class="avatar-xs acitivity-avatar">
                                      <div class="avatar-title rounded-circle bg-secondary">
                                          <i class="mdi mdi-sale fs-14"></i>
                                      </div>
                                  </div>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h6 class="mb-1">Flash sale starting <span class="text-primary">Tomorrow.</span></h6>
                                  <p class="text-muted mb-0">Flash sale by <a href="javascript:void(0);" class="link-secondary fw-medium">Zoetic Fashion</a></p>
                                  <small class="mb-0 text-muted">22 Oct, 2021</small>
                              </div>
                          </div>
                          <div class="acitivity-item py-3 d-flex">
                              <div class="flex-shrink-0">
                                  <div class="avatar-xs acitivity-avatar">
                                      <div class="avatar-title rounded-circle bg-soft-info text-info">
                                          <i class="ri-line-chart-line"></i>
                                      </div>
                                  </div>
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h6 class="mb-1">Monthly sales report</h6>
                                  <p class="text-muted mb-2"><span class="text-danger">2 days left</span> notification to submit the monthly sales report. <a href="javascript:void(0);" class="link-warning text-decoration-underline">Reports Builder</a></p>
                                  <small class="mb-0 text-muted">15 Oct</small>
                              </div>
                          </div>
                          <div class="acitivity-item d-flex">
                              <div class="flex-shrink-0">
                                  <img src="assets/images/users/avatar-3.jpg" alt="" class="avatar-xs rounded-circle acitivity-avatar" />
                              </div>
                              <div class="flex-grow-1 ms-3">
                                  <h6 class="mb-1">Frank Hook Commented</h6>
                                  <p class="text-muted mb-2 fst-italic">" A product that has reviews is more likable to be sold than a product. "</p>
                                  <small class="mb-0 text-muted">26 Aug, 2021</small>
                              </div>
                          </div>
                      </div>
                    </ngx-simplebar>
                  </div><!-- end card body -->
              </div><!-- end card -->
          </div><!-- end col -->
      </div> <!-- end row-->
  </div> <!-- end col-xl-7-->
</div>
<!-- end row-->

<div class="row">
  <div class="col-12">
      <h5 class="text-decoration-underline mb-3 mt-2 pb-3">Chart & Map Widgets</h5>
  </div>
</div>
<!-- end row-->

<div class="row">
  <div class="col-xxl-4 col-xl-6">
      <div class="card card-height-100">
          <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Live Users By Country</h4>
              <div class="flex-shrink-0">
                  <button type="button" class="btn btn-soft-primary btn-sm">
                      Export Report
                  </button>
              </div>
          </div><!-- end card header -->

          <!-- card body -->
          <div class="card-body">
              <div id="sales-by-locations" leaflet style="height: 269px;" [leafletOptions]="options" [leafletLayers]="layers"></div>
              <div class="table-responsive table-card mt-3">
                  <table class="table table-borderless table-sm table-centered align-middle table-nowrap mb-1">
                      <thead class="text-muted border-dashed border border-start-0 border-end-0 bg-soft-light">
                          <tr>
                              <th>Duration (Secs)</th>
                              <th style="width: 30%;">Sessions</th>
                              <th style="width: 30%;">Views</th>
                          </tr>
                      </thead>
                      <tbody class="border-0">
                          <tr>
                              <td>0-30</td>
                              <td>2,250</td>
                              <td>4,250</td>
                          </tr>
                          <tr>
                              <td>31-60</td>
                              <td>1,501</td>
                              <td>2,050</td>
                          </tr>
                          <tr>
                              <td>61-120</td>
                              <td>750</td>
                              <td>1,600</td>
                          </tr>
                          <tr>
                              <td>121-240</td>
                              <td>540</td>
                              <td>1,040</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
          <!-- end card body -->
      </div><!-- end card -->
  </div><!-- end col -->

  <div class="col-xxl-4 col-xl-6">
      <div class="card card-height-100">
          <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Sessions by Countries</h4>
              <div class="d-flex gap-1">
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                      ALL
                  </button>
                  <button type="button" class="btn btn-soft-primary btn-sm">
                      1M
                  </button>
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                      6M
                  </button>
              </div>
          </div>
          <div class="card-body p-0">
              <div>
                  <apx-chart [series]="basicBarChart.series" [colors]="basicBarChart.colors" [chart]="basicBarChart.chart"
                      [dataLabels]="basicBarChart.dataLabels" [plotOptions]="basicBarChart.plotOptions"
                      [xaxis]="basicBarChart.xaxis"></apx-chart>
              </div>
          </div><!-- end card body -->
      </div><!-- end card -->
  </div> <!-- end col-->

  <div class="col-xxl-4">
      <div class="card card-height-100">
          <div class="card-header border-0 align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Audiences Metrics</h4>
              <div class="d-flex gap-1">
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                      ALL
                  </button>
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                      1M
                  </button>
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                      6M
                  </button>
                  <button type="button" class="btn btn-soft-primary btn-sm">
                      1Y
                  </button>
              </div>
          </div><!-- end card header -->
          <div class="card-header p-0">
              <div class="alert alert-warning alert-solid alert-label-icon border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                  <i class="ri-error-warning-line label-icon"></i>
                  <div class="flex-grow-1 text-truncate">
                      Your free trial expired in <b>17</b> days.
                  </div>
                  <div class="flex-shrink-0">
                      <a routerLink="/pages/pricing" class="text-reset text-decoration-underline"><b>Upgrade</b></a>
                  </div>
              </div>
          </div>
          <div class="card-header p-0 border-0 bg-soft-light">
            <div class="row g-0 text-center">
                <div class="col-6 col-sm-4">
                    <div class="p-3 border border-dashed border-start-0">
                        <h5 class="mb-1"><span [CountTo]="854" class="counter-value" [from]="0" [duration]="1"></span>
                            <span class="text-success ms-1 fs-13">49%</span>
                           
                        </h5>
                        <p class="text-muted mb-0">Avg. Session</p>
                    </div>
                </div><!--end col-->
                <div class="col-6 col-sm-4">
                    <div class="p-3 border border-dashed border-start-0">
                        <h5 class="mb-1"><span [CountTo]="1278" class="counter-value" [from]="0" [duration]="1"></span>
                            <span class="text-success ms-1 fs-13">60%<i class="ri-arrow-right-up-line ms-1 align-middle"></i></span>
                        </h5>
                        <p class="text-muted mb-0">Conversion Rate</p>
                    </div>
                </div><!--end col-->
                <div class="col-6 col-sm-4">
                    <div class="p-3 border border-dashed border-start-0 border-end-0">
                        <h5 class="mb-1"><span [CountTo]="3" class="counter-value" [from]="0" [duration]="1"></span>m <span [CountTo]="40" class="counter-value" [from]="0" [duration]="1"></span>sec
                        </h5>
                        <p class="text-muted mb-0">Avg. Ses. Duration</p>
                    </div>
                </div><!--end col-->
            </div>
          </div><!-- end card header -->

          <div class="card-body p-0 pb-2">
              <div>
                  <apx-chart [series]="basicColumnChart.series" [chart]="basicColumnChart.chart" [colors]="basicColumnChart.colors" [dataLabels]="basicColumnChart.dataLabels"
              [plotOptions]="basicColumnChart.plotOptions" [yaxis]="basicColumnChart.yaxis" [legend]="basicColumnChart.legend"
              [fill]="basicColumnChart.fill" [stroke]="basicColumnChart.stroke" [tooltip]="basicColumnChart.tooltip"
              [xaxis]="basicColumnChart.xaxis"></apx-chart>
              </div>
          </div><!-- end card body -->
      </div><!-- end card -->
  </div><!-- end col -->

</div> <!-- end row-->

<div class="row">
  <div class="col-xxl-4 col-xl-6">
      <!-- card -->
      <div class="card card-height-100">
          <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Sales by Locations</h4>
              <div class="flex-shrink-0">
                  <button type="button" class="btn btn-soft-primary btn-sm">
                      Export Report
                  </button>
              </div>
          </div><!-- end card header -->

          <!-- card body -->
          <div class="card-body">

            <div id="sales-by-locations" leaflet style="height: 269px;" [leafletOptions]="options" [leafletLayers]="layers"></div>

              <div class="px-2 py-2 mt-1">
                  <p class="mb-1">New Maxico <span class="float-end">75%</span></p>
                  <ngb-progressbar [value]="75" [striped]="true" class="progress-sm"></ngb-progressbar>

                  <p class="mt-3 mb-1">California <span class="float-end">47%</span></p>
                  <ngb-progressbar [value]="47" [striped]="true" class="progress-sm"></ngb-progressbar>

                  <p class="mt-3 mb-1">Texas <span class="float-end">82%</span></p>
                  <ngb-progressbar [value]="82" [striped]="true" class="progress-sm"></ngb-progressbar>
              </div>
          </div>
          <!-- end card body -->
      </div>
      <!-- end card -->
  </div>
  <!-- end col -->

  <div class="col-xxl-4 col-xl-6">
      <div class="card card-height-100">
          <div class="card-header border-bottom-dashed align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">My Portfolio</h4>
              <div>
                  <div class="dropdown" ngbDropdown>
                      <button class="btn btn-soft-primary btn-sm arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                          <span class="text-uppercase">Btc<i class="mdi mdi-chevron-down align-middle ms-1"></i></span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                          <a class="dropdown-item" href="javascript:void(0);">BTC</a>
                          <a class="dropdown-item" href="javascript:void(0);">USD</a>
                          <a class="dropdown-item" href="javascript:void(0);">Euro</a>
                      </div>
                  </div>
              </div>
          </div><!-- end cardheader -->
          <div class="card-body">
             <apx-chart [series]="simpleDonutChart.series" [labels]="simpleDonutChart.labels"
                        [chart]="simpleDonutChart.chart" [plotOptions]="simpleDonutChart.plotOptions"
                        [dataLabels]="simpleDonutChart.dataLabels" [legend]="simpleDonutChart.legend"
                        [stroke]="simpleDonutChart.stroke" [yaxis]="simpleDonutChart.yaxis"
                        [colors]="simpleDonutChart.colors"></apx-chart>

              <ul class="list-group list-group-flush border-dashed mb-0">
                  <li class="list-group-item px-0">
                      <div class="d-flex">
                          <div class="flex-shrink-0 avatar-xs">
                              <span class="avatar-title bg-light p-1 rounded-circle">
                                  <img src="assets/images/svg/crypto-icons/btc.svg" class="img-fluid" alt="">
                              </span>
                          </div>
                          <div class="flex-grow-1 ms-2">
                              <h6 class="mb-1">Bitcoin</h6>
                              <p class="fs-12 mb-0 text-muted"><i class="mdi mdi-circle fs-10 align-middle text-primary me-1"></i>BTC</p>
                          </div>
                          <div class="flex-shrink-0 text-end">
                              <h6 class="mb-1">BTC 0.00584875</h6>
                              <p class="text-success fs-12 mb-0">$19,405.12</p>
                          </div>
                      </div>
                  </li><!-- end -->
                  <li class="list-group-item px-0">
                      <div class="d-flex">
                          <div class="flex-shrink-0 avatar-xs">
                              <span class="avatar-title bg-light p-1 rounded-circle">
                                  <img src="assets/images/svg/crypto-icons/eth.svg" class="img-fluid" alt="">
                              </span>
                          </div>
                          <div class="flex-grow-1 ms-2">
                              <h6 class="mb-1">Ethereum</h6>
                              <p class="fs-12 mb-0 text-muted"><i class="mdi mdi-circle fs-10 align-middle text-info me-1"></i>ETH</p>
                          </div>
                          <div class="flex-shrink-0 text-end">
                              <h6 class="mb-1">ETH 2.25842108</h6>
                              <p class="text-danger fs-12 mb-0">$40552.18</p>
                          </div>
                      </div>
                  </li><!-- end -->
                  <li class="list-group-item px-0">
                      <div class="d-flex">
                          <div class="flex-shrink-0 avatar-xs">
                              <span class="avatar-title bg-light p-1 rounded-circle">
                                  <img src="assets/images/svg/crypto-icons/ltc.svg" class="img-fluid" alt="">
                              </span>
                          </div>
                          <div class="flex-grow-1 ms-2">
                              <h6 class="mb-1">Litecoin</h6>
                              <p class="fs-12 mb-0 text-muted"><i class="mdi mdi-circle fs-10 align-middle text-warning me-1"></i>LTC</p>
                          </div>
                          <div class="flex-shrink-0 text-end">
                              <h6 class="mb-1">LTC 10.58963217</h6>
                              <p class="text-success fs-12 mb-0">$15824.58</p>
                          </div>
                      </div>
                  </li><!-- end -->
                  <li class="list-group-item px-0 pb-0">
                      <div class="d-flex">
                          <div class="flex-shrink-0 avatar-xs">
                              <span class="avatar-title bg-light p-1 rounded-circle">
                                  <img src="assets/images/svg/crypto-icons/dash.svg" class="img-fluid" alt="">
                              </span>
                          </div>
                          <div class="flex-grow-1 ms-2">
                              <h6 class="mb-1">Dash</h6>
                              <p class="fs-12 mb-0 text-muted"><i class="mdi mdi-circle fs-10 align-middle text-success me-1"></i>DASH</p>
                          </div>
                          <div class="flex-shrink-0 text-end">
                              <h6 class="mb-1">DASH 204.28565885</h6>
                              <p class="text-success fs-12 mb-0">$30635.84</p>
                          </div>
                      </div>
                  </li><!-- end -->
              </ul><!-- end -->
          </div><!-- end card body -->
      </div><!-- end card -->
  </div><!-- end col -->

  <div class="col-xxl-4">
      <div class="card card-height-100">
          <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Top Referrals Pages</h4>
              <div class="flex-shrink-0">
                  <button type="button" class="btn btn-soft-primary btn-sm">
                      Export Report
                  </button>
              </div>
          </div>

          <div class="card-body">

              <apx-chart [series]="basicHeatmapChart.series" [chart]="basicHeatmapChart.chart"
                        [legend]="basicHeatmapChart.legend" [plotOptions]="basicHeatmapChart.plotOptions"
                        [dataLabels]="basicHeatmapChart.dataLabels" [stroke]="basicHeatmapChart.stroke"
                        [title]="basicHeatmapChart.title"></apx-chart>

              <div class="row g-3">
                  <div class="col-md-6">
                      <div class="d-flex mb-3">
                          <div class="flex-grow-1">
                              <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-primary me-2"></i>www.google.com</p>
                          </div>
                          <div class="flex-shrink-0">
                              <p class="mb-0">24.58%</p>
                          </div>
                      </div><!-- end -->
                      <div class="d-flex mb-3">
                          <div class="flex-grow-1">
                              <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-warning me-2"></i>www.medium.com</p>
                          </div>
                          <div class="flex-shrink-0">
                              <p class="mb-0">12.22%</p>
                          </div>
                      </div><!-- end -->
                      <div class="d-flex">
                          <div class="flex-grow-1">
                              <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-secondary me-2"></i>Other</p>
                          </div>
                          <div class="flex-shrink-0">
                              <p class="mb-0">17.58%</p>
                          </div>
                      </div><!-- end -->
                  </div>
                  <div class="col-md-6">
                      <div class="d-flex mb-3">
                          <div class="flex-grow-1">
                              <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-info me-2"></i>www.youtube.com</p>
                          </div>
                          <div class="flex-shrink-0">
                              <p class="mb-0">17.51%</p>
                          </div>
                      </div><!-- end -->
                      <div class="d-flex mb-3">
                          <div class="flex-grow-1">
                              <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-success me-2"></i>www.meta.com</p>
                          </div>
                          <div class="flex-shrink-0">
                              <p class="mb-0">23.05%</p>
                          </div>
                      </div><!-- end -->
                  </div>
              </div>

              <div class="mt-2 text-center">
                  <a href="javascript:void(0);" class="text-muted text-decoration-underline">Show All</a>
              </div>

          </div><!-- end card body -->
      </div>
  </div><!--end col-->

</div>
<!-- end row-->
