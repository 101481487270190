<!-- Start Breadcrumbs -->
<app-breadcrumbs title="ANALYTICS" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
    <div class="col-xxl-5">
       
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body p-0">
                            <div class="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                                <i-feather name="alert-triangle" class="text-warning me-2 icon-sm"></i-feather>
                                <div class="flex-grow-1 text-truncate">
                                    Your free trial expired in <b>17</b> days.
                                </div>
                                <div class="flex-shrink-0">
                                    <a routerLink="/pages/pricing" class="text-reset text-decoration-underline"><b>Upgrade</b></a>
                                </div>
                            </div>

                            <div class="row align-items-end">
                                <div class="col-sm-8">
                                    <div class="p-3">
                                        <p class="fs-16 lh-base">Upgrade your plan from a <span class="fw-semibold">Free
                                            trial</span>, to ‘Premium Plan’ <i class="mdi mdi-arrow-right"></i></p>
                                        <div class="mt-3">
                                            <a routerLink="/pages/pricing" class="btn btn-success">Upgrade Account!</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="px-3">
                                        <img src="assets/images/user-illustarator-2.png" class="img-fluid" alt="">
                                    </div>
                                </div>
                            </div>
                        </div> <!-- end card-body-->
                    </div>
                </div> <!-- end col-->
            </div> <!-- end row-->
       

        <div class="row">
            <div *ngFor="let stat of statData" class="col-md-6">
                <app-analatics-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon" [persantage]="stat.persantage" [profit]="stat.profit"></app-analatics-stat>
            </div>
        </div>
    </div> <!-- end col-->

    <div class="col-xxl-7">
        <div class="row h-100">
            <div class="col-xl-6">
                <div class="card card-height-100">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1">Live Users By Country</h4>
                        <div class="flex-shrink-0">
                            <button type="button" class="btn btn-soft-primary btn-sm">
                                Export Report
                            </button>
                        </div>
                    </div><!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">

                        <div id="sales-by-locations" leaflet style="height: 250px;" [leafletOptions]="options" [leafletLayers]="layers"></div>

                        <div class="table-responsive table-card mt-3">
                            <table class="table table-borderless table-sm table-centered align-middle table-nowrap mb-1">
                                <thead class="text-muted border-dashed border border-start-0 border-end-0 bg-soft-light">
                                    <tr>
                                        <th>Duration (Secs)</th>
                                        <th style="width: 30%;">Sessions</th>
                                        <th style="width: 30%;">Views</th>
                                    </tr>
                                </thead>
                                <tbody class="border-0">
                                    <tr>
                                        <td>0-30</td>
                                        <td>2,250</td>
                                        <td>4,250</td>
                                    </tr>
                                    <tr>
                                        <td>31-60</td>
                                        <td>1,501</td>
                                        <td>2,050</td>
                                    </tr>
                                    <tr>
                                        <td>61-120</td>
                                        <td>750</td>
                                        <td>1,600</td>
                                    </tr>
                                    <tr>
                                        <td>121-240</td>
                                        <td>540</td>
                                        <td>1,040</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end card body -->
                </div><!-- end card -->
            </div><!-- end col -->

            <div class="col-xl-6">
                <div class="card card-height-100">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1">Sessions by Countries</h4>
                        <div class="d-flex gap-1">
                            <button type="button" class="btn btn-soft-secondary btn-sm">
                                ALL
                            </button>
                            <button type="button" class="btn btn-soft-primary btn-sm">
                                1M
                            </button>
                            <button type="button" class="btn btn-soft-secondary btn-sm">
                                6M
                            </button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div>
                            <apx-chart [series]="basicBarChart.series" [colors]="basicBarChart.colors" [chart]="basicBarChart.chart"
                      [dataLabels]="basicBarChart.dataLabels" [plotOptions]="basicBarChart.plotOptions"></apx-chart>
                        </div>
                    </div><!-- end card body -->
                </div><!-- end card -->
            </div> <!-- end col-->

        </div> <!-- end row-->
    </div><!-- end col -->
</div> <!-- end row-->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Audiences Metrics</h4>
                <div class="d-flex gap-1">
                    <button type="button" class="btn btn-soft-secondary btn-sm">
                        ALL
                    </button>
                    <button type="button" class="btn btn-soft-secondary btn-sm">
                        1M
                    </button>
                    <button type="button" class="btn btn-soft-secondary btn-sm">
                        6M
                    </button>
                    <button type="button" class="btn btn-soft-primary btn-sm">
                        1Y
                    </button>
                </div>
            </div><!-- end card header -->
            <div class="card-header p-0 border-0 bg-soft-light">
                <div class="row g-0 text-center">
                    <div class="col-6 col-sm-4">
                        <div class="p-3 border border-dashed border-start-0">
                            <h5 class="mb-1">
                                <span [CountTo]="854" class="counter-value" [from]="0" [duration]="1"></span>
                                <span class="text-success ms-1 fs-12">49%<i class="ri-arrow-right-up-line ms-1 align-middle"></i></span>
                            </h5>
                            <p class="text-muted mb-0">Avg. Session</p>
                        </div>
                    </div><!--end col-->
                    <div class="col-6 col-sm-4">
                        <div class="p-3 border border-dashed border-start-0">
                            <h5 class="mb-1"><span [CountTo]="1278" class="counter-value" [from]="0" [duration]="1"></span>
                                <span class="text-success ms-1 fs-12">60%<i class="ri-arrow-right-up-line ms-1 align-middle"></i></span>
                            </h5>
                            <p class="text-muted mb-0">Conversion Rate</p>
                        </div>
                    </div><!--end col-->
                    <div class="col-6 col-sm-4">
                        <div class="p-3 border border-dashed border-start-0 border-end-0">
                            <h5 class="mb-1"><span [CountTo]="3" class="counter-value" [from]="0" [duration]="1"></span>m <span [CountTo]="40" class="counter-value" [from]="0" [duration]="1"></span>sec
                                <span class="text-success ms-1 fs-12">37%<i class="ri-arrow-right-up-line ms-1 align-middle"></i></span>
                            </h5>
                            <p class="text-muted mb-0">Avg. Session Duration</p>
                        </div>
                    </div><!--end col-->
                </div>
            </div><!-- end card header -->
            <div class="card-body p-0 pb-2">
                <div class="mb-n2">
                    <apx-chart [series]="basicColumnChart.series" [chart]="basicColumnChart.chart" [colors]="basicColumnChart.colors" [dataLabels]="basicColumnChart.dataLabels"
              [plotOptions]="basicColumnChart.plotOptions" [yaxis]="basicColumnChart.yaxis" [legend]="basicColumnChart.legend"
              [fill]="basicColumnChart.fill" [stroke]="basicColumnChart.stroke" [tooltip]="basicColumnChart.tooltip"
              [xaxis]="basicColumnChart.xaxis"></apx-chart>
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Audiences Sessions by Country</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <span class="fw-semibold text-uppercase fs-12">Sort by: </span><span class="text-muted">Current Week<i class="mdi mdi-chevron-down ms-1"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Today</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Week</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                            <a class="dropdown-item" href="javascript:void(0);">Current Year</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->
            <div class="card-body p-0">
                <div class="mb-n2">
                    <apx-chart [series]="basicHeatmapChart.series" [chart]="basicHeatmapChart.chart"
                        [dataLabels]="basicHeatmapChart.dataLabels" [legend]="basicHeatmapChart.legend"
                        [colors]="basicHeatmapChart.colors" [tooltip]="basicHeatmapChart.tooltip"></apx-chart>
                </div>
            </div><!-- end cardbody -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-4">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Users by Device</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                           <span class="text-muted fs-16"><i class="mdi mdi-dots-vertical align-middle"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Today</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Week</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                            <a class="dropdown-item" href="javascript:void(0);">Current Year</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->
            <div class="card-body">
                <apx-chart [series]="simpleDonutChart.series" [labels]="simpleDonutChart.labels"
                        [chart]="simpleDonutChart.chart" [plotOptions]="simpleDonutChart.plotOptions"
                        [dataLabels]="simpleDonutChart.dataLabels" [legend]="simpleDonutChart.legend"
                        [stroke]="simpleDonutChart.stroke" [yaxis]="simpleDonutChart.yaxis"
                        [colors]="simpleDonutChart.colors"></apx-chart>

                <div class="table-responsive mt-3">
                    <table class="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                        <tbody class="border-0">
                            <tr>
                                <td><h4 class="text-truncate fs-14 fs-medium mb-0"><i class="ri-stop-fill align-middle fs-18 text-primary me-2"></i>Desktop Users</h4></td>
                                <td><p class="text-muted mb-0"><i-feather name="users" class="me-2 icon-sm"></i-feather>78.56k</p></td>
                                <td class="text-end"><p class="text-success fw-medium fs-12 mb-0"><i class="ri-arrow-up-s-fill fs-5 align-middle"></i>2.08%</p></td>
                            </tr>
                            <tr>
                                <td><h4 class="text-truncate fs-14 fs-medium mb-0"><i class="ri-stop-fill align-middle fs-18 text-warning me-2"></i>Mobile Users</h4></td>
                                <td><p class="text-muted mb-0"><i-feather name="users" class="me-2 icon-sm"></i-feather>105.02k</p></td>
                                <td class="text-end"><p class="text-danger fw-medium fs-12 mb-0"><i class="ri-arrow-down-s-fill fs-5 align-middle"></i>10.52%</p></td>
                            </tr>
                            <tr>
                                <td><h4 class="text-truncate fs-14 fs-medium mb-0"><i class="ri-stop-fill align-middle fs-18 text-info me-2"></i>Tablet Users</h4></td>
                                <td><p class="text-muted mb-0"><i-feather name="users" class="me-2 icon-sm"></i-feather>42.89k</p></td>
                                <td class="text-end"><p class="text-danger fw-medium fs-12 mb-0"><i class="ri-arrow-down-s-fill fs-5 align-middle"></i>7.36%</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-4 col-md-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Top Referrals Pages</h4>
                <div class="flex-shrink-0">
                    <button type="button" class="btn btn-soft-primary btn-sm">
                        Export Report
                    </button>
                </div>
            </div>

            <div class="card-body">

                <div class="row align-items-center">
                    <div class="col-6">
                        <h6 class="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">Total Referrals Page</h6>
                        <h4 class="fs- mb-0">725,800</h4>
                        <p class="mb-0 mt-2 text-muted"><span class="badge badge-soft-success mb-0">
                            <i class="ri-arrow-up-line align-middle"></i> 15.72 %
                        </span> vs. previous month</p>
                    </div><!-- end col -->
                    <div class="col-6">
                        <div class="text-center">
                            <img src="assets/images/illustrator-1.png" class="img-fluid" alt="">
                        </div>
                    </div><!-- end col -->
                </div><!-- end row -->
                <div class="mt-3 pt-2">
                    <div class="progress progress-lg rounded-pill">
                        <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-info" role="progressbar" style="width: 18%" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-success" role="progressbar" style="width: 22%" aria-valuenow="22" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 16%" aria-valuenow="16" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-danger" role="progressbar" style="width: 19%" aria-valuenow="19" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div><!-- end -->

                <div class="mt-3 pt-2">
                    <div class="d-flex mb-2">
                        <div class="flex-grow-1">
                            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-primary me-2"></i>www.google.com</p>
                        </div>
                        <div class="flex-shrink-0">
                            <p class="mb-0">24.58%</p>
                        </div>
                    </div><!-- end -->
                    <div class="d-flex mb-2">
                        <div class="flex-grow-1">
                            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-info me-2"></i>www.youtube.com</p>
                        </div>
                        <div class="flex-shrink-0">
                            <p class="mb-0">17.51%</p>
                        </div>
                    </div><!-- end -->
                    <div class="d-flex mb-2">
                        <div class="flex-grow-1">
                            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-success me-2"></i>www.meta.com</p>
                        </div>
                        <div class="flex-shrink-0">
                            <p class="mb-0">23.05%</p>
                        </div>
                    </div><!-- end -->
                    <div class="d-flex mb-2">
                        <div class="flex-grow-1">
                            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-warning me-2"></i>www.medium.com</p>
                        </div>
                        <div class="flex-shrink-0">
                            <p class="mb-0">12.22%</p>
                        </div>
                    </div><!-- end -->
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-danger me-2"></i>Other</p>
                        </div>
                        <div class="flex-shrink-0">
                            <p class="mb-0">17.58%</p>
                        </div>
                    </div><!-- end -->
                </div><!-- end -->

                <div class="mt-2 text-center">
                    <a href="javascript:void(0);" class="text-muted text-decoration-underline">Show All</a>
                </div>

            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-4 col-md-6">
        <div class="card card-height-100">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Top Pages</h4>
                <div class="flex-shrink-0">
                    <div class="dropdown card-header-dropdown" ngbDropdown>
                        <a class="text-reset dropdown-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                           <span class="text-muted fs-16"><i class="mdi mdi-dots-vertical align-middle"></i></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Today</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Week</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                            <a class="dropdown-item" href="javascript:void(0);">Current Year</a>
                        </div>
                    </div>
                </div>
            </div><!-- end card header -->
            <div class="card-body">
                <div class="table-responsive table-card">
                    <app-top-pages [TopPages]="TopPages"></app-top-pages>
                </div><!-- end -->
            </div><!-- end cardbody -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->
