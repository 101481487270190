import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResponseService } from '../../models/response-service.model';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NewsService {


constructor(private http: HttpClient) { }

public news_watched(user_id: number){
    const url = `${environment.api}news/${user_id}/news_watched`;
    return this.http.get<ResponseService>(url);
  }

  async showNews(userId: number){
    const { value: accept } = await Swal.fire({
        padding:'1em',
        scrollbarPadding: false,
        width: 800,
        input: 'checkbox',
        inputValue: 0,
        customClass: {
            input: 'lol',
          },
        inputPlaceholder:
            'Don\'t show this anymore.',
        showCloseButton: true,
        html:'<img src="assets/images/lead_gen_workflow.png" width="700px"/>',
        background: '#EFE6D9',
        backdrop: `rgba(0,0,0,0.7)`
      })

      if(accept === 1){
        this.dontShowForthisUser(userId);
      }
  }

  dontShowForthisUser(userId: number){
    this.news_watched(userId).subscribe();
  }

}
