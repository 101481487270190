import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-success-msg',
  templateUrl: './success-msg.component.html',
  styleUrls: ['./success-msg.component.scss']
})
export class SuccessMsgComponent implements OnInit {
  @Input() msg:string='';
  message:string="";
  constructor() { }

  ngOnInit(): void {
    this.message=this.msg;
  }

}
