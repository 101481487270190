<div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
    <div class="chat-leftsidebar">
        <div class="px-4 pt-4 mb-4">
            <div class="d-flex align-items-start">
                <div class="flex-grow-1">
                    <h5 class="mb-4">Chats</h5>
                </div>
                <div class="flex-shrink-0">
                    <div data-bs-toggle="tooltip" data-bs-trigger="hover" ngbTooltip="Add Contact" placement="bottom">

                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-soft-success btn-sm shadow-none">
                            <i class="ri-add-line align-bottom"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="search-box">
                <input type="text" class="form-control bg-light border-light" placeholder="Search here...">
                <i class="ri-search-2-line search-icon"></i>
            </div>

        </div> <!-- .p-4 -->

        <ngx-simplebar class="chat-room-list">

            <div class="d-flex align-items-center px-4 mb-2">
                <div class="flex-grow-1">
                    <h4 class="mb-0 fs-11 text-muted text-uppercase">Direct Messages</h4>
                </div>
                <div class="flex-shrink-0">
                    <div data-bs-toggle="tooltip" data-bs-trigger="hover" ngbTooltip="New Message" placement="bottom">

                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-soft-success btn-sm shadow-none">
                            <i class="ri-add-line align-bottom"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="chat-message-list">

                <ul class="list-unstyled chat-list chat-user-list" id="userList">
                    <li *ngFor="let data of chatData" [ngClass]="{'active': data.name == username}">
                        <a href="javascript: void(0);" (click)="chatUsername(data.name, data.image, data.status)">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 chat-user-img {{data.status}} align-self-center me-2 ms-0">
                                    <div class="avatar-xxs" *ngIf="data.image">
                                        <img src="{{data.image}}" class="rounded-circle img-fluid userprofile" alt="">
                                    </div>
                                    <div class="avatar-xxs" *ngIf="!data.image">
                                        <span class="avatar-title rounded-circle bg-danger userprofile">{{ data.name.charAt(0) }}</span>
                                      </div>
                                    <span class="user-status"></span>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-truncate mb-0">{{data.name}}</p>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="d-flex align-items-center px-4 mt-4 pt-2 mb-2">
                <div class="flex-grow-1">
                    <h4 class="mb-0 fs-11 text-muted text-uppercase">Channels</h4>
                </div>
                <div class="flex-shrink-0">
                    <div data-bs-toggle="tooltip" data-bs-trigger="hover" ngbTooltip="Create group" placement="bottom">

                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-soft-success btn-sm shadow-none">
                            <i class="ri-add-line align-bottom"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="chat-message-list">

                <ul class="list-unstyled chat-list chat-user-list mb-0" id="channelList">
                    <li *ngFor="let data of groupData">
                        <a href="javascript: void(0);" class="unread-msg-user">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                    <div class="avatar-xxs">
                                        <div class="avatar-title bg-light rounded-circle text-body">
                                            #
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-truncate mb-0">{{data.name}}</p>
                                </div>
                                <div class="flex-shrink-0">
                                    <span class="badge badge-soft-dark rounded p-1">{{data.unread}}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- End chat-message-list -->
        </ngx-simplebar>

    </div>
    <!-- end chat leftsidebar -->
    <!-- Start User chat -->
    <div class="user-chat w-100 overflow-hidden">

        <div class="chat-content d-lg-flex">
            <!-- start chat conversation section -->
            <div class="w-100 overflow-hidden position-relative">
                <!-- conversation user -->
                <div class="position-relative">
                    <div class="p-3 user-chat-topbar">
                        <div class="row align-items-center">
                            <div class="col-sm-4 col-8">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 d-block d-lg-none me-3">
                                        <a href="javascript: void(0);" class="user-chat-remove fs-18 p-1" (click)="SidebarHide()"><i class="ri-arrow-left-s-line align-bottom"></i></a>
                                    </div>
                                    <div class="flex-grow-1 overflow-hidden">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0 chat-user-img user-own-img align-self-center me-3 ms-0" [ngClass]="{'away': isStatus == 'away', 'online': isStatus == 'online'}">
                                                <img src="{{isProfile}}" class="rounded-circle avatar-xs" alt="">
                                                <span class="user-status"></span>
                                            </div>
                                            <div class="flex-grow-1 overflow-hidden">
                                                <h5 class="text-truncate mb-0 fs-16"><a class="text-reset username" data-bs-toggle="offcanvas" href="javascript:void(0);" aria-controls="userProfileCanvasExample" (click)="onChatInfoClicked()">{{username}}</a></h5>
                                                <p class="text-truncate text-muted fs-14 mb-0 userStatus"><small>{{ isStatus | titlecase }}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-8 col-4">
                                <ul class="list-inline user-chat-nav text-end mb-0">
                                    <li class="list-inline-item m-0">
                                        <div class="dropdown" ngbDropdown>
                                            <button class="btn btn-ghost-secondary btn-icon shadow-none arrow-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                <i-feather name="search" class="icon-sm"></i-feather>
                                            </button>
                                            <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg" ngbDropdownMenu>
                                                <div class="p-2">
                                                    <div class="search-box">
                                                        <input type="text" class="form-control bg-light border-light" placeholder="Search here..." onkeyup="searchMessages()" id="searchMessage">
                                                        <i class="ri-search-2-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="list-inline-item d-none d-lg-inline-block m-0">
                                        <button type="button" class="btn btn-ghost-secondary btn-icon shadow-none" data-bs-toggle="offcanvas" data-bs-target="#userProfileCanvasExample" aria-controls="userProfileCanvasExample" (click)="onChatInfoClicked()">
                                            <i-feather name="info" class="icon-sm"></i-feather>
                                        </button>
                                    </li>

                                    <li class="list-inline-item m-0">
                                        <div class="dropdown" ngbDropdown>
                                            <button class="btn btn-ghost-secondary btn-icon shadow-none arrow-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                              <i-feather name="more-vertical" class="icon-sm"></i-feather>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                <a class="dropdown-item d-block d-lg-none user-profile-show" href="javascript:void(0);"><i class="ri-user-2-fill align-bottom text-muted me-2"></i> View Profile</a>
                                                <a class="dropdown-item" href="javascript:void(0);"><i class="ri-inbox-archive-line align-bottom text-muted me-2"></i> Archive</a>
                                                <a class="dropdown-item" href="javascript:void(0);"><i class="ri-mic-off-line align-bottom text-muted me-2"></i> Muted</a>
                                                <a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line align-bottom text-muted me-2"></i> Delete</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <!-- end chat user head -->

                    <div class="position-relative" id="users-chat">
                    <ngx-simplebar class="chat-conversation p-3 p-lg-4" #scrollRef>
                        <ul class="list-unstyled chat-conversation-list" id="users-conversation">
                            <li class="chat-list left" *ngFor="let data of chatMessagesData" [ngClass]="{ 'right': data.align === 'right' }">
                                <div class="conversation-list">
                                    <div class="chat-avatar" *ngIf="data.profile">
                                        <img src="{{data.profile}}" alt="" >
                                    </div>
                                    <div class="user-chat-content">
                                        <div class="ctext-wrap">
                                            <div class="ctext-wrap-content">
                                                <p class="mb-0 ctext-content">{{data.message}}</p>
                                                <ul class="list-inline message-img mt-3  mb-0" *ngIf="data.image">
                                                    <li class="list-inline-item message-img-list" *ngFor="let images of data.image; let i=index">
                                                        <a class="d-inline-block m-1">
                                                            <img src="{{images}}" alt="" class="rounded img-thumbnail" (click)="open(i)">
                                                        </a>
                                                    </li>
                                                  </ul>
                                            </div>
                                            <div class="dropdown align-self-start message-box-drop" ngbDropdown>
                                                <a class="dropdown-toggle" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                    <i class="ri-more-2-fill"></i>
                                                </a>
                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                    <a class="dropdown-item reply-message" href="javascript:void(0);"><i class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                    <a class="dropdown-item copy-message" href="javascript:void(0);"><i class="ri-file-copy-line me-2 text-muted align-bottom"></i>Copy</a>
                                                    <a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                    <a class="dropdown-item delete-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conversation-name"><small class="text-muted time">{{data.time}}</small> <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span></div>
                                    </div>
                                </div>
                            </li>
                            <!-- chat-list -->
                        </ul>
                        <!-- end chat-conversation-list -->
                    </ngx-simplebar>
                </div>

                    <!-- end chat-conversation -->

                    <div class="chat-input-section p-3 p-lg-4">

                        <form (ngSubmit)="messageSave()" [formGroup]="formData" id="chatinput-form" enctype="multipart/form-data" >
                            <div class="row g-0 align-items-center">
                                <div class="col-auto">
                                    <div class="chat-input-links me-2">
                                        <div class="links-list-item">
                                            <button type="button" class="btn btn-link text-decoration-none emoji-btn" id="emoji-btn">
                                                <i class="bx bx-smile align-middle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="col">

                                    <div *ngIf="submitted && form['message'].errors" class="chat-input-feedback">
                                        <span *ngIf="form['message'].errors['required']">Please Enter a Message</span>
                                    </div>
                                    <input type="text" class="form-control chat-input bg-light border-light" id="chat-input" placeholder="Type your message..." autocomplete="off" formControlName="message" [ngClass]="{ 'is-invalid': submitted && form['message'].errors }">
                                </div>
                                <div class="col-auto">
                                    <div class="chat-input-links ms-2">
                                        <div class="links-list-item">
                                            <button type="submit" class="btn btn-primary chat-send waves-effect waves-light shadow">
                                                <i class="ri-send-plane-2-fill align-bottom"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div class="replyCard">
                        <div class="card mb-0">
                            <div class="card-body py-3">
                                <div class="replymessage-block mb-0 d-flex align-items-start">
                                    <div class="flex-grow-1">
                                        <h5 class="conversation-name"></h5>
                                        <p class="mb-0"></p>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <button type="button" id="close_toggle" class="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none">
                                            <i class="bx bx-x align-middle"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Chat Info Sidebar -->
<div class="offcanvas offcanvas-end border-0" tabindex="-1" id="userProfileCanvasExample">
  <!--end offcanvas-header-->
  <div class="offcanvas-body profile-offcanvas p-0">
      <div class="team-cover">
          <img src="assets/images/small/img-9.jpg" alt="" class="img-fluid" />
      </div>
      <div class="p-1 pb-4 pt-0">
          <div class="team-settings">
              <div class="row g-0">
                  <div class="col">
                      <div class="btn nav-btn">
                          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" (click)="hide();"></button>
                      </div>
                  </div>
                  <div class="col-auto">
                      <div class="user-chat-nav d-flex">
                          <button type="button" class="btn nav-btn favourite-btn active">
                              <i class="ri-star-fill"></i>
                          </button>

                          <div class="dropdown" ngbDropdown>
                              <a class="btn nav-btn arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                  <i class="ri-more-2-fill"></i>
                              </a>
                              <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                  <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-inbox-archive-line align-bottom text-muted me-2"></i>Archive</a></li>
                                  <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-mic-off-line align-bottom text-muted me-2"></i>Muted</a></li>
                                  <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>Delete</a></li>
                              </ul>
                          </div>
                      </div>

                  </div>
              </div>
          </div><!--end col-->
      </div>
      <div class="p-3 text-center">
          <img src="assets/images/users/avatar-2.jpg" alt="" class="avatar-lg img-thumbnail rounded-circle mx-auto">
          <div class="mt-3">
              <h5 class="fs-16 mb-1"><a href="javascript:void(0);" class="link-primary username">Lisa Parker</a></h5>
              <p class="text-muted"><i class="ri-checkbox-blank-circle-fill me-1 align-bottom text-success"></i>Online</p>
          </div>

          <div class="d-flex gap-2 justify-content-center">
              <button type="button" class="btn avatar-xs p-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Message">
                  <span class="avatar-title rounded bg-light text-body">
                      <i class="ri-question-answer-line"></i>
                  </span>
              </button>

              <button type="button" class="btn avatar-xs p-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Favourite">
                  <span class="avatar-title rounded bg-light text-body">
                      <i class="ri-star-line"></i>
                  </span>
              </button>

              <button type="button" class="btn avatar-xs p-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Phone">
                  <span class="avatar-title rounded bg-light text-body">
                      <i class="ri-phone-line"></i>
                  </span>
              </button>

              <div class="dropdown" ngbDropdown>
                  <button class="btn avatar-xs p-0 arrow-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                      <span class="avatar-title bg-light text-body rounded">
                          <i class="ri-more-fill"></i>
                      </span>
                  </button>

                  <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-inbox-archive-line align-bottom text-muted me-2"></i>Archive</a></li>
                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-mic-off-line align-bottom text-muted me-2"></i>Muted</a></li>
                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>Delete</a></li>
                  </ul>
              </div>
          </div>
      </div>

      <div class="border-top border-top-dashed p-3">
          <h5 class="fs-15 mb-3">Personal Details</h5>
          <div class="mb-3">
              <p class="text-muted text-uppercase fw-medium fs-12 mb-1">Number</p>
              <h6>+(256) 2451 8974</h6>
          </div>
          <div class="mb-3">
              <p class="text-muted text-uppercase fw-medium fs-12 mb-1">Email</p>
              <h6>lisaparker@gmail.com</h6>
          </div>
          <div>
              <p class="text-muted text-uppercase fw-medium fs-12 mb-1">Location</p>
              <h6 class="mb-0">California, USA</h6>
          </div>
      </div>

      <div class="border-top border-top-dashed p-3">
          <h5 class="fs-15 mb-3">Attached Files</h5>

          <div class="vstack gap-2">
              <div class="border rounded border-dashed p-2">
                  <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                          <div class="avatar-xs">
                              <div class="avatar-title bg-light text-secondary rounded fs-20">
                                  <i class="ri-folder-zip-line"></i>
                              </div>
                          </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                          <h5 class="fs-13 mb-1"><a href="javascript:void(0);" class="text-body text-truncate d-block">App pages.zip</a></h5>
                          <div class="text-muted">2.2MB</div>
                      </div>
                      <div class="flex-shrink-0 ms-2">
                          <div class="d-flex gap-1">
                              <button type="button" class="btn btn-icon text-muted btn-sm fs-18"><i class="ri-download-2-line"></i></button>
                              <div class="dropdown" ngbDropdown>
                                  <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                      <i class="ri-more-fill"></i>
                                  </button>
                                  <ul class="dropdown-menu" ngbDropdownMenu>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line align-bottom me-2 text-muted"></i> Share</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line align-bottom me-2 text-muted"></i> Bookmark</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-line align-bottom me-2 text-muted"></i> Delete</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="border rounded border-dashed p-2">
                  <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                          <div class="avatar-xs">
                              <div class="avatar-title bg-light text-secondary rounded fs-20">
                                  <i class="ri-file-ppt-2-line"></i>
                              </div>
                          </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                          <h5 class="fs-13 mb-1"><a href="javascript:void(0);" class="text-body text-truncate d-block">Velzon admin.ppt</a></h5>
                          <div class="text-muted">2.4MB</div>
                      </div>
                      <div class="flex-shrink-0 ms-2">
                          <div class="d-flex gap-1">
                              <button type="button" class="btn btn-icon text-muted btn-sm fs-18"><i class="ri-download-2-line"></i></button>
                              <div class="dropdown" ngbDropdown>
                                  <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                      <i class="ri-more-fill"></i>
                                  </button>
                                  <ul class="dropdown-menu" ngbDropdownMenu>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line align-bottom me-2 text-muted"></i> Share</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line align-bottom me-2 text-muted"></i> Bookmark</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-line align-bottom me-2 text-muted"></i> Delete</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="border rounded border-dashed p-2">
                  <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                          <div class="avatar-xs">
                              <div class="avatar-title bg-light text-secondary rounded fs-20">
                                  <i class="ri-folder-zip-line"></i>
                              </div>
                          </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                          <h5 class="fs-13 mb-1"><a href="javascript:void(0);" class="text-body text-truncate d-block">Images.zip</a></h5>
                          <div class="text-muted">1.2MB</div>
                      </div>
                      <div class="flex-shrink-0 ms-2">
                          <div class="d-flex gap-1">
                              <button type="button" class="btn btn-icon text-muted btn-sm fs-18"><i class="ri-download-2-line"></i></button>
                              <div class="dropdown" ngbDropdown>
                                  <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                      <i class="ri-more-fill"></i>
                                  </button>
                                  <ul class="dropdown-menu" ngbDropdownMenu>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line align-bottom me-2 text-muted"></i> Share</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line align-bottom me-2 text-muted"></i> Bookmark</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-line align-bottom me-2 text-muted"></i> Delete</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="border rounded border-dashed p-2">
                  <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 me-3">
                          <div class="avatar-xs">
                              <div class="avatar-title bg-light text-secondary rounded fs-20">
                                  <i class="ri-image-2-line"></i>
                              </div>
                          </div>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                          <h5 class="fs-13 mb-1"><a href="javascript:void(0);" class="text-body text-truncate d-block">bg-pattern.png</a></h5>
                          <div class="text-muted">1.1MB</div>
                      </div>
                      <div class="flex-shrink-0 ms-2">
                          <div class="d-flex gap-1">
                              <button type="button" class="btn btn-icon text-muted btn-sm fs-18"><i class="ri-download-2-line"></i></button>
                              <div class="dropdown" ngbDropdown>
                                  <button class="btn btn-icon text-muted btn-sm fs-18 dropdown arrow-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                      <i class="ri-more-fill"></i>
                                  </button>
                                  <ul class="dropdown-menu" ngbDropdownMenu>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-share-line align-bottom me-2 text-muted"></i> Share</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-bookmark-line align-bottom me-2 text-muted"></i> Bookmark</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-line align-bottom me-2 text-muted"></i> Delete</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="text-center mt-2">
                  <button type="button" class="btn btn-danger">Load more <i class="ri-arrow-right-fill align-bottom ms-1"></i></button>
              </div>
          </div>
      </div>
  </div><!--end offcanvas-body-->
</div><!--end offcanvas-->
