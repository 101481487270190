import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JwtService {

    constructor(private http: HttpClient) { }


    testJWT() {
        let url = 'http://localhost/decode/jwt';
        return this.http.get(url);
    }

}


