<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Sellers" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->


<div class="card">
  <div class="card-header border-0 rounded">
      <div class="row g-2">
          <div class="col-xl-3">
              <div class="search-box">
                  <input type="text" class="form-control search" placeholder="Search for sellers & owner name or something...">  <i class="ri-search-line search-icon"></i>
              </div>
          </div><!--end col-->
          <div class="col-xl-2 ms-auto">
              <div>
                  <select class="form-control" data-choices data-choices-search-false>
                      <option value="">Select Categories</option>
                      <option value="All">All</option>
                      <option value="Retailer">Retailer</option>
                      <option value="Health & Medicine">Health & Medicine</option>
                      <option value="Manufacturer">Manufacturer</option>
                      <option value="Food Service">Food Service</option>
                      <option value="Computers & Electronics">Computers & Electronics</option>
                  </select>
              </div>
          </div><!--end col-->
          <div class="col-lg-auto">
              <div class="hstack gap-2">
                  <button type="button" class="btn btn-danger"><i class="ri-equalizer-fill me-1 align-bottom"></i> Filters</button>
                  <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#addSeller" (click)="openModal(content)"><i class="ri-add-fill me-1 align-bottom"></i> Add Seller</button>
              </div>
          </div><!--end col-->
      </div><!--end row-->
  </div>
</div>

<div class="row mt-4">

  <div class="col-xl-3 col-lg-6" >
    <div class="card ribbon-box right overflow-hidden">
        <div class="card-body text-center p-4">
            <div class="ribbon ribbon-info ribbon-shape trending-ribbon"><i class="ri-flashlight-fill text-white align-bottom"></i> <span class="trending-ribbon-text">Trending</span></div>
            <img src="assets/images/companies/img-1.png" alt="" height="45">
            <h5 class="mb-1 mt-4"><a routerLink="/ecommerce/seller-details" class="link-primary">Force Medicines</a></h5>
            <p class="text-muted mb-4">David Marshall</p>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <apx-chart [series]="BitcoinChart.series" [chart]="BitcoinChart.chart" [dataLabels]="BitcoinChart.dataLabels"
                                [stroke]="BitcoinChart.stroke" [fill]="BitcoinChart.fill" [colors]="BitcoinChart.colors"></apx-chart>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 border-end-dashed border-end">
                    <h5>452</h5>
                    <span class="text-muted">Item Stock</span>
                </div>
                <div class="col-lg-6">
                    <h5>$45,415</h5>
                    <span class="text-muted">Wallet Balance</span>
                </div>
            </div>
           <div class="mt-4">
                <a routerLink="/ecommerce/seller-details" class="btn btn-light w-100">View Details</a>
           </div>
        </div>
    </div>
  </div><!--end col-->

  <div class="col-xl-3 col-lg-6">
    <div class="card">
        <div class="card-body text-center p-4">
            <img src="assets/images/companies/img-2.png" alt="" height="45">
            <h5 class="mb-1 mt-4"><a routerLink="/ecommerce/seller-details" class="link-primary">Micro Design</a></h5>
            <p class="text-muted mb-4">Katia Stapleton</p>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                  <apx-chart [series]="MicroChart.series" [chart]="MicroChart.chart" [dataLabels]="MicroChart.dataLabels"
                  [stroke]="MicroChart.stroke" [fill]="MicroChart.fill" [colors]="MicroChart.colors"></apx-chart>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 border-end-dashed border-end">
                    <h5>784</h5>
                    <span class="text-muted">Item Stock</span>
                </div>
                <div class="col-lg-6">
                    <h5>$97,642</h5>
                    <span class="text-muted">Wallet Balance</span>
                </div>
            </div>
           <div class="mt-4">
                <a routerLink="/ecommerce/seller-details" class="btn btn-light w-100">View Details</a>
           </div>
        </div>
    </div>
  </div><!--end col-->

  <div class="col-xl-3 col-lg-6">
    <div class="card">
        <div class="card-body text-center p-4">
            <img src="assets/images/companies/img-3.png" alt="" height="45">
            <h5 class="mb-1 mt-4"><a routerLink="/ecommerce/seller-details" class="link-primary">Nesta Technologies</a></h5>
            <p class="text-muted mb-4">Harley Fuller</p>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                  <apx-chart [series]="NestaChart.series" [chart]="NestaChart.chart" [dataLabels]="NestaChart.dataLabels"
                  [stroke]="NestaChart.stroke" [fill]="NestaChart.fill" [colors]="NestaChart.colors"></apx-chart>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 border-end-dashed border-end">
                    <h5>320</h5>
                    <span class="text-muted">Item Stock</span>
                </div>
                <div class="col-lg-6">
                    <h5>$27,102</h5>
                    <span class="text-muted">Wallet Balance</span>
                </div>
            </div>
           <div class="mt-4">
                <a routerLink="/ecommerce/seller-details" class="btn btn-light w-100">View Details</a>
           </div>
        </div>
    </div>
</div><!--end col-->

<div class="col-xl-3 col-lg-6">
  <div class="card ribbon-box right overflow-hidden">
      <div class="card-body text-center p-4">
          <div class="ribbon ribbon-info ribbon-shape trending-ribbon"><i class="ri-flashlight-fill text-white align-bottom"></i> <span class="trending-ribbon-text">Trending</span></div>
          <img src="assets/images/companies/img-4.png" alt="" height="45">
          <h5 class="mb-1 mt-4"><a routerLink="/ecommerce/seller-details" class="link-primary">iTest Factory</a></h5>
          <p class="text-muted mb-4">Oliver Tyler</p>
          <div class="row justify-content-center">
              <div class="col-lg-8">
                <apx-chart [series]="iTestChart.series" [chart]="iTestChart.chart" [dataLabels]="iTestChart.dataLabels"
                [stroke]="iTestChart.stroke" [fill]="iTestChart.fill" [colors]="iTestChart.colors"></apx-chart>
              </div>
          </div>
          <div class="row mt-4">
              <div class="col-lg-6 border-end-dashed border-end">
                  <h5>159</h5>
                  <span class="text-muted">Item Stock</span>
              </div>
              <div class="col-lg-6">
                  <h5>$14,933</h5>
                  <span class="text-muted">Wallet Balance</span>
              </div>
          </div>
         <div class="mt-4">
              <a routerLink="/ecommerce/seller-details" class="btn btn-light w-100">View Details</a>
         </div>
      </div>
  </div>
</div><!--end col-->

<div class="col-xl-3 col-lg-6">
  <div class="card">
      <div class="card-body text-center p-4">
          <img src="assets/images/companies/img-5.png" alt="" height="45">
          <h5 class="mb-1 mt-4"><a routerLink="/ecommerce/seller-details" class="link-primary">Meta4Systems</a></h5>
          <p class="text-muted mb-4">Zoe Dennis</p>
          <div class="row justify-content-center">
              <div class="col-lg-8">
                <apx-chart [series]="MetaChart.series" [chart]="MetaChart.chart" [dataLabels]="MetaChart.dataLabels"
                [stroke]="MetaChart.stroke" [fill]="MetaChart.fill" [colors]="MetaChart.colors"></apx-chart>
              </div>
          </div>
          <div class="row mt-4">
              <div class="col-lg-6 border-end-dashed border-end">
                  <h5>363</h5>
                  <span class="text-muted">Item Stock</span>
              </div>
              <div class="col-lg-6">
                  <h5>$73,426</h5>
                  <span class="text-muted">Wallet Balance</span>
              </div>
          </div>
         <div class="mt-4">
              <a routerLink="/ecommerce/seller-details" class="btn btn-light w-100">View Details</a>
         </div>
      </div>
  </div>
</div><!--end col-->

<div class="col-xl-3 col-lg-6">
  <div class="card ribbon-box right overflow-hidden">
      <div class="card-body text-center p-4">
          <div class="ribbon ribbon-info ribbon-shape trending-ribbon"><i class="ri-flashlight-fill text-white align-bottom"></i> <span class="trending-ribbon-text">Trending</span></div>
          <img src="assets/images/companies/img-6.png" alt="" height="45">
          <h5 class="mb-1 mt-4"><a routerLink="/ecommerce/seller-details" class="link-primary">Digitech Galaxy</a></h5>
          <p class="text-muted mb-4">John Roberts</p>
          <div class="row justify-content-center">
              <div class="col-lg-8">
                <apx-chart [series]="DigitechChart.series" [chart]="DigitechChart.chart" [dataLabels]="DigitechChart.dataLabels"
                [stroke]="DigitechChart.stroke" [fill]="DigitechChart.fill" [colors]="DigitechChart.colors"></apx-chart>
              </div>
          </div>
          <div class="row mt-4">
              <div class="col-lg-6 border-end-dashed border-end">
                  <h5>412</h5>
                  <span class="text-muted">Item Stock</span>
              </div>
              <div class="col-lg-6">
                  <h5>$34,241</h5>
                  <span class="text-muted">Wallet Balance</span>
              </div>
          </div>
         <div class="mt-4">
              <a routerLink="/ecommerce/seller-details" class="btn btn-light w-100">View Details</a>
         </div>
      </div>
  </div>
</div><!--end col-->

<div class="col-xl-3 col-lg-6">
  <div class="card ribbon-box right overflow-hidden">
      <div class="card-body text-center p-4">
          <div class="ribbon ribbon-info ribbon-shape trending-ribbon"><i class="ri-flashlight-fill text-white align-bottom"></i> <span class="trending-ribbon-text">Trending</span></div>
          <img src="assets/images/companies/img-7.png" alt="" height="45">
          <h5 class="mb-1 mt-4"><a routerLink="/ecommerce/seller-details" class="link-primary">Syntyce Solutions</a></h5>
          <p class="text-muted mb-4">Demi Allen</p>
          <div class="row justify-content-center">
              <div class="col-lg-8">
                <apx-chart [series]="SyntyceChart.series" [chart]="SyntyceChart.chart" [dataLabels]="SyntyceChart.dataLabels"
                [stroke]="SyntyceChart.stroke" [fill]="SyntyceChart.fill" [colors]="SyntyceChart.colors"></apx-chart>
              </div>
          </div>
          <div class="row mt-4">
              <div class="col-lg-6 border-end-dashed border-end">
                  <h5>945</h5>
                  <span class="text-muted">Item Stock</span>
              </div>
              <div class="col-lg-6">
                  <h5>$17,200</h5>
                  <span class="text-muted">Wallet Balance</span>
              </div>
          </div>
         <div class="mt-4">
              <a routerLink="/ecommerce/seller-details" class="btn btn-light w-100">View Details</a>
         </div>
      </div>
  </div>
</div><!--end col-->

<div class="col-xl-3 col-lg-6">
  <div class="card">
      <div class="card-body text-center p-4">
          <img src="assets/images/companies/img-8.png" alt="" height="45">
          <h5 class="mb-1 mt-4"><a routerLink="/ecommerce/seller-details" class="link-primary">Zoetic Fashion</a></h5>
          <p class="text-muted mb-4">James Bowen</p>
          <div class="row justify-content-center">
              <div class="col-lg-8">
                <apx-chart [series]="ZoeticChart.series" [chart]="ZoeticChart.chart" [dataLabels]="ZoeticChart.dataLabels"
                [stroke]="ZoeticChart.stroke" [fill]="ZoeticChart.fill" [colors]="ZoeticChart.colors"></apx-chart>
              </div>
          </div>
          <div class="row mt-4">
              <div class="col-lg-6 border-end-dashed border-end">
                  <h5>784</h5>
                  <span class="text-muted">Item Stock</span>
              </div>
              <div class="col-lg-6">
                  <h5>$97,642</h5>
                  <span class="text-muted">Wallet Balance</span>
              </div>
          </div>
         <div class="mt-4">
              <a routerLink="/ecommerce/seller-details" class="btn btn-light w-100">View Details</a>
         </div>
      </div>
  </div>
</div><!--end col-->

</div><!--end row-->

<div class="row g-0 text-center text-sm-start align-items-center mb-3">
  <div class="col-sm-6">
      <div>
          <p class="mb-sm-0">Showing 1 to 8 of 12 entries</p>
      </div>
  </div> <!-- end col -->
  <div class="col-sm-6">
      <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
          <li class="page-item disabled"> <a href="javascript:void(0);" class="page-link"><i class="mdi mdi-chevron-left"></i></a> </li>
          <li class="page-item active"> <a href="javascript:void(0);" class="page-link">1</a> </li>
          <li class="page-item "> <a href="javascript:void(0);" class="page-link">2</a> </li>
          <li class="page-item"> <a href="javascript:void(0);" class="page-link">3</a> </li>
          <li class="page-item"> <a href="javascript:void(0);" class="page-link">4</a> </li>
          <li class="page-item"> <a href="javascript:void(0);" class="page-link">5</a> </li>
          <li class="page-item"> <a href="javascript:void(0);" class="page-link"><i class="mdi mdi-chevron-right"></i></a> </li>
      </ul>
  </div><!-- end col -->
</div><!-- end row -->


<!-- Modal -->
<ng-template #content role="document" let-modal>
  <div class="modal-header">
      <h5 class="modal-title" id="addSellerLabel">Add Seller</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-content border-0 mt-3">
    <ul ngbNav #customNav="ngbNav" [activeId]="1"  class="nav nav-tabs nav-tabs-custom nav-success p-2 pb-0 bg-light" role="tablist">
      <li [ngbNavItem]="1" class="nav-item">
          <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            Personal Details
          </a>
          <ng-template ngbNavContent>
            <div class="tab-pane active" id="personalDetails" role="tabpanel">
              <form action="#">
                  <div class="row">
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="firstnameInput" class="form-label">First Name</label>
                              <input type="text" class="form-control" id="firstnameInput" placeholder="Enter your firstname">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="lastnameInput" class="form-label">Last Name</label>
                              <input type="text" class="form-control" id="lastnameInput" placeholder="Enter your lastname">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="contactnumberInput" class="form-label">Contact Number</label>
                              <input type="number" class="form-control" id="contactnumberInput" placeholder="Enter your number">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="phonenumberInput" class="form-label">Phone Number</label>
                              <input type="number" class="form-control" id="phonenumberInput" placeholder="Enter your number">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="emailidInput" class="form-label">Email</label>
                              <input type="email" class="form-control" id="emailidInput" placeholder="Enter your email">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="birthdayidInput" class="form-label">Date of Birth</label>
                              <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true" [convertModelValue]="true" placeholder="Enter your date of birth">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-4">
                          <div class="mb-3">
                              <label for="cityidInput" class="form-label">City</label>
                              <input type="text" class="form-control" id="cityidInput" placeholder="Enter your city">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-4">
                          <div class="mb-3">
                              <label for="countryidInput" class="form-label">Country</label>
                              <input type="text" class="form-control" id="countryidInput" placeholder="Enter your country">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-4">
                          <div class="mb-3">
                              <label for="zipcodeidInput" class="form-label">Zip Code</label>
                              <input type="text" class="form-control" id="zipcodeidInput" placeholder="Enter your zipcode">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-12">
                          <div class="mb-3">
                              <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Enter description"></textarea>
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-12">
                          <div class="hstack gap-2 justify-content-end">
                              <button class="btn btn-link link-success text-decoration-none fw-medium" data-bs-dismiss="modal" (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                              <button type="submit" class="btn btn-primary"><i class="ri-save-3-line align-bottom me-1"></i> Save</button>
                          </div>
                      </div><!--end col-->
                  </div><!--end row-->
              </form>
            </div>
          </ng-template>
      </li>
      <li [ngbNavItem]="2" class="nav-item">
          <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            Business Details
          </a>
          <ng-template ngbNavContent>
            <div class="tab-pane" id="businessDetails" role="tabpanel">
              <form action="#">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="mb-3">
                              <label for="companynameInput" class="form-label">Company Name</label>
                              <input type="text" class="form-control" id="companynameInput" placeholder="Enter your company name">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="choices-single-default" class="form-label">Company Type</label>
                              <select class="form-control" data-trigger name="choices-single-default" id="choices-single-default">
                                  <option value="">Select type</option>
                                  <option value="All" selected>All</option>
                                  <option value="Merchandising">Merchandising</option>
                                  <option value="Manufacturing">Manufacturing</option>
                                  <option value="Partnership">Partnership</option>
                                  <option value="Corporation">Corporation</option>
                              </select>
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="pancardInput" class="form-label">Pan Card Number</label>
                              <input type="text" class="form-control" id="pancardInput" placeholder="Enter your pan-card number">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-4">
                          <div class="mb-3">
                              <label for="websiteInput" class="form-label">Website</label>
                              <input type="url" class="form-control" id="websiteInput" placeholder="Enter your URL">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-4">
                          <div class="mb-3">
                              <label for="faxInput" class="form-label">Fax</label>
                              <input type="text" class="form-control" id="faxInput" placeholder="Enter your fax">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-4">
                          <div class="mb-3">
                              <label for="companyemailInput" class="form-label">Email</label>
                              <input type="email" class="form-control" id="companyemailInput" placeholder="Enter your email">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="worknumberInput" class="form-label">Number</label>
                              <input type="number" class="form-control" id="worknumberInput" placeholder="Enter your number">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-6">
                          <div class="mb-3">
                              <label for="companylogoInput" class="form-label">Company Logo</label>
                              <input type="file" class="form-control" id="companylogoInput">
                          </div>
                      </div><!--end col-->
                      <div class="col-lg-12">
                          <div class="hstack gap-2 justify-content-end">
                              <button class="btn btn-link link-success text-decoration-none fw-medium" data-bs-dismiss="modal" (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                              <button type="submit" class="btn btn-primary"><i class="ri-save-3-line align-bottom me-1"></i> Save</button>
                          </div>
                      </div><!--end col-->
                  </div><!--end row-->
              </form>
            </div>
          </ng-template>
      </li>
      <li [ngbNavItem]="3" class="nav-item">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
          Bank Details
        </a>
        <ng-template ngbNavContent>
          <div class="tab-pane" id="bankDetails" role="tabpanel">
            <form action="#">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="banknameInput" class="form-label">Bank Name</label>
                            <input type="text" class="form-control" id="banknameInput" placeholder="Enter your bank name">
                        </div>
                    </div><!--end col-->
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="branchInput" class="form-label">Branch</label>
                            <input type="text" class="form-control" id="branchInput" placeholder="Branch">
                        </div>
                    </div><!--end col-->
                    <div class="col-lg-12">
                        <div class="mb-3">
                            <label for="accountnameInput" class="form-label">Account Holder Name</label>
                            <input type="text" class="form-control" id="accountnameInput" placeholder="Enter account holder name">
                        </div>
                    </div><!--end col-->
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="accountnumberInput" class="form-label">Account Number</label>
                            <input type="number" class="form-control" id="accountnumberInput" placeholder="Enter account number">
                        </div>
                    </div><!--end col-->
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="ifscInput" class="form-label">IFSC</label>
                            <input type="number" class="form-control" id="ifscInput" placeholder="IFSC">
                        </div>
                    </div><!--end col-->
                    <div class="col-lg-12">
                        <div class="hstack gap-2 justify-content-end">
                            <button class="btn btn-link link-success text-decoration-none fw-medium" data-bs-dismiss="modal" (click)="modal.close('Close click')"><i class="ri-close-line me-1 align-middle"></i> Close</button>
                            <button type="submit" class="btn btn-primary"><i class="ri-save-3-line align-bottom me-1"></i> Save</button>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </form>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="modal-body">
    <div class="tab-content">
      <div [ngbNavOutlet]="customNav"></div>
    </div>
  </div>
</ng-template><!--end modal-->
