<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Product Details" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="row">
  <div class="col-lg-12">
      <div class="card">
          <div class="card-body">
              <div class="row gx-lg-5">
                  <div class="col-xl-4 col-md-8 mx-auto">
                      <div class="product-img-slider sticky-side-div">
                        <swiper [config]="config" >
                          <div class="swiper-slide" *ngFor="let i of productDetail[0].images">
                            <img src="{{i}}" alt="" class="img-fluid d-block" />
                          </div>
                        </swiper>
                        <div class="swiper-arrow">
                          <div class="swiper-button-prev" (click)="previousSlideComp()"></div>
                          <div class="swiper-button-next" (click)="nextSlideComp()"></div>
                        </div>
                      </div>
                  </div>
                  <!-- end col -->

                  <div class="col-xl-8">
                      <div class="mt-xl-0 mt-5">
                          <div class="d-flex">
                              <div class="flex-grow-1">
                                  <h4>{{productDetail[0].name}}</h4>
                                  <div class="hstack gap-3 flex-wrap">
                                      <div><a href="javascript:void(0);" class="text-primary d-block">{{productDetail[0].category}}</a></div>
                                      <div class="vr"></div>
                                      <div class="text-muted">Seller : <span class="text-body fw-medium">{{productDetail[0].seller}}</span></div>
                                      <div class="vr"></div>
                                      <div class="text-muted">Published : <span class="text-body fw-medium">{{productDetail[0].published}}</span></div>
                                  </div>
                              </div>
                              <div class="flex-shrink-0">
                                  <div>
                                      <a routerLink="/ecommerce/add-product" class="btn btn-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><i class="ri-pencil-fill align-bottom"></i></a>
                                  </div>
                              </div>
                          </div>

                          <div class="d-flex flex-wrap gap-2 align-items-center mt-3">
                              <div class="text-muted fs-16">
                                  <ngb-rating [rate]="productDetail[0].ratings" class="mdi mdi-star text-warning" [max]=4></ngb-rating>
                              </div>
                              <div class="text-muted">( {{productDetail[0].reviewCount}}k Customer Review )</div>
                          </div>

                          <div class="row mt-4">
                              <div class="col-lg-3 col-sm-6">
                                  <div class="p-2 border border-dashed rounded">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar-sm me-2">
                                              <div class="avatar-title rounded bg-transparent text-success fs-24">
                                                  <i class="ri-money-dollar-circle-fill"></i>
                                              </div>
                                          </div>
                                          <div class="flex-grow-1">
                                              <p class="text-muted mb-1">Price :</p>
                                              <h5 class="mb-0">${{productDetail[0].price}}</h5>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!-- end col -->
                              <div class="col-lg-3 col-sm-6">
                                  <div class="p-2 border border-dashed rounded">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar-sm me-2">
                                              <div class="avatar-title rounded bg-transparent text-success fs-24">
                                                  <i class="ri-file-copy-2-fill"></i>
                                              </div>
                                          </div>
                                          <div class="flex-grow-1">
                                              <p class="text-muted mb-1">No. of Orders :</p>
                                              <h5 class="mb-0">{{productDetail[0].orders}}</h5>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!-- end col -->
                              <div class="col-lg-3 col-sm-6">
                                  <div class="p-2 border border-dashed rounded">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar-sm me-2">
                                              <div class="avatar-title rounded bg-transparent text-success fs-24">
                                                  <i class="ri-stack-fill"></i>
                                              </div>
                                          </div>
                                          <div class="flex-grow-1">
                                              <p class="text-muted mb-1">Available Stocks :</p>
                                              <h5 class="mb-0">{{productDetail[0].stocks}}</h5>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!-- end col -->
                              <div class="col-lg-3 col-sm-6">
                                  <div class="p-2 border border-dashed rounded">
                                      <div class="d-flex align-items-center">
                                          <div class="avatar-sm me-2">
                                              <div class="avatar-title rounded bg-transparent text-success fs-24">
                                                  <i class="ri-inbox-archive-fill"></i>
                                              </div>
                                          </div>
                                          <div class="flex-grow-1">
                                              <p class="text-muted mb-1">Total Revenue :</p>
                                              <h5 class="mb-0">${{productDetail[0].revenue}}</h5>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!-- end col -->
                          </div>

                          <div class="row">
                              <div class="col-xl-6">
                                  <div class=" mt-4">
                                      <h5 class="fs-14">Sizes :</h5>
                                      <div class="d-flex flex-wrap gap-2">
                                          <div ngbTooltip="{{i.key}}" placement="top" *ngFor="let i of productDetail[0].sizes">
                                              <input type="radio" class="btn-check" name="productsize-radio" id="productsize-radio1" *ngIf="i.key == 'Out of Stock'" disabled>
                                              <label class="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center" for="productsize-radio1">{{i.value}}</label>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!-- end col -->

                              <div class="col-xl-6">
                                  <div class=" mt-4">
                                      <h5 class="fs-14">Colors :</h5>
                                      <div class="d-flex flex-wrap gap-2">
                                          <div ngbTooltip="{{i.key}}" placement="top" *ngFor="let i of productDetail[0].colors">
                                              <button type="button" class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 {{i.value}}"  *ngIf="i.key == 'Out of Stock'" disabled>
                                                  <i class="ri-checkbox-blank-circle-fill"></i>
                                              </button>
                                              <button type="button" class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 {{i.value}}"  *ngIf="i.key != 'Out of Stock'">
                                                <i class="ri-checkbox-blank-circle-fill"></i>
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!-- end col -->
                          </div>
                          <!-- end row -->

                          <div class="mt-4 text-muted">
                              <h5 class="fs-14">Description :</h5>
                              <p>{{productDetail[0].description}}</p>
                          </div>

                          <div class="row">
                              <div class="col-sm-6">
                                  <div class="mt-3">
                                      <h5 class="fs-14">Features :</h5>
                                      <ul class="list-unstyled">
                                          <li class="py-1" *ngFor="let i of productDetail[0].features"><i class="mdi mdi-circle-medium me-1 text-muted align-middle"></i> {{i}}</li>
                                      </ul>
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="mt-3">
                                      <h5 class="fs-14">Services :</h5>
                                      <ul class="list-unstyled product-desc-list">
                                          <li class="py-1"  *ngFor="let i of productDetail[0].services">{{i}}</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>


                          <div class="product-content mt-5">
                              <h5 class="fs-14 mb-3">Product Description :</h5>
                              <ul ngbNav #customNav="ngbNav" [activeId]="1"  class="nav nav-tabs nav-tabs-custom nav-success" role="tablist">
                                <li [ngbNavItem]="1" class="nav-item">
                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                      Specification
                                    </a>
                                    <ng-template ngbNavContent>
                                      <div class="tab-pane fade show active" id="nav-speci" role="tabpanel" aria-labelledby="nav-speci-tab">
                                        <div class="table-responsive">
                                            <table class="table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" style="width: 200px;">Category</th>
                                                        <td>T-Shirt</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Brand</th>
                                                        <td>Tommy Hilfiger</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Color</th>
                                                        <td>Blue</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Material</th>
                                                        <td>Cotton</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Weight</th>
                                                        <td>140 Gram</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                      </div>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="2" class="nav-item">
                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                      Details
                                    </a>
                                    <ng-template ngbNavContent>
                                      <div>
                                        <h5 class="font-size-16 mb-3">Tommy Hilfiger Sweatshirt for Men (Pink)</h5>
                                        <p>Tommy Hilfiger men striped pink sweatshirt. Crafted with cotton. Material composition is 100% organic cotton. This is one of the world’s leading designer lifestyle brands and is internationally recognized for celebrating the essence of classic American cool style, featuring preppy with a twist designs.</p>
                                        <div>
                                            <p class="mb-2"><i class="mdi mdi-circle-medium me-1 text-muted align-middle"></i> Machine Wash</p>
                                            <p class="mb-2"><i class="mdi mdi-circle-medium me-1 text-muted align-middle"></i> Fit Type: Regular</p>
                                            <p class="mb-2"><i class="mdi mdi-circle-medium me-1 text-muted align-middle"></i> 100% Cotton</p>
                                            <p class="mb-0"><i class="mdi mdi-circle-medium me-1 text-muted align-middle"></i> Long sleeve</p>
                                        </div>
                                      </div>
                                    </ng-template>
                                </li>
                              </ul>
                              <!-- Tab panes -->
                              <div class="tab-content border border-top-0 p-4">
                                <div [ngbNavOutlet]="customNav"></div>
                              </div>
                            </div>
                          <!-- product-content -->

                          <div class="mt-5">
                              <div>
                                  <h5 class="fs-14 mb-3">Ratings & Reviews</h5>
                              </div>
                              <div class="row gy-4 gx-0">
                                  <div class="col-lg-4">
                                      <div>
                                          <div class="pb-3">
                                              <div class="bg-light px-3 py-2 rounded-2 mb-2">
                                                  <div class="d-flex align-items-center">
                                                      <div class="flex-grow-1">
                                                          <ngb-rating [rate]="productDetail[0].ratings" class="fs-16 align-middle text-warning" [max]=4></ngb-rating>
                                                      </div>
                                                      <div class="flex-shrink-0">
                                                          <h6 class="mb-0">{{productDetail[0].ratings}} out of 5</h6>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="text-center">
                                                  <div class="text-muted">Total <span class="fw-medium">{{productDetail[0].reviewCount}}k</span> reviews</div>
                                              </div>
                                          </div>

                                          <div class="mt-3">
                                              <div class="row align-items-center g-2">
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0">5 star</h6>
                                                      </div>
                                                  </div>
                                                  <div class="col">
                                                      <div class="p-2">
                                                          <div class="progress animated-progess progress-sm">
                                                              <div class="progress-bar bg-success" role="progressbar" style="width: 50.16%" aria-valuenow="50.16" aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0 text-muted">2758</h6>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- end row -->

                                              <div class="row align-items-center g-2">
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0">4 star</h6>
                                                      </div>
                                                  </div>
                                                  <div class="col">
                                                      <div class="p-2">
                                                          <div class="progress animated-progess progress-sm">
                                                              <div class="progress-bar bg-success" role="progressbar" style="width: 19.32%" aria-valuenow="19.32" aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0 text-muted">1063</h6>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- end row -->

                                              <div class="row align-items-center g-2">
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0">3 star</h6>
                                                      </div>
                                                  </div>
                                                  <div class="col">
                                                      <div class="p-2">
                                                          <div class="progress animated-progess progress-sm">
                                                              <div class="progress-bar bg-success" role="progressbar" style="width: 18.12%" aria-valuenow="18.12" aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0 text-muted">997</h6>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- end row -->

                                              <div class="row align-items-center g-2">
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0">2 star</h6>
                                                      </div>
                                                  </div>
                                                  <div class="col">
                                                      <div class="p-2">
                                                          <div class="progress animated-progess progress-sm">
                                                              <div class="progress-bar bg-warning" role="progressbar" style="width: 7.42%" aria-valuenow="7.42" aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                      </div>
                                                  </div>

                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0 text-muted">408</h6>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- end row -->

                                              <div class="row align-items-center g-2">
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0">1 star</h6>
                                                      </div>
                                                  </div>
                                                  <div class="col">
                                                      <div class="p-2">
                                                          <div class="progress animated-progess progress-sm">
                                                              <div class="progress-bar bg-danger" role="progressbar" style="width: 4.98%" aria-valuenow="4.98" aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-auto">
                                                      <div class="p-2">
                                                          <h6 class="mb-0 text-muted">274</h6>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- end row -->
                                          </div>
                                      </div>
                                  </div>
                                  <!-- end col -->

                                  <div class="col-lg-8">
                                      <div class="ps-lg-4">
                                          <div class="d-flex flex-wrap align-items-start gap-3">
                                              <h5 class="fs-14">Reviews: </h5>
                                          </div>

                                          <ngx-simplebar class="me-lg-n3 pe-lg-4" style="max-height: 225px;">
                                            <ul class="list-unstyled mb-0">
                                                <li class="py-2">
                                                    <div class="border border-dashed rounded p-3">
                                                        <div class="d-flex align-items-start mb-3">
                                                            <div class="hstack gap-3">
                                                                <div class="badge rounded-pill bg-success mb-0"><i class="mdi mdi-star"></i> 4.2</div>
                                                                <div class="vr"></div>
                                                                <div class="flex-grow-1">
                                                                    <p class="text-muted mb-0">Superb sweatshirt. I loved it. It is for winter.</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex flex-grow-1 gap-2 mb-3">
                                                            <a href="javascript:void(0);" class="d-block">
                                                                <img src="assets/images/small/img-12.jpg" alt="" class="avatar-sm rounded object-cover">
                                                            </a>
                                                            <a href="javascript:void(0);" class="d-block">
                                                                <img src="assets/images/small/img-11.jpg" alt="" class="avatar-sm rounded object-cover">
                                                            </a>
                                                            <a href="javascript:void(0);" class="d-block">
                                                                <img src="assets/images/small/img-10.jpg" alt="" class="avatar-sm rounded object-cover">
                                                            </a>
                                                        </div>

                                                        <div class="d-flex align-items-end">
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-14 mb-0">Henry</h5>
                                                            </div>

                                                            <div class="flex-shrink-0">
                                                                <p class="text-muted fs-13 mb-0">12 Jul, 21</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="py-2">
                                                    <div class="border border-dashed rounded p-3">
                                                        <div class="d-flex align-items-start mb-3">
                                                            <div class="hstack gap-3">
                                                                <div class="badge rounded-pill bg-success mb-0"><i class="mdi mdi-star"></i> 4.0</div>
                                                                <div class="vr"></div>
                                                                <div class="flex-grow-1">
                                                                    <p class="text-muted mb-0">Great at this price, Product quality and look is awesome.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-end">
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-14 mb-0">Nancy</h5>
                                                            </div>

                                                            <div class="flex-shrink-0">
                                                                <p class="text-muted fs-13 mb-0">06 Jul, 21</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li class="py-2">
                                                    <div class="border border-dashed rounded p-3">
                                                        <div class="d-flex align-items-start mb-3">
                                                            <div class="hstack gap-3">
                                                                <div class="badge rounded-pill bg-success mb-0"><i class="mdi mdi-star"></i> 4.2</div>
                                                                <div class="vr"></div>
                                                                <div class="flex-grow-1">
                                                                    <p class="text-muted mb-0">Good product. I am so happy.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-end">
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-14 mb-0">Joseph</h5>
                                                            </div>

                                                            <div class="flex-shrink-0">
                                                                <p class="text-muted fs-13 mb-0">06 Jul, 21</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li class="py-2">
                                                    <div class="border border-dashed rounded p-3">
                                                        <div class="d-flex align-items-start mb-3">
                                                            <div class="hstack gap-3">
                                                                <div class="badge rounded-pill bg-success mb-0"><i class="mdi mdi-star"></i> 4.1</div>
                                                                <div class="vr"></div>
                                                                <div class="flex-grow-1">
                                                                    <p class="text-muted mb-0">Nice Product, Good Quality.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-end">
                                                            <div class="flex-grow-1">
                                                                <h5 class="fs-14 mb-0">Jimmy</h5>
                                                            </div>

                                                            <div class="flex-shrink-0">
                                                                <p class="text-muted fs-13 mb-0">24 Jun, 21</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                          </ngx-simplebar>
                                      </div>
                                  </div>
                                  <!-- end col -->
                              </div>
                              <!-- end Ratings & Reviews -->
                          </div>
                          <!-- end card body -->
                      </div>
                  </div>
                  <!-- end col -->
              </div>
              <!-- end row -->
          </div>
          <!-- end card body -->
      </div>
      <!-- end card -->
  </div>
  <!-- end col -->
</div>
<!-- end row -->
