import { ChartType } from './team-members.model';


/**
 *  Status1
 */
 const status1: ChartType = {
    series: [50],       
    chart: {
        type: 'radialBar',
        width: 36,
        height: 36,
        sparkline: {
            enabled: true
        }
    },
    dataLabels: {
        enabled: false
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '50%'
            },
            track: {
                margin: 1
            },
            dataLabels: {
                show: false
            }
        }
    },
    colors: ["#0d68c3"]
};

/**
 *  Status2
 */
 const status2: ChartType = {
    series: [45],       
    chart: {
        type: 'radialBar',
        width: 36,
        height: 36,
        sparkline: {
            enabled: true
        }
    },
    dataLabels: {
        enabled: false
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '50%'
            },
            track: {
                margin: 1
            },
            dataLabels: {
                show: false
            }
        }
    },
    colors: ["#0d68c3"]
};

/**
 *  Status3
 */
 const status3: ChartType = {
    series: [75],       
    chart: {
        type: 'radialBar',
        width: 36,
        height: 36,
        sparkline: {
            enabled: true
        }
    },
    dataLabels: {
        enabled: false
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '50%'
            },
            track: {
                margin: 1
            },
            dataLabels: {
                show: false
            }
        }
    },
    colors: ["#0d68c3"]
};

/**
 *  Status4
 */
 const status4: ChartType = {
    series: [25],       
    chart: {
        type: 'radialBar',
        width: 36,
        height: 36,
        sparkline: {
            enabled: true
        }
    },
    dataLabels: {
        enabled: false
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '50%'
            },
            track: {
                margin: 1
            },
            dataLabels: {
                show: false
            }
        }
    },
    colors: ["#f6c164"]
};

/**
 *  Status5
 */
 const status5: ChartType = {
    series: [60],       
    chart: {
        type: 'radialBar',
        width: 36,
        height: 36,
        sparkline: {
            enabled: true
        }
    },
    dataLabels: {
        enabled: false
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '50%'
            },
            track: {
                margin: 1
            },
            dataLabels: {
                show: false
            }
        }
    },
    colors: ["#0d68c3"]
};

/**
 *  Status6
 */
 const status6: ChartType = {
    series: [85],       
    chart: {
        type: 'radialBar',
        width: 36,
        height: 36,
        sparkline: {
            enabled: true
        }
    },
    dataLabels: {
        enabled: false
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '50%'
            },
            track: {
                margin: 1
            },
            dataLabels: {
                show: false
            }
        }
    },
    colors: ["#2dbca9"]
};

/**
 *  Status7
 */
 const status7: ChartType = {
    series: [70],       
    chart: {
        type: 'radialBar',
        width: 36,
        height: 36,
        sparkline: {
            enabled: true
        }
    },
    dataLabels: {
        enabled: false
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '50%'
            },
            track: {
                margin: 1
            },
            dataLabels: {
                show: false
            }
        }
    },
    colors: ["#0d68c3"]
};

export { status1, status2, status3, status4, status5, status6, status7 };