const ClientLogo = [
  {
    images: [
      {
        image: [{img:'assets/images/nft/img-06.jpg'},{img:'assets/images/nft/gif/img-2.gif'}]
      },
      {
        image: [{img:'assets/images/nft/gif/img-5.gif'},{img:'assets/images/nft/img-03.jpg'}]
      },
    ],
  },
  {
    images: [
      {
        image: [{img:'assets/images/nft/img-05.jpg'},{img:'assets/images/nft/gif/img-1.gif'}]
      },
      {
        image: [{img:'assets/images/nft/gif/img-4.gif'},{img:'assets/images/nft/img-04.jpg'}]
      },
    ],
  },
  {
    images: [
      {
        image: [{img:'assets/images/nft/img-02.jpg'},{img:'assets/images/nft/gif/img-3.gif'}]
      },
      {
        image: [{img:'assets/images/nft/gif/img-1.gif'},{img:'assets/images/nft/img-01.jpg'}]
      },
    ],
  },
  {
    images: [
      {
        image: [{img:'assets/images/nft/img-03.jpg'},{img:'assets/images/nft/gif/img-5.gif'}]
      },
      {
        image: [{img:'assets/images/nft/gif/img-2.gif'},{img:'assets/images/nft/img-05.jpg'}]
      },
    ],
  },
  {
    images: [
      {
        image: [{img:'assets/images/nft/img-02.jpg'},{img:'assets/images/nft/gif/img-3.gif'}]
      },
      {
        image: [{img:'assets/images/nft/gif/img-1.gif'},{img:'assets/images/nft/img-01.jpg'}]
      },
    ],
  },
];

export { ClientLogo };
