import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARDCLIENT.TEXT',
    icon: 'ri-dashboard-2-line',
    link: ''
  },
  {
    id: 3,
    label: 'MENUITEMS.LEADLIST.TEXT',
    icon: 'ri-account-circle-line',
    link: 'lead-list'
  },
  {
    id: 4,
    label: 'MENUITEMS.CALENDAR.TEXT',
    icon: 'ri-calendar-2-line',
    link: 'calendar'
  },
  {
    id: 5,
    label: 'MENUITEMS.BLACKOUTDAYS.TEXT',
    icon: 'ri-shut-down-line',
    link: 'blackout-day'
  },
  {
    id: 6,
    label: 'MENUITEMS.TRANSACTIONS.TEXT',
    icon: 'ri-list-check-2',
    link: 'transaction'
  },
  {
    id: 7,
    label: 'MENUITEMS.TUTORIAL.TEXT',
    icon: 'ri-rocket-line',
    link: 'tutorial'
  },
  {
    id: 8,
    label: 'Prospector',
    icon: 'ri-building-fill',
    link: 'prospector'
  },
  // {
  //   id: 8,
  //   label: 'Dashboard Analytics',
  //   icon: 'ri-wallet-3-line',
  //   link: 'dashboards/analytics'
  // },
  // {
  //   id: 8,
  //   label: 'tasks',
  //   icon: 'ri-wallet-3-line',
  //   link: 'tasks/details'
  // }
];
